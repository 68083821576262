import React, { useState } from 'react';
import './Pricing.css';
import { Link } from 'react-router-dom';

import hamburger from '../../assets/images/illustrations/hamburger.svg';
import Plans from '../../components/modals/Plans';

const Pricing = () => {
    const [showPlan, setShowPlan] = useState(undefined);

    return (
        <div className="pricing" id="pricing">
            <div className="intro">
                <img src={hamburger} alt="Cheap as burger"/>
                <h1>It doesn't cost much</h1>
            </div>
            <div className="item">
                <h1 className="name">Solo</h1>
                <div className="plan">
                    <div className="perk">
                        Suitable for a Business name or Limited Liability Company.
                    </div>
                    <div className="perk">
                        Annual turnover below &#8358;25,000,000.00
                    </div>
                    <div className="perk">
                        No employees.
                    </div>
                    <div className="perk">
                        <span className="plan-link" role="button" onClick={() => setShowPlan('Solo')}>Learn more<i className="la la-arrow-right"></i></span>
                    </div>
                </div>
                <h2 className="price">from <b className="naira">&#8358;</b>200,000<span>annually</span></h2>
                <Link to="/signup" className="link a">Get started now</Link>
                <p className="term">Terms and conditions apply</p>
            </div>
            
            <div className="item b">
                <h1 className="name">Basic</h1>
                <div className="plan">
                    <div className="perk">
                        Suitable for a Business name.
                    </div>
                    <div className="perk">
                        Annual turnover below &#8358;25,000,000.00
                    </div>
                    <div className="perk">
                        1 to 15 employees.
                    </div>
                    <div className="perk">
                        <span className="plan-link" role="button" onClick={() => setShowPlan('Basic')}>Learn more<i className="la la-arrow-right"></i></span>
                    </div>
                </div>
                <h2 className="price"><b className="naira">&#8358;</b>29,167<i className="la la-minus"></i><b className="naira">&#8358;</b>33,400<span>monthly</span></h2>
                <Link to="/signup" className="link a">Get started now</Link>
                <p className="term">Terms and conditions apply</p>
            </div>
            
            <div className="item">
                <h1 className="name">Medium</h1>
                <div className="plan">
                    <div className="perk">
                        Suitable for a Limited Liability Company.
                    </div>
                    <div className="perk">
                        Annual turnover below &#8358;25,000,000.00
                    </div>
                    <div className="perk">
                        0 to 15 employees.
                    </div>
                    <div className="perk">
                        <span className="plan-link" role="button" onClick={() => setShowPlan('Medium')}>Learn more<i className="la la-arrow-right"></i></span>
                    </div>
                </div>
                <h2 className="price"><b className="naira">&#8358;</b>41,667<i className="la la-minus"></i><b className="naira">&#8358;</b>50,000<span>monthly</span></h2>
                <Link to="/signup" className="link a">Get started now</Link>
                <p className="term">Terms and conditions apply</p>
            </div>
            
            <div className="item">
                <h1 className="name">Enterprise</h1>
                <div className="plan">
                    <div className="perk">
                        Suitable for all kinds of businesses.
                    </div>
                    <div className="perk">
                        Annual turnover of &#8358;25,000,000.00 and above.
                    </div>
                    <div className="perk">
                        At least 1 employee.
                    </div>
                    <div className="perk">
                        <span className="plan-link" role="button" onClick={() => setShowPlan('Enterprise')}>Learn more<i className="la la-arrow-right"></i></span>
                    </div>
                </div>
                <h2 className="price">Custom price<span><i className="la la-ellipsis-h"></i></span></h2>
                <Link to="/signup" className="link a">Get started now</Link>
                <p className="term">Terms and conditions apply</p>
            </div>

            <Plans show={showPlan} close={() => setShowPlan(undefined)}  />
        </div>
    );
}

export default Pricing;
