import React, { useState } from 'react';
import './Support.css';

import Input from '../../../components/Input/Input';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import List from '../../components/List/List';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { CREATE_SUPPORT_TICKET, SUPPORT_LIST } from '../../Queries';
import { useQuery, useMutation } from 'react-apollo';
import { IS_LOGGED_IN } from '../../../AuthPages/Queries';

const Support = () => {
    const [ticketForm, setTicketForm] = useState({
        form: {
            subject: {
                label: 'Subject', elementType: 'input',
                elementConfig: { type: 'text', autoFocus: true },
                validation: {required: true},
                value: '', valid: false, touched: false
            },
            message: {
                label: 'Message', elementType: 'textarea',
                elementConfig: { type: 'text' },
                validation: {required: true},
                value: '', valid: false, touched: false
            }
        },
        formIsValid: false
    });
    const { data: cache } = useQuery(IS_LOGGED_IN);
    let [showTicket, setShowTicket] = useState("no");

    const [currentPage, setCurrentPage] = useState(1);
    
    let pageLoading = false;
    const {loading: supportLoading, data: supportListData} = useQuery(SUPPORT_LIST, {
        variables: { first: 15, page: currentPage },
        fetchPolicy: "network-only"
    });
    if (supportLoading) {
        pageLoading = true;
    }
    let supportList = [];
    let listPagination = {};
    if (supportListData && supportListData.list_support) {
        supportList = supportListData.list_support.data;
        listPagination = supportListData.list_support.paginatorInfo;
    }

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedTicketForm = {
            ...ticketForm.form
        };
        const updatedFormElement = {
            ...updatedTicketForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedTicketForm[inputIdentifier] = updatedFormElement;
        
        let formIsValid = true;
        for (let inputIdentifier in updatedTicketForm) {
            formIsValid = updatedTicketForm[inputIdentifier].valid && formIsValid
        }

        setTicketForm({...ticketForm, form: updatedTicketForm, formIsValid: formIsValid});
    }

    const checkValidity = (value, rules) => {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        return isValid;
    }

    
    const [createTicket, { loading: ticketLoading }] = useMutation(CREATE_SUPPORT_TICKET, {
        refetchQueries: [{query: SUPPORT_LIST, variables: {first: 15, page: 1}}],
        onCompleted: data => {
            setShowTicket("sent");
        }
    });
    if (ticketLoading) {
        pageLoading = true;
    }

    const formHandler = (event) => {
        event.preventDefault();

        const variables = {
            subject: ticketForm.form.subject.value,
            message: ticketForm.form.message.value
        }
        
        createTicket({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in ticketForm.form) {
        formELementsArray.push({
            id: key,
            config: ticketForm.form[key]
        });
    }

    const handlePagination = (page) => {
        setCurrentPage(page);
    }

    return (
        <div>
            <Breadcrumb name="Tax support" />

            <div className="support-ticket-box">
                <div className={`support-ticket ${showTicket !== "no" ? "active" : null}`}>
                    {cache && cache.business?.verified ?
                        <div className="support-ticket-head" role="button" onClick={() => setShowTicket(showTicket === "no" ? "yes" : "no")}>
                            Request support <i className="la la-angle-down"></i>
                        </div> : 
                        <a href={`mailto:support@taxmingo.com?subject=Support request from ${cache.business?.name}`} className="support-ticket-head " role="button">
                            Send a support email
                        </a>
                    }
                    {showTicket === "yes" ?
                        <form onSubmit={formHandler} className="support-ticket-form">
                            {formELementsArray.map(formElement => (
                                <Input key={formElement.id} value={formElement.config.value}
                                    label={formElement.config.label} invalid={!formElement.config.valid}
                                    shouldValidate={formElement.config.validation}
                                    touched={formElement.config.touched}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    inputChanged={(event) => inputChangedHandler(event, formElement.id)} />
                            ))}

                            <button type="submit" disabled={!ticketForm.formIsValid}>Create ticket</button>
                        </form> : 
                        (showTicket === "sent" ? 
                            <div className="support-ticket-sent">
                                Your ticket has been created and your consultant will respond to you soon.
                            </div> : null
                        )
                    }
                </div>
            </div>
            <div>
                <List type="support" title="Support history" list={supportList}
                    pagination={listPagination} pageClicked={handlePagination} />
            </div>
            
            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default Support;