import React from 'react';
import './DashInput.css';

const dashInput = (props) => {
    let inputElement = null;
    const inputClass = [];
    if (props.invalid && props.shouldValidate && props.touched) {
        inputClass.push('invalid-field');
    }

    switch (props.elementType) {
        case ('input'): inputElement =  <input className={inputClass} 
                {...props.elementConfig} value={props.value}
                onChange={props.inputChanged} />
            break;
        case ('file'): inputElement =  <div className={"file-input-box" + (props.value ? " file-input-box--active" : "")}>
                    <span className="file-input-box__label">Choose File({props.elementConfig.multiple ? "s" : null})</span>
                    <span className="file-input-box__text">{props.value ? props.value : props.typeInfo}</span>
                    <input className={inputClass}
                        title={props.value ? props.value : "No file chosen"}
                        {...props.elementConfig} onChange={props.inputChanged} />
                </div>
            break;
        case ('textarea'): inputElement =  <textarea className={inputClass} 
                {...props.elementConfig} value={props.value}
                onChange={props.inputChanged} />
            break;
        case ('select'): inputElement = (
                <select className={inputClass} onChange={props.inputChanged} value={props.value} >
                    <option></option>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>{option.displayText}</option>
                    ))}
                </select>
            );
            break;
        case ('radio'): inputElement = (
                <div className={`radio-input ${inputClass}`}>
                    <label>No&nbsp;
                        <input name={props.name}
                            {...props.elementConfig} value='No'
                            onChange={props.inputChanged} />
                            <span className="radio"></span>
                    </label>
                    <label>
                        <input name={props.name}
                            {...props.elementConfig} value='Yes'
                            onChange={props.inputChanged} />
                            <span className="radio"></span>&nbsp;Yes
                    </label>
                    {props.invalid ? <span className="error">select an option</span> : null }
                </div>
            );
            break;
        default: inputElement =  <input className={inputClass} 
                {...props.elementConfig} value={props.value}
                onChange={props.inputChanged} />
            break;
    }

    return (
        <div className="dash-input-box">
            <div className="dash-input-content">
                <label>{props.label}</label>
                {inputElement}
            </div>
            <div className="dash-input-description">
                {props.description}
            </div>
        </div>
    );
}

export default dashInput;