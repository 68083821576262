import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../components/Input/Input';
import { useMutation } from 'react-apollo'
import { REQUEST_RESET_PASSWORD } from '../Queries';
import PageLoading from '../../components/PageLoading/PageLoading';

const ForgotPassword = () => {
    const [resetEmail, setResetEmail] = useState({
        label: 'Email', elementType: 'input',
        elementConfig: { type: 'email', autoFocus: true },
        validation: {required: true, email: true},
        value: '', valid: false, touched: false
    })
    let pageLoading = false;
    let emailSent = false;
    let falseEmail = false;
    const [requestReset, { loading: requestLoading, error: requestError, data: requestData }] = useMutation(REQUEST_RESET_PASSWORD);
    if (requestLoading) {
        pageLoading = true;
    }
    if (requestError) {
        console.log(requestError);
        pageLoading = false;
    }
    if (requestData) {
        if (requestData.user_password_reset_link[0] === "true") {
            emailSent = true;
        } else {
            falseEmail = true;
        }
    }

    const formHandler = (event) => {
        event.preventDefault();

        const variables = {
            email: resetEmail.value
        }

        requestReset({ variables: variables });
    }

    const inputChangedHandler = (event) => {
        const updatedFormElement = {
            ...resetEmail
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        

        setResetEmail(updatedFormElement);
    }

    const checkValidity = (value, rules) => {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.email) {
            const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isValid = isEmail.test(value.trim()) && isValid;
        }

        return isValid;
    }     


    return (
        <form onSubmit={formHandler}>
            <h1>Reset password</h1>
            {emailSent ?
                <div className="auth-message auth-success">
                    <b>RESET PASSWORD LINK SENT!</b><br/>
                    A link has been sent to your email. Follow the link in your email to reset your password.
                </div> : null
            }
            {falseEmail ?
                <div className="auth-message auth-error">
                    <b>OOPS!</b> We do not have this email in our records.<br/>
                    <Link to="/signup" className="lnk">Sign up</Link> if you do not have an account.
                </div> : null
            }

            <Input value={resetEmail.value}
                label={resetEmail.label} invalid={!resetEmail.valid}
                shouldValidate={resetEmail.validation}
                touched={resetEmail.touched}
                elementType={resetEmail.elementType}
                elementConfig={resetEmail.elementConfig}
                inputChanged={(event) => inputChangedHandler(event)} />
            
            <div className="submit-box">
                <button type="submit" className="submit" disabled={!resetEmail.valid}>Reset</button>
                <div className="text"><Link to="/signup">Sign up</Link> if you do not have an account.</div>
            </div>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </form>
    );
}

export default ForgotPassword;