import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './Dashboard.css';

import PageLoading from '../components/PageLoading/PageLoading';
import RegisterBusiness from './pages/Business/RegisterBusiness';
import Nav from './Nav/Nav';
import Home from './pages/Home/Home';
import Health from './pages/Health/Health';
import HealthForm from './pages/Health/HealthForm';
import HealthReport from './pages/Health/HealthReport';
import Computation from './pages/Computation/Computation';
import ComputationForm from './pages/Computation/ComputationForm';
import ComputationReport from './pages/Computation/ComputationReport';
import Planning from './pages/Planning/Planning';
import Support from './pages/Support/Support';
import SupportTicket from './pages/Support/SupportTicket';
import Subscription from './pages/Subscription/Subscription';
import Profile from './pages/Profile/Profile';
import EditBusiness from './pages/Profile/EditBusiness';

import { IS_LOGGED_IN } from '../AuthPages/Queries';
import { BUSINESS_INFO } from './Queries';
import { useQuery, useApolloClient } from 'react-apollo';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import EditUser from './pages/Profile/EditUser';

const Dashboard = () => {
    const client = useApolloClient();
    const {data: cache} = useQuery(IS_LOGGED_IN);
    let { error: businessError, data: business } = useQuery(BUSINESS_INFO, {
        fetchPolicy: "network-only",
        onCompleted: business => {
            if (business.user_business) {
                client.writeData({
                    data: {
                        ...cache,
                        business: business.user_business
                    }
                });
            }
        }
    });
    // console.log(cache);
    let page = <PageLoading ripple={true} />;
    if (cache.isLoggedIn) {
        if (businessError) {
            let { errors } = businessError;
            if (businessError || errors[0].debugMessage === "Unauthenticated.") {
                localStorage.removeItem("auth");
                localStorage.removeItem("trial");
                client.writeData({data: { isLoggedIn: false, business: null, trial: null} });
            }
        }
        if (business) {
            businessError = null;
            if (!business.user_business) {
                page = <RegisterBusiness />
            } else if (['active', 'success'].includes(business.user_business.subscribed)) {
                page = <>
                    <Nav />
                    <Switch>
                        <Route path="/(dashboard/home|dashboard)/" exact component={Home} />
                        <Route path="/dashboard/health" exact component={Health} />
                            <Route path="/dashboard/health/:type" exact component={HealthForm} />
                            <Route path="/dashboard/health/report/:id" exact component={HealthReport} />
                        <Route path="/dashboard/computation" exact component={Computation} />
                            <Route path="/dashboard/computation/:type" exact component={ComputationForm} />
                            <Route path="/dashboard/computation/report/:id" exact component={ComputationReport} />
                        <Route path="/dashboard/planning" exact component={Planning} />
                        <Route path="/dashboard/support" exact component={Support} />
                            <Route path="/dashboard/support/ticket/:id" exact component={SupportTicket} />
                        <Route path="/dashboard/subscription" exact component={Subscription} />
                        <Route path="/dashboard/profile" exact component={Profile} />
                            <Route path="/dashboard/profile/edit-business" exact component={EditBusiness} />
                            <Route path="/dashboard/profile/edit-user" exact component={EditUser} />
                        
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </>;
            } else if (!business.user_business.subscribed || ['cancelled', 'pending'].includes(business.user_business.subscribed)) {
                if (localStorage.getItem("trial")) {
                    page = <>
                        <Nav />
                        <Switch>
                            <Route path="/(dashboard/home|dashboard)/" exact component={Home} />
                            <Route path="/dashboard/health" exact component={Health} />
                                <Route path="/dashboard/health/:type" exact component={HealthForm} />
                                <Route path="/dashboard/health/report/:id" exact component={HealthReport} />
                            <Route path="/dashboard/profile" exact component={Profile} />
                                <Route path="/dashboard/profile/edit-business" exact component={EditBusiness} />
                                <Route path="/dashboard/profile/edit-user" exact component={EditUser} />
                            
                            <Route path="*" component={Subscription} />
                        </Switch>
                    </>;
                } else {
                    page = <>
                        <Nav />
                        <Switch>
                            <Route path="/(dashboard/home|dashboard)/" exact component={Home} />
                            <Route path="/dashboard/profile" exact component={Profile} />
                                <Route path="/dashboard/profile/edit-business" exact component={EditBusiness} />
                                <Route path="/dashboard/profile/edit-user" exact component={EditUser} />
                            
                            <Route path="*" component={Subscription} />
                        </Switch>
                    </>;
                }
            }
        } 
    } else {
        return <Redirect to="/login" />
    }

    return (
        <div className="dashboard">
            {page}
        </div>
    );
}

export default Dashboard;