import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Home/Home';
import Dashboard from './Dashboard/Dashboard';
import AuthPages from './AuthPages/AuthPages';
import PageNotFound from './components/PageNotFound/PageNotFound';



const routes = () => {
    return (
        <div>
            <Switch>
                <Route path="/(|home)/" exact component={Home} />
                <Route path="/(login|signup|forgot-password)/" exact component={AuthPages} />
                <Route path="/password/reset/:token" exact component={AuthPages} />
                <Route path="/user/verify/:token" exact component={AuthPages} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </div>
    );
}

export default routes;