import React, { useState } from 'react';
import '../assets/Report.css';
import ReactHtmlParser from 'react-html-parser';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { useQuery } from 'react-apollo';
import { COMPUTATION_REPORT } from '../../Queries';

const ComputationReport = (props) => {
    const [activeSection, setActiveSection] = useState("report");
    let pageLoading = false;
    const id = props.match.params.id;
    
    const {loading: reportLoading, error: reportError, data: reportData} = useQuery(COMPUTATION_REPORT, {
        variables: { id: id }
    });
    if (reportLoading) {
        pageLoading = true;
    }
    if (reportError) {
        // console.log({reportError});
    }
    let computationReport = {};
    let documents = [];
    let assessment = [];
    
    if (reportData && reportData.view_tcf_report) {
        computationReport = reportData.view_tcf_report;
        const docs = JSON.parse(computationReport.documents);
        for (const key in docs) {
            documents.push({
                name: key, path: docs[key]
            })
        }
        if (computationReport.text_info !== "null") {
            const questions = JSON.parse(computationReport.text_info);
            questions.forEach(quest => {
                for (const key in JSON.parse(quest)) {
                    assessment.push({
                        question: key, answer: JSON.parse(quest)[key]
                    })
                }
            });
        }
    }
    
    const editAmount = (input) => {
        let val = input.indexOf(".") > -1 ? input.split(".")[0] : input;
        let value = val.replaceAll(',','');
        let newValue = value;
        const iteration = Math.floor(value.length / 3);
        if (value.length > 3) {
            for (let index = 1; index <= iteration; index++) {
                newValue = newValue.slice(0, value.length - (3 * index)) + "," + newValue.slice(value.length - (3 * index));
            }
            newValue = newValue.charAt(0) === ',' ? newValue.replace(',','') : newValue;
        } else {
            newValue = val;
        }
        return input.indexOf(".") > -1 ? newValue + '.' + input.split(".")[1] : newValue;
    }
    
    const changeSection = (section) => {
        if (activeSection === section) {}
        setActiveSection(activeSection === section ? "" : section);
    }

    // const download = (files, name) => {
    //     files.forEach((file, i) => {;
    //         const index = i + 1;
    //         const fileName = name +" "+ index +"."+ file.split(".")[file.split(".").length -1];
    //         const reportLink = document.createElement("a");
        
    //         reportLink.href = file;
    //         reportLink.download = fileName;
        
    //         document.body.appendChild(reportLink);
        
    //         reportLink.dispatchEvent(
    //             new MouseEvent('click', {
    //                 bubbles: true, 
    //                 cancelable: true, 
    //                 view: window 
    //             })
    //         );

    //         document.body.removeChild(reportLink);
    //     })
    // }


    return (
        <div>
            <Breadcrumb name="Tax computation report" />

            <div className="report-box">
                <div className="report">
                    <div className="report-intro">
                        {computationReport.type ? <>
                            <div className="desc big">
                                <div className="label">Tax type</div>
                                <div className="value">{computationReport.type}</div>
                            </div>
                            <div className="desc big">
                                <div className="label">Payable tax</div>
                                <div className="value">
                                    {computationReport.amount ? 
                                        <div><span className="naira">&#8358;</span>{editAmount(computationReport.amount)}</div> :
                                        <div className="not-ready">Not yet ready</div>
                                    }
                                </div>
                            </div>
                            <div className="desc">
                                <div className="label">Tax period</div>
                                <div className="value">
                                    {computationReport.period}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="label">Requested on</div>
                                <div className="value">
                                    {new Date(computationReport.created_at).toLocaleString()}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="label">Analysed on</div>
                                <div className="value">
                                    {computationReport.updated_at !== computationReport.created_at ?
                                        new Date(computationReport.updated_at).toLocaleString() : <div className="not-ready">Not yet analysed</div>}
                                </div>
                            </div>
                        </> : null}
                    </div>
                    {computationReport.report ?
                        <div className="report-section">
                            <h1 className={`title ${activeSection === 'report' ? 'active' : null}`} onClick={() => changeSection("report")}>
                                Computation report <i className="la la-angle-down"></i>
                            </h1>
                            {activeSection === "report" ?
                                <div className="report-text">
                                    {ReactHtmlParser(computationReport.report)}
                                </div> : null
                            }
                        </div> : null
                    }
                    {documents.length > 0 ?
                        <div className="report-section">
                            <h1 className="title active">
                                Documents
                            </h1>
                            <div className="report-text">
                                {documents.map(item => {
                                    return (
                                        <div className="report-assessment" key={item.name}>
                                            <div className="question">
                                                {item.name}
                                            </div>
                                            <div className="answer">
                                                <a target="_blank" rel="noopener noreferrer" download={true} href={item.path}>
                                                    View/Download <i className="la la-download"></i>
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div> : null
                    }
                    {computationReport.text_info !== "null" ? 
                        <div className="report-section">
                            <h1 className={`title ${activeSection === 'assessment' ? 'active' : null}`} onClick={() => changeSection("assessment")}>
                                Information <i className="la la-angle-down"></i>
                            </h1>
                            {activeSection === "assessment" ?
                                <div className="report-text">
                                    {assessment.map(item => {
                                        return (
                                            <div className="report-assessment" key={item.question}>
                                                <div className="question">
                                                    {item.question}
                                                </div>
                                                <div className="answer">
                                                    {item.answer}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div> : null
                            }
                        </div> : null
                    }
                </div>
            </div>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default ComputationReport;