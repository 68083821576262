import React, { useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DashInput from '../../components/DashInput/DashInput';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { REQUEST_COMPUTATION } from '../../Queries';
import { useMutation } from 'react-apollo';
import { Link } from 'react-router-dom';

const ComputationForm = (props) => {
    const [componentState, setComponentState] = useState({
        form: {
            vat: {
                month: {
                    label: 'What month is this computation for?',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'month' },
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                a: {
                    label: 'Total value of sales made during the month',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'text', placeholder: '0.00' },
                    validation: {required: true, money: true}, show: true,
                    value: '', actualValue: '', valid: false, touched: false,
                },
                b: {
                    label: 'Total value of purchases made during the month',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'text', placeholder: '0.00' },
                    validation: {required: true, money: true}, show: true,
                    value: '', actualValue: '', valid: false, touched: false,
                },
                c: {
                    label: 'Sales day book/sales book',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                d: {
                    label: 'Purchases day book/purchases book',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                e: {
                    label: 'Sales invoices during the month',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                f: {
                    label: 'Purchases invoices during the month',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                }
            },
            wt: {
                month: {
                    label: 'What month is this computation for?',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'month' },
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                a: {
                    label: 'Receipt of rent or lease paid during the period',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                b: {
                    label: 'Contractor and/or Consultants invoice and details',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                c: {
                    label: 'Evidence (Receipts) to payments for rent',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                d: {
                    label: 'Evidence of payments to Contractors and Consultants (Receipts)',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                e: {
                    label: 'Withholding Tax Schedule',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                }
            },
            cit: {
                year: {
                    label: 'What year is this computation for?',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'number', max: new Date().getFullYear() },
                    validation: {required: true, min: 1990, max: new Date().getFullYear()}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                a: {
                    label: 'Statement of ﬁnancial position (Balance Sheet)',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                b: {
                    label: 'Statement of comprehensive income (Proﬁt and Loss Account)',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                c: {
                    label: 'Statement of changes in equity',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                d: {
                    label: 'Statement of cash ﬂows',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                e: {
                    label: 'Notes on signiﬁcant Accounting Policies and other explanatory information',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                f: {
                    label: 'Comparative ﬁgures of preceding year on the Generally Accepted Accounting Principles (GAAP) basis',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                g: {
                    label: 'Ledger',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                h: {
                    label: 'Capital allowances computation',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                i: {
                    label: 'Schedule of Fixed Assets',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                }
            },
            da: {
                year: {
                    label: 'What year is this Direct Assessment computation for?',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'number', step: 1, min: 1990, max: new Date().getFullYear() },
                    validation: {required: true, min: 1900, max: new Date().getFullYear(), minLenght: 4, maxLenght: 4}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                a: {
                    label: 'Cash book',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                b: {
                    label: 'Records of Income and Expenses',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                c: {
                    label: 'Bank Statement for the period',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                d: {
                    label: 'Assets register and schedule',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                e: {
                    label: 'Evidence of assets disposed',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                f: {
                    label: 'Tax Identification Number',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                }
            },
            paye: {
                month: {
                    label: 'What month is this PAYE computation for?',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'month' },
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                a: {
                    label: 'Evidences of remittances and schedules filed for the period',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                b: {
                    label: 'Payroll for the periods',
                    description: '',
                    elementType: 'file',
                    elementConfig: { type: 'file', multiple: true, accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                },
                c: {
                    label: 'PAYE ID',
                    description: '',
                    elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {required: true}, show: true,
                    value: '', file: {}, valid: false, touched: false
                }
            }
        },
        formIsValid: false
    });
    let pageLoading = false;
    let requestSent = false;

    const taxType = props.match.params.type;
    let pageTitle;
    if (taxType === "vat") {
        pageTitle = 'Value Added Tax';
    } else if (taxType === "wt") {
        pageTitle = 'Withholding Tax';
    } else if (taxType === "cit") {
        pageTitle = 'Company Income Tax';
    } else if (taxType === "da") {
        pageTitle = 'Direct Assessment Tax';
    } else if (taxType === "paye") {
        pageTitle = 'Pay-As-You-Earn Tax';
    }

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...componentState.form
        };
        const updatedFormGroup = {
            ...updatedForm[taxType]
        }
        const updatedFormElement = {
            ...updatedFormGroup[inputIdentifier]
        };

        if (updatedFormElement.validation.money) {
            updatedFormElement.actualValue = event.target.value.replace(/,/gi,'');
            updatedFormElement.value = editMoney(event.target.value);
        } else if (updatedFormElement.elementConfig.type === "file") {
            updatedFormElement.file = event.target.multiple ? event.target.files : event.target.files[0];
            updatedFormElement.value = event.target.multiple ? 
                event.target.files.length + " file(s) selected" : event.target.files[0].name;
        } else {
            updatedFormElement.value = event.target.value;
        }
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[taxType][inputIdentifier] = updatedFormElement;
        
        handleDependants(inputIdentifier, event.target.value);
        
        let formIsValid = true;
        for (let inputIdentifier in updatedForm[taxType]) {
            if (updatedForm[taxType][inputIdentifier].show) {
                formIsValid = updatedForm[taxType][inputIdentifier].valid && formIsValid;
            }
        }

        setComponentState({...componentState, form: updatedForm, formIsValid: formIsValid});
    }
    
    const editMoney = (input) => {
        const val = input.indexOf(".") > -1 ? input.split(".")[0] : input;
        const value = val.replace(/,/gi,'');
        let newValue = value;
        const iteration = Math.floor(value.length / 3);
        if (value.length > 3) {
            for (let index = 1; index <= iteration; index++) {
                newValue = newValue.slice(0, value.length - (3 * index)) + "," + newValue.slice(value.length - (3 * index));
            }
            newValue = newValue.charAt(0) === ',' ? newValue.replace(',','') : newValue;
        } else {
            newValue = val;
        }
        return input.indexOf(".") > -1 ? newValue + '.' + input.split(".")[1] : newValue;
    }

    const handleDependants = (identifier, inputValue) => {
        for (let formIdentifier in componentState.form[taxType]) {
            const element = componentState.form[taxType][formIdentifier];
            if (componentState.form[taxType][formIdentifier].benefactor === identifier && inputValue === 'Yes') {
                element.show = true;
                element.validation.required = true;
            }
            else if (componentState.form[taxType][formIdentifier].benefactor === identifier && inputValue === 'No') {
                element.show = false;
            }
        }
    }

    const checkValidity = (value, rules) => {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.min) {
            isValid = value >= rules.min && isValid;
        }
        if (rules.max) {
            isValid = value <= rules.max && isValid;
        }
        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }
        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }
        if (rules.money) {
            const figure = value.replace(/,/gi,''); 
            isValid = !isNaN(figure) && !isNaN(parseFloat(figure)) && isValid;            
        }
        
        return isValid;
    }

    const [request, { loading: requestLoading, data: requestSuccess }] = useMutation(REQUEST_COMPUTATION);
    if (requestLoading) {
        pageLoading = true;
    }
    if (requestSuccess) {
        requestSent = true;
    }
    const formHandler = (event) => {
        event.preventDefault();
        let period;
        let text_info = [], document_types = [], documents = [];
        for (let key in componentState.form[taxType]) {
            if (key === 'month') {
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                const month = months[new Date(componentState.form[taxType][key].value).getMonth()];
                period = month + ", " + componentState.form[taxType][key].value.split("-")[0];
            } else if (key === 'year') {
                period = componentState.form[taxType][key].value;
            } else {
                if (componentState.form[taxType][key].elementType === 'file' && componentState.form[taxType][key].show) {
                    document_types.push(componentState.form[taxType][key].label);
                    documents.push(componentState.form[taxType][key].file);
                }
                else if (componentState.form[taxType][key].elementType !== 'file' && componentState.form[taxType][key].show) {
                    const obj = {};
                    obj[componentState.form[taxType][key].label] = componentState.form[taxType][key].value;
                    text_info.push(JSON.stringify(obj));
                }
            }
        }

        const variables = {
            type: pageTitle,
            text_info: text_info.length > 0 ? text_info : null,
            document_types: document_types,
            documents: documents,
            period: period
        };
        request({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in componentState.form[taxType]) {
        formELementsArray.push({
            id: key,
            config: componentState.form[taxType][key]
        });
    }

    return(
        <div>
            <Breadcrumb name={pageTitle + ' computation'} />

            {!requestSent ?
                <form className="dashform-box" onSubmit={formHandler}>
                    <div className="dashform">
                        <div className="dashform-intro">
                            Upload appropriate documents to compute your <b>{pageTitle}</b>.
                        </div>

                        {formELementsArray.map(formElement => {
                            return (
                                <div key={formElement.id}>
                                    {formElement.config.show ? 
                                        <DashInput name={formElement.id} value={formElement.config.value}
                                            label={formElement.config.label} invalid={!formElement.config.valid}
                                            shouldValidate={formElement.config.validation}
                                            touched={formElement.config.touched}
                                            description={formElement.config.description}
                                            typeInfo={formElement.config.typeInfo}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            inputChanged={(event) => inputChangedHandler(event, formElement.id)} />
                                    : null}
                                </div>
                            )
                        })}
                        <div className="submit-box dash-input-submit">
                            <button type="submit" className="submit right" disabled={!componentState.formIsValid} >
                                Submit
                            </button>
                        </div>
                    </div>
                </form> :
                <div className="dashform-box">
                    <div className="dashform">
                        <div className="dashform-intro text-center">
                            Your <b>{pageTitle}</b> computation request has been sent.
                        </div>
                        <div className="dashform-message">
                            You will be notified immediately the result is ready.<br/><br/><br/>
                            <Link to="/dashboard/computation" className="link a">Tax computation</Link>
                        </div>
                    </div>
                </div>
            }

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default ComputationForm;