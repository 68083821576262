import gql from 'graphql-tag';

const SIGNUP_MUTATION = gql`
    mutation($first_name: String!, $last_name: String!, $email: String!,
        $password: String!, $password_confirmation: String!){
            register_user(input: {
                first_name: $first_name, last_name: $last_name, email: $email, password: $password,
                password_confirmation: $password_confirmation
            }) {
                registered
            }
    }
`;

const LOGIN_MUTATION = gql`
    mutation($email: String!, $password: String!){
        user_login(input: {
            email: $email, password: $password
        })  
        {
            error, authorization, trial, expires_in
        }
    }
`;

const LOGOUT_MUTATION = gql`
    mutation {
        user_logout
    } 
`;

const IS_LOGGED_IN = gql`
    {
        isLoggedIn @client
        business @client {
            id, user_id, name, slug, type, employees, turnover, designation, rc_no, bn_no, tin,
            subscribed, vat_reg_no, address, tier, verified, created_at, updated_at,
            cac_docs { files }, consultant { first_name, last_name, qualification }
        }
        trial @client
        tokenExpired @client
    }
`;

const REQUEST_RESET_PASSWORD = gql`
    mutation($email: String!){
        user_password_reset_link(email: $email)
    }
`;

const RESET_PASSWORD = gql`
    mutation($token: String!, $email: String!, $password: String!, $password_confirmation: String!){
        user_reset_password(input: {
            token: $token, email: $email, password: $password, password_confirmation: $password_confirmation
        })
    }
`;

const VERIFY_EMAIL = gql`
    # mutation($token: String!, $email: String!, $password: String!, $password_confirmation: String!){
    #     user_reset_password(input: {
    #         token: $token, email: $email, password: $password, password_confirmation: $password_confirmation
    #     })
    # }
    mutation($code: String!){
        verify_email(code: $code){
            access_token, token_type, authorization, expires_in, error, trial
        }
    }
`;


export { 
    SIGNUP_MUTATION, LOGIN_MUTATION, LOGOUT_MUTATION, IS_LOGGED_IN, REQUEST_RESET_PASSWORD, RESET_PASSWORD,
    VERIFY_EMAIL 
};