import React, { useState } from 'react';
import './List.css';
import { Link } from 'react-router-dom';

const List = (props) => {
    const [componentState, setComponentState] = useState({
        listSearch: {
            validation: {required: true},
            value: '', valid: false, touched: false
        },
        searchIsValid: false
    });
    const inputChangedHandler = (event) => {
        const updatedlistSearch = {
            ...componentState.listSearch,
        };
        updatedlistSearch.value = event.target.value;
        updatedlistSearch.valid = checkValidity(updatedlistSearch.value);
        updatedlistSearch.touched = true;
  
        setComponentState({ listSearch: updatedlistSearch, searchIsValid: updatedlistSearch.valid });
    };
    const checkValidity = (value) => {
        let isValid = true;
        isValid = value.trim() !== '' && isValid;
        return isValid;
    };

    const filterSubmit = (event, type) => {
        event.preventDefault();
        props.filterClicked(event, type);
    }

    return (
        <div className={`list-box ${props.w}`}>
            <div className={`list ${props.h}`}>
                <div className="list-head">
                    <div className="list-name">
                        <span className="hidden-xs">{props.title}</span>
                        <span className="visible-xs">History</span>
                        <div className="search-box">
                            {props.filter ?
                                <div className={`filter ${props.filteredItems.length > 0 ? 'active' : null}`}>
                                    <i className="la la-filter"></i>
                                    <i className="la la-redo-alt" onClick={props.clear} title="Clear filter"></i>
                                    <div className="filter-list">
                                        {props.filter.map(filterGroup => {
                                            if (filterGroup.title === 'MONTH' || filterGroup.title === 'YEAR') {
                                                return (
                                                    <form key={filterGroup.title}
                                                        onSubmit={(event) => filterSubmit(event, filterGroup.title)}>
                                                        <h3>Period - {filterGroup.title}</h3>
                                                        {filterGroup.title === 'MONTH' ?
                                                            <input type="month" required /> :
                                                            <input type="number" min="1990" max={new Date().getFullYear()} required />
                                                        }
                                                        <button type="submit">Filter</button>
                                                    </form>
                                                );
                                            } else {
                                                return (
                                                    <div key={filterGroup.title}>
                                                        <h3>{filterGroup.title}</h3>
                                                        {filterGroup.items.map(filterElement => {
                                                            return (
                                                                <label className="item" key={filterElement} >
                                                                    {filterElement}
                                                                    <input type="checkbox" value={filterElement}
                                                                        checked={props.filteredItems.includes(filterElement)}
                                                                        onChange={(event) => props.filterClicked(event, filterGroup.title)}/>
                                                                </label>
                                                            );                                         
                                                        })}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </div> : null
                            }
                            {props.search ?
                                <form className="list-search hidden-xs" onSubmit={(event) => props.search(event, componentState.listSearch.value)}>
                                    <input type="search" placeholder="Search list"
                                        onChange={event => inputChangedHandler(event)} />
                                    <button type="submit" disabled={!componentState.searchIsValid}>
                                        <i className="la la-search"></i>
                                    </button>
                                </form> : null
                            }
                        </div>
                    </div>
                    <div className="list-header hidden-xs">
                        { props.type === 'health' ?
                            <>
                                <div className="w-15">Date</div>
                                <div className="w-20">Tax type</div>
                                <div className="w-25">Score</div>                        
                                <div className="w-40">Analysed on</div>
                            </> : null
                        }
                        
                        { props.type === 'computation' ?
                            <>
                                <div className="w-15">Date</div>
                                <div className="w-20">Tax type</div>
                                <div className="w-15">Tax period</div>
                                <div className="w-15">Amount</div>
                                <div className="w-35">Analysed on</div> 
                            </> : null
                        }
                        
                        { props.type === 'support' ?
                            <>
                                <div className="w-15">Date</div>
                                <div className="w-15">Ticket ID</div>
                                <div className="w-60">Subject</div>
                                <div className="w-10">Status</div>
                            </> : null
                        }
                        
                        { props.type === 'subscription' ?
                            <>
                                <div className="w-15">Plan</div>
                                <div className="w-15">Starts on</div>
                                <div className="w-15">Ends on</div>
                                <div className="w-20">Code</div>
                                <div className="w-20">Token</div>
                                <div className="w-15">Status</div>
                            </> : null
                        }
                    </div> 
                </div>
                <div className="list-item-box">
                    {props.pagination.total === 0 ?
                        <div className="no-item">
                            You currently have no record.
                        </div> : null
                    }

                    { props.list.map(listItem => {
                        let path = "#";
                        if (props.type === 'health') {
                            if (listItem.score === 0) { listItem['theme'] = 'gray';}
                            else if (listItem.score >= 0 && listItem.score < 50) { listItem['theme'] = 'red';}
                            else if (listItem.score >= 50 && listItem.score < 65) { listItem['theme'] = 'purple'}
                            else if (listItem.score >= 65 && listItem.score < 90) { listItem['theme'] = 'cyan'}
                            else if (listItem.score >= 90 && listItem.score <= 100) { listItem['theme'] = 'green'}
                            path = "/dashboard/health/report/";
                        }  else if (props.type === 'computation') {
                            path = "/dashboard/computation/report/";
                        } else if (props.type === 'support') {
                            if (listItem.closed) { listItem['theme'] = 'green';}
                            else { listItem['theme'] = 'red'}
                            path = "/dashboard/support/ticket/";
                        }

                        return (
                            <Link to={path + (props.type === 'subscription' ? null : (props.type === 'support' ? listItem.support_id : listItem.id))}
                              className="list-item" key={props.type === 'subscription' ? listItem.code : listItem.id}>
                                { props.type === 'health' ?
                                    <>
                                        <div className="w-15">
                                            <b className="visible-xs">Date: </b>
                                            {new Date(listItem.created_at).toDateString()}
                                        </div>
                                        <div className="w-20 list-item-name"><b className="visible-xs">Type: </b>{listItem.type}</div>
                                        <div className="w-25">
                                            <div className="visible-xs"><b>Score:</b></div>
                                            {listItem.score >= 0 ?
                                                <>
                                                    <div className="list-item-score">
                                                        <span className={`score-meter ${listItem.theme}`} style={{width: (listItem.score > 0 ? listItem.score : "100") + '%'}}></span>
                                                    </div>
                                                    <b className={`list-item-score-value ${listItem.theme}`}>
                                                        {listItem.score}%
                                                    </b> 
                                                </> : <div className="list-item-not-ready">Not yet ready</div>
                                            }
                                        </div>
                                        <div className="w-40 list-item-text">
                                            <div className="visible-xs"><b>Report:</b></div>
                                            {listItem.report ?
                                                new Date(listItem.updated_at).toDateString() :
                                                <div className="list-item-not-ready">Not yet ready</div>
                                            }
                                        </div>
                                    </> : null
                                }

                                { props.type === 'computation' ?
                                    <>
                                        <div className="w-15">
                                            <b className="visible-xs">Date: </b>
                                            {new Date(listItem.created_at).toDateString()}
                                        </div>
                                        <div className="w-20 list-item-name"><b className="visible-xs">Type: </b>{listItem.type}</div>
                                        <div className="w-15">
                                            <b className="visible-xs">Period: </b>
                                            {listItem.period}
                                        </div>
                                        <div className="w-15">
                                            <b className="visible-xs">Amount: </b>
                                            {listItem.amount ?
                                                <>&#8358;<b>{listItem.amount}</b></> :
                                                <div className="list-item-not-ready">Not yet ready</div>
                                            }
                                            
                                        </div>
                                        <div className="w-35 list-item-text">
                                            <div className="visible-xs"><b>Analysed on:</b></div>
                                            {listItem.report ?
                                                new Date(listItem.updated_at).toDateString() :
                                                <div className="list-item-not-ready">Not yet ready</div>
                                            }
                                        </div>
                                    </>: null
                                }

                                { props.type === 'support' ?
                                    <>
                                        <div className="w-15">
                                            <b className="visible-xs">Date: </b>
                                            {new Date(listItem.created_at).toDateString()}
                                        </div>
                                        <div className="w-15 list-item-name">
                                            <b className="visible-xs">Ticket id: </b>
                                            {listItem.support_id}
                                        </div>
                                        <div className="w-60 list-item-name">
                                            <b className="visible-xs">Subject: </b>
                                            <b>{listItem.subject}</b>
                                        </div>
                                        <div className="w-10 list-item-text">
                                            <b className="visible-xs">Status: </b>
                                            <b className={`list-item-score-value ${listItem.theme}`}>
                                                {listItem.closed ? "resolved" : "open"}
                                            </b>
                                        </div> 
                                    </>: null
                                }

                                { props.type === 'subscription' ?
                                    <>
                                        <div className="w-15 list-item-name"><b className="visible-xs">Plan: </b>{listItem.plan}</div>
                                        <div className="w-15">
                                            <b className="visible-xs">Starts on: </b>
                                            {new Date(listItem.start).toDateString()}
                                        </div>
                                        <div className="w-15">
                                            <b className="visible-xs">Ends on: </b>
                                            {new Date(listItem.end).toDateString()}
                                        </div>
                                        <div className="w-20">
                                            <b className="visible-xs">Code:</b>
                                            {listItem.code}
                                        </div>
                                        <div className="w-20">
                                            <b className="visible-xs">Token:</b>
                                            {listItem.token}
                                        </div>
                                        <div className="w-15">
                                            <b className="visible-xs">Status:</b>
                                            {listItem.status}
                                        </div>
                                    </>: null
                                }
                                
                            </Link>
                        );
                    })}
                </div>
                <div className="list-pagination">
                    {props.pagination.total > 0 ? <>
                        {props.pagination.currentPage > 1 ?
                            <button onClick={() => props.pageClicked(props.pagination.currentPage - 1)}>
                                <i className="la la-angle-double-left"></i>Prev
                            </button> : null
                        }
                        {(props.pagination.currentPage - 2) > 1 ?
                            <><button onClick={() => props.pageClicked(1)}>1</button>...</> : null
                        }
                        {props.pagination.currentPage > 1 ?
                            <button onClick={() => props.pageClicked(props.pagination.currentPage - 1)}>
                                {props.pagination.currentPage - 1}
                            </button> : null
                        }

                        <button className="active">{props.pagination.currentPage}</button>

                        {props.pagination.currentPage < props.pagination.lastPage ?
                            <button onClick={() => props.pageClicked(props.pagination.currentPage + 1)}>
                                {props.pagination.currentPage + 1}
                            </button> : null
                        }                 
                        {props.pagination.currentPage === 1 && props.pagination.lastPage > 2 ?
                            <button onClick={() => props.pageClicked(props.pagination.currentPage + 2)}>
                                {props.pagination.currentPage + 2}
                            </button> : null
                        }
                        {(props.pagination.currentPage + 2) < props.pagination.lastPage ? <>...
                            <button onClick={() => props.pageClicked(props.pagination.lastPage)}>
                                {props.pagination.lastPage}
                            </button></> : null
                        }
                        {props.pagination.currentPage < props.pagination.lastPage ?
                            <button onClick={() => props.pageClicked(props.pagination.currentPage + 1)}>
                                Next<i className="la la-angle-double-right"></i>
                            </button> : null
                        }</> : null
                    }
                </div>
            </div>
        </div>
    );
}

export default List;