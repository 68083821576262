import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';

import { typeDefs } from './store/resolvers';

const trackingId = "G-JDXMDVWZF3"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const cache = new InMemoryCache();
const httpLink = createUploadLink({
    uri: process.env.REACT_APP_URL + '/v1'
});


const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('auth') || null,
    }
  }));

  return forward(operation);
})

const logoutLink = onError(({graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors[0].debugMessage === "Unauthenticated.") {
    localStorage.removeItem("auth");
    localStorage.removeItem("trial");
    cache.writeData({
      data: {
          isLoggedIn: false, business: null, trial: null, tokenExpired: true
      }
    });
  };
})

const client = new ApolloClient({    
    cache,
    link: logoutLink.concat(from([authMiddleware, httpLink])),
    typeDefs
})

cache.writeData({
    data: {
        isLoggedIn: localStorage.getItem('auth') ? true : false,
        business: null, trial: localStorage.getItem('trial') ? true : false, tokenExpired: false
    }
});

const app = (
    <ApolloProvider client={client}>
        <HashRouter history={history}><App /></HashRouter>
    </ApolloProvider>
)
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
