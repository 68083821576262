import React, { useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Card from '../../components/Card/Card';
import List from '../../components/List/List';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { useQuery, useLazyQuery } from 'react-apollo';
import { COMPUTATION_LIST } from '../../Queries';

const Computation = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [filtered, setFiltered] = useState({
        column: 'TYPE', operator: 'NOT_IN', value: []
    });

    let pageLoading = false;
    const {loading: computationLoading, data: computationListData} = useQuery(COMPUTATION_LIST, {
        variables: {
            column: filtered.column, operator: filtered.operator,
            value: filtered.value, first: 15, page: currentPage
        },
        fetchPolicy: "network-only"
    });
    if (computationLoading) {
        pageLoading = true;
    }
    let computationList = [];
    let listPagination = {};
    if (computationListData && computationListData.filter_list_tcf) {
        computationList = computationListData.filter_list_tcf.data;
        listPagination = computationListData.filter_list_tcf.paginatorInfo;
    }

    const filter = [
        {
            title: 'MONTH',
        },
        {
            title: 'YEAR'
        },
        {
            title: 'TYPE',
            items: ['Value Added Tax', 'Personal Income Tax', 'Withholding Tax', 'Company Income Tax']
        }
    ]
    const [filterData, {loading: filterLoading}] = useLazyQuery(COMPUTATION_LIST, {
        fetchPolicy: "network-only"
    });
    if (filterLoading) {
        pageLoading = true;
    }    
    const handleFilter = (event, type) => {
        pageLoading = true;
        let newFiltered = {...filtered};
        let filteredValue = newFiltered.value;
        let period, column, index;

        if (type === 'MONTH') {
            filteredValue = [];
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const month = months[new Date(event.target.elements[0].value).getMonth()];
            period = month + ", " + event.target.elements[0].value.split("-")[0];

            column = "PERIOD";
            index = filteredValue.indexOf(period);
        } else if (type === 'YEAR') {
            filteredValue = [];
            period = event.target.elements[0].value;

            column = "PERIOD";
            index = filteredValue.indexOf(period);
        } else {
            column = type;
            index = filteredValue.indexOf(event.target.value);
        }

        if (index > -1) {
            filteredValue.splice(index, 1);
        } else {
            filteredValue.push(period ? period : event.target.value);
        }

        let operator;
        if (filteredValue.length > 0){ 
            operator = 'IN'
        }
        else {
            operator = 'NOT_IN'
        }
        
        setFiltered({
            column: column, operator: operator, value: filteredValue
        });
        filterData({
            variables: {
                column: column, operator: operator,
                value: filteredValue, first: 15, page: 1
            },
            onCompleted: data => {
                computationList = data.filter_list_tax_health.data;
                listPagination = data.filter_list_tax_health.paginatorInfo;
            }
        })
    }

    const clearFilter = () => {
        setFiltered({
            column: 'TYPE', operator: 'NOT_IN', value: []
        });
    }

    const handlePagination = (page) => {
        setCurrentPage(page);
    }

    return (
        <div>
            <Breadcrumb name="Tax computation and filing" />

            <div>
                <Card w="w-20" h="h-200" align="text-center" theme="cyan" icon="calculator" xs="w-50-s"
                    header0={["Compute", "and file it"]} header3="Value Added Tax" link="/dashboard/computation/vat" />
                <Card w="w-20" h="h-200" align="text-center" theme="cyan" icon="calculator" xs="w-50-s"
                    header0={["Compute", "and file it"]} header3="Withholding Tax" link="/dashboard/computation/wt" />
                <Card w="w-20" h="h-200" align="text-center" theme="cyan" icon="calculator" xs="w-50-s"
                    header0={["Compute", "and file it"]} header3="Company Income Tax" link="/dashboard/computation/cit" />
                <Card w="w-20" h="h-200" align="text-center" theme="cyan" icon="calculator" xs="w-50-s"
                    header0={["Compute", "and file it"]} header3="Direct Assessment" link="/dashboard/computation/da" />
                <Card w="w-20" h="h-200" align="text-center" theme="cyan" icon="calculator" xs="w-50-s"
                    header0={["Compute", "and file it"]} header3="PAYE" link="/dashboard/computation/paye" />
            </div>
            <div>
                <List type="computation" title="Previous computations" list={computationList}
                    filter={filter} filteredItems={filtered.value} filterClicked={handleFilter} clear={clearFilter}
                    pagination={listPagination} pageClicked={handlePagination} />
            </div>
            
            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default Computation;