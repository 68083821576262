import React from 'react';
import './Profile.css';
import { graphql} from 'react-apollo';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { BUSINESS_INFO, USER_INFO } from '../../Queries';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

const Profile = (props) => {
    let pageLoading = true;
    let business = {};
    let user = {};
    const documents = [];
    if (props.business && props.user) {
        business = props.business.user_business;
        user = props.user.me;
        
        const docsArray = [];
        business.cac_docs.forEach(element => {
            docsArray.push(JSON.parse(element.files));
        });
        
        const files = business.cac_docs[0] ? Object.assign(...docsArray, {}) : null;
        if (files) {
            for (const key in files) {
                documents.push({
                    name: key, path: files[key]
                })
            }
        }
        documents.reverse();
        pageLoading = false;
    }

    const editTurnover = (input) => {
        let val = input.indexOf(".") > -1 ? input.split(".")[0] : input;
        let value = val.replaceAll(',','');
        let newValue = value;
        const iteration = Math.floor(value.length / 3);
        if (value.length > 3) {
            for (let index = 1; index <= iteration; index++) {
                newValue = newValue.slice(0, value.length - (3 * index)) + "," + newValue.slice(value.length - (3 * index));
            }
            newValue = newValue.charAt(0) === ',' ? newValue.replace(',','') : newValue;
        } else {
            newValue = val;
        }
        return input.indexOf(".") > -1 ? newValue + '.' + input.split(".")[1] : newValue;
    }

    return (
        <div>
            {pageLoading ? <PageLoading ripple={true} /> : null }
            
            <Breadcrumb name="Profile" />

            <div className="profile-box">
                <div className="profile">
                    <div className="profile-intro">
                        <div className="desc big">
                            <div className="label">Business name</div>
                            <div className="value">{business.name}</div>
                        </div>
                        <div className="desc wide">
                            <div className="label">Business address</div>
                            <div className="value editable">{business.address}</div>
                        </div>
                        <div className="desc wide-mob">
                            <div className="label">Yearly turnover</div>
                            <div className="value editable">
                                <span className="naira">&#8358;</span>{editTurnover(business.turnover)}
                            </div>
                        </div>
                        <div className="desc">
                            <div className="label">Employees</div>
                            <div className="value editable">
                                {business.employees}
                            </div>
                        </div>
                        <div className="desc">
                            <div className="label">Business tier</div>
                            <div className="value editable">
                                {business.tier}
                            </div>
                        </div>
                        <div className="desc">
                            <div className="label">Business type</div>
                            <div className="value">
                                {business.type}
                            </div>
                        </div>
                        {business.rc_no ? 
                            <div className="desc">
                                <div className="label">RC number</div>
                                <div className="value">
                                    {business.rc_no}
                                </div>
                            </div> : null
                        }
                        {business.bn_no ? 
                            <div className="desc">
                                <div className="label">BN number</div>
                                <div className="value">
                                    {business.bn_no}
                                </div>
                            </div> : null
                        }
                        <div className="desc">
                            <div className="label">TIN</div>
                            <div className="value">
                                {business.tin}
                            </div>
                        </div>
                        <div className="desc">
                            <div className="label">VAT number</div>
                            <div className="value">
                                {business.vat_reg_no}
                            </div>
                        </div>

                        <div className="profile-links">
                            <Link to="/dashboard/profile/edit-business" className="profile-link">Edit business info</Link>
                        </div>
                    </div>
                    
                    <div className="profile-section">
                        <h1 className="title">
                            Admin officer Info
                        </h1>
                        <div className="profile-text">
                            <div className="desc">
                                <div className="label">Full name</div>
                                <div className="value">
                                    {props.user.me ? user.first_name + " " + user.last_name : null}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="label">Email</div>
                                <div className="value">{props.user.me ? user.email : null}</div>
                            </div>
                            <div className="desc">
                                <div className="label">Business designation</div>
                                <div className="value editable">{business.designation}</div>
                            </div>

                            <div className="profile-links">
                                <Link to="/dashboard/profile/edit-user" className="profile-link">Edit admin info</Link>
                            </div>
                        </div> 
                    </div> 

                    <div className="profile-section">
                        <h1 className="title">
                            Business documents
                        </h1>
                        
                        <div className="profile-text">
                            {documents.length > 0 ? 
                                documents.map(document => {
                                    return (
                                        <div className="profile-assessment" key={document.name}>
                                            <div className="question">
                                                {document.name}
                                            </div>
                                            <div className="answer">
                                                <a target="_blank" rel="noopener noreferrer" href={document.path} download>
                                                    Download <i className="la la-download"></i>
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })
                            : <i>You are yet to upload your documents.</i>}
                            
                            <div className="profile-links">
                                <Link to="/dashboard/profile/edit-business" className="profile-link">Upload documents</Link>
                            </div>
                        </div>
                    </div>

                    <div className="profile-section">
                        <h1 className="title">
                            Consultant Info
                        </h1>
                        <div className="profile-text">
                            {business.consultant ? <>
                                <div className="desc">
                                    <div className="label">Full name</div>
                                    <div className="value">
                                        {business.consultant.first_name} {business.consultant.last_name}
                                    </div>
                                </div>
                                <div className="desc">
                                    <div className="label">Qualification</div>
                                    <div className="value">{business.consultant.qualification}</div>
                                </div>
                                </> : 
                                <div className="desc">
                                    <div className="label">Unassigned</div>
                                    <div className="value">
                                        Check again later
                                    </div>
                                </div>
                            }
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default compose(
    graphql(BUSINESS_INFO, {
        name: "business"
    }),
    graphql(USER_INFO, {
        name: "user"
    })
)(Profile);