import React from 'react';
import './AuthPages.css';
import { Route, Redirect, Switch } from 'react-router-dom';

import Login from './Login/Login';
import Signup from './Signup/Signup';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Verifying from './Verify/Verifying';

import { IS_LOGGED_IN } from '../AuthPages/Queries';
import { useQuery } from 'react-apollo';
import PageNotFound from '../components/PageNotFound/PageNotFound';

const AuthPages = () => {
    const { data: loggedIn } = useQuery(IS_LOGGED_IN);

    if (loggedIn.isLoggedIn) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className="auth-page">
            <div className="auth-form">
                <Switch>
                    <Route path="/login" exact component={Login} />
                    <Route path="/signup" exact component={Signup} />
                    <Route path="/forgot-password" exact component={ForgotPassword} />
                    <Route path="/password/reset/:token" exact component={ResetPassword} />
                    <Route path="/user/verify/:token" exact component={Verifying} />
                        
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </div>
        </div>
    );
}

export default AuthPages;