import React, { useState } from "react";
import { Link } from "react-router-dom";

import Input from "../../components/Input/Input";
import PageLoading from '../../components/PageLoading/PageLoading';

import { SIGNUP_MUTATION } from '../Queries';
import { useMutation } from 'react-apollo';
import Terms from "../../components/modals/Terms";

const Signup = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [componentState, setComponentState] = useState({
    signupForm: {
        firstName: {
            label: 'First name', elementType: 'input',
            elementConfig: { type: 'text', autoFocus: true },
            validation: {required: true},
            value: '', message: '', valid: false, touched: false
        },
        lastName: {
            label: 'Last name', elementType: 'input',
            elementConfig: { type: 'text' },
            validation: {required: true},
            value: '', message: '', valid: false, touched: false
        },
        email: {
            label: 'Email', elementType: 'input',
            elementConfig: { type: 'email' },
            validation: {required: true, email: true},
            value: '', message: '', valid: false, touched: false
        },
        password: {
            label: 'Password', elementType: 'input',
            elementConfig: { type: 'password' },
            validation: {required: true, minLength: 8},
            value: '', message: '', valid: false, touched: false
        },
        confirmPassword: {
            label: 'Confirm password', elementType: 'input',
            elementConfig: { type: 'password' },
            validation: {required: true, confirmPassword: true},
            value: '', message: '', valid: false, touched: false
        }
    },
    formIsValid: false,
    loading: false
  });
  let pageLoading = false;

  const inputChangedHandler = (event, inputIdentifier) => {
      const updatedSignupForm = {
      ...componentState.signupForm,
      };
      const updatedFormElement = {
      ...updatedSignupForm[inputIdentifier],
      };
      updatedFormElement.value = event.target.value;
      if (componentState.signupForm[inputIdentifier].elementConfig.type === "file") {
      updatedFormElement.file = event.target.files[0];
      }
      const validityStatus = checkValidity(
        updatedFormElement.value,
        updatedFormElement.validation,
        componentState.signupForm[inputIdentifier].elementConfig.type,
      );
      updatedFormElement.valid = validityStatus.validity;
      updatedFormElement.message = validityStatus.message;
      updatedFormElement.touched = true;
      updatedSignupForm[inputIdentifier] = updatedFormElement;

      let formIsValid = true;
      for (let inputIdentifier in updatedSignupForm) {
      formIsValid = updatedSignupForm[inputIdentifier].valid && formIsValid;
      }

      setComponentState({ ...componentState, signupForm: updatedSignupForm, formIsValid: formIsValid });
  };

  const checkValidity = (value, rules, type) => {
    let isValid = true;
    let message = '';

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
        message = isValid ? '' : 'This field is required';
    }
    if (rules.email) {
        const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValid = isEmail.test(value.trim()) && isValid;
        message = isValid ? '' : 'Use a valid email';
    }
    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        message = isValid ? '' : 'Password should be at least ' + rules.minLength + ' characters';
    }
    if (rules.confirmPassword) {
        isValid = value === componentState.signupForm.password.value && isValid;
        message = isValid ? '' : 'Should be the same as Password';
    }

    return {validity: isValid, message: message};
  };

  let authDetailsError = false;
  let signupSuccess = false;
  const [signupUser, { loading: signupLoading, error: signupError, data: userData }] = useMutation(SIGNUP_MUTATION);
  if (signupLoading) {
    pageLoading = true;
  }
  if (signupError) {
    // let { graphQLErrors } = signupError;
    // console.log(graphQLErrors[0]);
    authDetailsError = true;
  }
  if (userData) {
    if (userData.register_user.error) {
      authDetailsError = true;
    } else if (userData.register_user.registered) {
      signupSuccess = true;
    }
  }
  
  const formHandler = event => {
    event.preventDefault();

    const variables = {
      first_name: componentState.signupForm.firstName.value,
      last_name: componentState.signupForm.lastName.value,
      email: componentState.signupForm.email.value,
      password: componentState.signupForm.password.value,
      password_confirmation: componentState.signupForm.confirmPassword.value
    }

    signupUser({ variables: variables });
  };

  const formELementsArray = [];
  for (let key in componentState.signupForm) {
    formELementsArray.push({
      id: key,
      config: componentState.signupForm[key],
    });
  }

  return (
    <>
      <form onSubmit={formHandler}>
        <h1>Sign up</h1>
        
        {formELementsArray.map(formElement => (
          <Input
            key={formElement.id}
            value={formElement.config.value}
            label={formElement.config.label}
            invalid={!formElement.config.valid}
            message={formElement.config.message}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            inputChanged={event => inputChangedHandler(event, formElement.id)}
          />
        ))}
        <div>
          <label className="terms-agreement">
            <input type="checkbox" checked={agreedToTerms}  onChange={() => setAgreedToTerms(!agreedToTerms)}/>
            I agree to the <button type="button" onClick={() => setShowTerms(true)}>TERMS &amp; CONDITIONS</button> of service.
          </label>
        </div>
        <div className="submit-box">
          {authDetailsError ?
            <div className="auth-message auth-error">
                <b>OOPS!</b> Looks like you already have an account.<br/>
                <Link to="/login" className="lnk">Login</Link> with your email.
            </div> : null
          }
          <button
            type="submit"
            className="submit"
            disabled={!componentState.formIsValid || !agreedToTerms}
          >
            Sign up
          </button>
          <div className="text">
            <Link to="/login">Login</Link> if you already have an account.
          </div>
        </div>
        
        <div className={`signup-modal-backdrop ${signupSuccess ? 'active' : null}`} id="signup-modal-backdrop">
          <div className={`signup-modal ${signupSuccess ? 'active' : null}`} id="signup-modal">
            <h2>Thank you</h2>
            A verification link has been sent to your email. <br/><br/>
            Kindly check your email to proceed.
            <Link to="/login" className="cancel">Close</Link>
          </div>
        </div>
        
        {pageLoading ? <PageLoading ripple={true} /> : null}
      </form>

      <Terms show={showTerms ? true : false} close={() => setShowTerms(false)}
        agree={() => setAgreedToTerms(true)} />
    </>
  );
}

export default Signup;
