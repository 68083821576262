import gql from 'graphql-tag';

const USER_INFO = gql`
    {
        me{
            id, first_name, last_name, email, identity, created_at, updated_at
        }
    }
`;

const BUSINESS_INFO = gql`
    {
        user_business{
            id, user_id, name, slug, type, employees, turnover, designation, rc_no, bn_no, tin,
            subscribed, vat_reg_no, address, tier, verified, created_at, updated_at,
            cac_docs { files }, consultant { first_name, last_name, qualification }
        }
    }
`;

const REGISTER_BUSINESS = gql`
    mutation($name: String!, $designation: String!, $type: String!, $employees: Int!, $turnover: String!, $rc_no: String, $bn_no: String,
        $tin: String, $vat_reg_no: String, $address: String!, $phone: String!, $doc_types: [String!], $cac_docs: [Upload!], $identity: Upload){
        register_business(input: {
            name: $name, designation: $designation, type: $type, employees: $employees, turnover: $turnover, rc_no: $rc_no, bn_no: $bn_no,
            tin: $tin, vat_reg_no: $vat_reg_no, address: $address, phone: $phone, doc_types: $doc_types, cac_docs: $cac_docs, identity: $identity
        }) {
            id, user_id, name, type, employees, rc_no, bn_no, tin, vat_reg_no, address,
            tier, verified, created_at, updated_at, cac_docs { files }
        }
    }
`;

const UPDATE_BUSINESS = gql`
    mutation($address: String!, $turnover: String!, $designation: String!, $type: String!,
        $employees: Int!, $doc_types: [String!], $cac_docs: [Upload!], $identity: Upload){
        update_business(input: {
            address: $address, turnover: $turnover, designation: $designation, type: $type,
            employees: $employees, doc_types: $doc_types, cac_docs: $cac_docs, identity: $identity
        }) {
            id, user_id, name, slug, type, employees, turnover, designation, rc_no, bn_no, tin,
            subscribed, vat_reg_no, address, tier, verified, created_at, updated_at,
            cac_docs { files }, consultant { first_name, last_name, qualification }
        }
    }
`;

const UPDATE_USER = gql`
    mutation($first_name: String!, $last_name: String!, $email: String){
        update_user(input: {
            first_name: $first_name, last_name: $last_name, email: $email
        }) {updated}
    }
`;

const REQUEST_HEALTH_CHECK = gql`
    mutation($type: String!, $assessment: String!, $score: Int!){
        request_tax_health(input: {
            type: $type, assessment: $assessment, score: $score
        }) { 
            id, score
        }
    }
`;

const LATEST_HEALTH_REPORT = gql`
    {
        last_tax_health{
            type, score, created_at
        }
    }
`;

const HEALTH_REPORT_LIST = gql`
    query($column: THCColumn, $operator: SQLOperator, $value: Mixed, $first: Int!, $page: Int!){
        filter_list_tax_health(where: {column: $column, operator: $operator, value: $value}, first: $first, page: $page){
            paginatorInfo{
                count, currentPage, firstItem, hasMorePages, lastItem, lastPage, perPage, total
            }
            data{
                id, type, score, report, created_at, updated_at
            }
        }
    }
`;

const HEALTH_REPORT = gql`
    query($id: Int!){
        view_tax_health_report(id: $id){
            id, type, assessment, score, report, created_at, updated_at
        }
    }
`;

const REQUEST_COMPUTATION = gql`
    mutation($type: String!, $text_info: [String!], $document_types: [String!]!, $documents: [[Upload!]]!, $period: String!){
        request_tcf(input: {
            type: $type, text_info: $text_info, document_types: $document_types, documents: $documents, period: $period
        }){
            id
        }
    }
`;

const COMPUTATION_LIST = gql`
    query($column: TCFColumn, $operator: SQLOperator, $value: Mixed, $first: Int!, $page: Int!){
        filter_list_tcf(where: {column: $column, operator: $operator, value: $value}, first: $first, page: $page){
            paginatorInfo{
                count, currentPage, firstItem, hasMorePages, lastItem, lastPage, perPage, total
            }
            data{
                id, type, amount, period, report, status, created_at, updated_at
            }
        }
    }
`;

const COMPUTATION_REPORT = gql`
    query($id: Int!){
        view_tcf_report(id: $id){
            id, type, amount, period, text_info, documents, report, status, created_at, updated_at
        }
    }
`;

const CREATE_SUPPORT_TICKET = gql`
    mutation($subject: String!, $message: String!){
        new_support(input: {
            subject: $subject, message: $message
        }){
            id, support_id, subject, message, closed, created_at
        }
    }
`;

const SUPPORT_LIST = gql`
    query($first: Int!, $page: Int!){
        list_support(first: $first, page: $page){
            paginatorInfo{
                count, currentPage, firstItem, hasMorePages, lastItem, lastPage, perPage, total
            }
            data{
                id, support_id, subject, message, closed, created_at
            }
        }
    }
`;

const SUPPORT_TICKET = gql`
    query($support_id: String!){
        view_support(support_id: $support_id){
            support_id, subject, message, closed, created_at, updated_at
        }
    }
`;

const SEND_TICKET_MESSAGE = gql`
    mutation($support_id: String!, $message: String!){
        reply_support(input: {
            support_id: $support_id, message: $message
        }){
            id, support_id, subject, message, closed, created_at, updated_at
        }
    }
`;

const SUBSCRIPTION_PLANS = gql`
    query{
        plans{
            id, name, plan_code, description, amount, interval, currency, created_at, updated_at
        }
    }
`;

const SUBSCRIPTIONS = gql`
    query($month: String, $first: Int!, $page: Int!){
        subscriptions(month: $month, first: $first, page: $page){
            paginatorInfo{
                count, currentPage, firstItem, hasMorePages, lastItem, lastPage, perPage, total
            }
            data{
                code, token, start, end, plan, status, amount
            }
        }
    }
`;

const GET_PLAN_CODE = gql`
    mutation($code: String!){
        make_payment(code: $code){
            status, access_code
        }
    }
`;

const CANCEL_PAYMENT = gql`
    mutation($access_code: String!){
        cancelled_transaction(access_code: $access_code)
    }
`;

export {
    USER_INFO, BUSINESS_INFO, REGISTER_BUSINESS, UPDATE_BUSINESS, UPDATE_USER, REQUEST_HEALTH_CHECK,
    REQUEST_COMPUTATION, LATEST_HEALTH_REPORT, HEALTH_REPORT_LIST, HEALTH_REPORT, COMPUTATION_LIST,
    COMPUTATION_REPORT, CREATE_SUPPORT_TICKET, SUPPORT_LIST, SUPPORT_TICKET, SEND_TICKET_MESSAGE,
    SUBSCRIPTION_PLANS, SUBSCRIPTIONS, GET_PLAN_CODE, CANCEL_PAYMENT
};