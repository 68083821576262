import React, { useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';
import Input from "../../../components/Input/Input";

import { useQuery, useMutation } from 'react-apollo';
import { BUSINESS_INFO, UPDATE_BUSINESS } from '../../Queries';
import { Redirect } from 'react-router';

const EditBusiness = () => {
    let pageLoading = false;
    const {loading: businessLoading, data: businessData} = useQuery(BUSINESS_INFO);
    if (businessLoading) {
        pageLoading = true;
    }
    let business = {};
    if (businessData && businessData.user_business) {
        business = businessData.user_business;
    }

    const [componentState, setComponentState] = useState({
        form: {
            employees: {
                label: 'Number of employees', elementType: 'input',
                elementConfig: { type: 'number', min: 1, step: 1 },
                validation: {required: true},
                value: business.employees, valid: true, touched: false
            },
            turnover: {
                label: 'Yearly turnover (in Naria)', elementType: 'input',
                elementConfig: { type: 'text', placeholder: '0.00' },
                validation: {required: true, money: true},
                value: business.turnover, actualValue: business.turnover,
                valid: true, touched: false
            },
            designation: {
                label: 'Your designation', elementType: 'input',
                elementConfig: { type: 'text' },
                validation: {required: true},
                value: business.designation, valid: true, touched: false
            },
            type: {
                label: 'Business type', elementType: 'select',
                elementConfig: { options: [
                    {value: 'Enterprise', displayText: 'Enterprise'},
                    {value: 'Ventures', displayText: 'Ventures'},
                    {value: 'Limited Liability Company', displayText: 'Limited Liability Company'}
                ]},
                validation: {required: true},
                value: business.type, message: '', valid: true, touched: false
            },
            address: {
                label: 'Address', elementType: 'textarea',
                elementConfig: { type: 'text' },
                validation: {required: true},
                value: business.address, valid: true, touched: false
            },
            idcard: {
                label: 'Government issued ID', elementType: 'file',
                elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png" },
                typeInfo: "Select an image file",
                validation: { image: true }, show: true,
                value: '', message: '', file: {}, valid: true, touched: false
            },
            inc: {
                label: 'Certificate of Incorporation', elementType: 'file',
                elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                typeInfo: "Select pdf, doc or image file",
                validation: { doc: true }, show: true,
                value: '', message: '', file: {}, valid: true, touched: false
            },
            co2: {
                label: 'Form C02 & C07', elementType: 'file',
                elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                typeInfo: "Select pdf, doc or image file",
                validation: { doc: true }, show: true,
                value: '', file: {}, valid: true, touched: false
            },
            tin_cert: {
                label: 'TIN certificate', elementType: 'file',
                elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                typeInfo: "Select pdf, doc or image file",
                validation: { doc: true }, show: true,
                value: '', file: {}, valid: true, touched: false
            },
            vat_cert: {
                label: 'VAT certificate', elementType: 'file',
                elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                typeInfo: "Select pdf, doc or image file",
                validation: { doc: true }, show: true,
                value: '', file: {}, valid: true, touched: false
            }
        },
        formIsValid: false
    });


    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
        ...componentState.form,
        };
        const updatedFormElement = {
        ...updatedForm[inputIdentifier]
        };

        if (inputIdentifier === 'turnover') {
            updatedFormElement.actualValue = event.target.value.replaceAll(',','');
            updatedFormElement.value = editTurnover(event.target.value);
        } else if (updatedFormElement.elementConfig.type === "file") {
            updatedFormElement.file = event.target.files[0];
            updatedFormElement.value = event.target.files[0].name;
        } else {
            updatedFormElement.value = event.target.value;
        }

        const validityStatus = checkValidity(
            updatedFormElement.actualValue ? updatedFormElement.actualValue : updatedFormElement.value,
            updatedFormElement.validation,
            updatedFormElement.elementConfig.type === "file" ? event.target.files[0] : null
        );
        
        updatedFormElement.valid = validityStatus.validity;
        updatedFormElement.message = validityStatus.message;

        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
  
        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
  
        setComponentState({ ...componentState, form: updatedForm, formIsValid: formIsValid });
    };

    const editTurnover = (input) => {
        let val = input.indexOf(".") > -1 ? input.split(".")[0] : input;
        let value = val.replaceAll(',','');
        let newValue = value;
        const iteration = Math.floor(value.length / 3);
        if (value.length > 3) {
            for (let index = 1; index <= iteration; index++) {
                newValue = newValue.slice(0, value.length - (3 * index)) + "," + newValue.slice(value.length - (3 * index));
            }
            newValue = newValue.charAt(0) === ',' ? newValue.replace(',','') : newValue;
        } else {
            newValue = val;
        }
        return input.indexOf(".") > -1 ? newValue + '.' + input.split(".")[1] : newValue;
    }
  
    const checkValidity = (value, rules, file) => {
        let isValid = true;
        let message = '';
  
        if (rules.required) {
            const valid = value.trim() !== '';
            isValid = valid && isValid;
            message = valid ? '' : 'This field is required';
        }
        if (rules.money) {
            const isMoney = /^\d+(\.\d+)?$/;
            const valid = isMoney.test(value.trim());
            isValid = valid && isValid;
            message = valid ? '' : 'Input a valid amount of money';
        }
        if (rules.minLength) {
            const valid = value.length >= rules.minLength;
            isValid = valid && isValid;
            message = valid ? '' : 'Password should be at least ' + rules.minLength + ' characters';
        }
        if (rules.image) {
            const type = value.split(".")[value.split(".").length - 1];
            const acceptedTypes = ["jpg", "jpeg", "png"];
            const valid = acceptedTypes.includes(type.toLowerCase());
            isValid = valid && isValid;
            message = valid ? '' : 'File must be .jpeg  or .png';
        }
        if (rules.doc) {
            const type = value.split(".")[value.split(".").length - 1];
            const acceptedTypes = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "csv", "tsv", "xls", "xlsx"];;
            const valid = acceptedTypes.includes(type.toLowerCase());
            isValid = valid && isValid;
            message = valid ? '' : 'File must be .pdf, .doc, .docx, excel, csv, tsv, .jpeg or .png';
        }
        if (file) {
            const valid = file.size < 6000000;
            isValid = valid && isValid;
            message = valid ? '' : 'Size of attached file must be less than 6MB';
        }
    
        return {validity: isValid, message: message};
    };

    const [update, { loading: updateLoading, data: newBusiness }] = useMutation(UPDATE_BUSINESS, {
        refetchQueries: [{query: BUSINESS_INFO}]
    });
    if (updateLoading) {
        pageLoading = true;
    }
    if (newBusiness) {
        return <Redirect to="/dashboard/profile" />
    }

    const formHandler = (event) => {
        event.preventDefault();
        const form = componentState.form;

        let variables = {
            address: form.address.value, turnover: form.turnover.actualValue, designation: form.designation.value,
            type: form.type.value, employees: form.employees.value
        }
        let docs = [], doc_names = [];
        if (form.inc.value !== "") {
            docs = [...docs, form.inc.file];
            doc_names = [...doc_names, form.inc.label];
        }
        if (form.co2.value !== "") {
            docs = [...docs, form.co2.file];
            doc_names = [...doc_names, form.co2.label];
        }
        if (form.tin_cert.value !== "") {
            docs = [...docs, form.tin_cert.file];
            doc_names = [...doc_names, form.tin_cert.label];
        }
        if (form.vat_cert.value !== "") {
            docs = [...docs, form.vat_cert.file];
            doc_names = [...doc_names, form.vat_cert.label];
        }

        variables = docs.length < 1 ? {...variables} : { ...variables, doc_types: doc_names, cac_docs: docs };
        variables = form.idcard.value ? { ...variables, identity: form.idcard.file } : variables;

        update({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in componentState.form) {
        formELementsArray.push({
            id: key,
            config: componentState.form[key]
        });
    }

    return (
        <div>
            <Breadcrumb name="Edit business" />

            <form className="dashform-box w-40" onSubmit={formHandler}>
                <div className="dashform update-profile">
                    <div className="dashform-intro">
                        Edit your business Info
                    </div>
                    
                    {formELementsArray.map(formElement => (
                        <Input
                            key={formElement.id}
                            value={formElement.config.value}
                            label={formElement.config.label}
                            invalid={!formElement.config.valid}
                            message={formElement.config.message}
                            shouldValidate={formElement.config.validation}
                            typeInfo = {formElement.config.typeInfo}
                            touched={formElement.config.touched}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            inputChanged={event => inputChangedHandler(event, formElement.id)}
                        />
                    ))}

                    <div className="submit-box">
                        <button type="submit" className="submit right" disabled={!componentState.formIsValid} >
                            Submit
                        </button>
                    </div>

                </div>
            </form>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default EditBusiness;