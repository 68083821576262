import React from 'react';
import './Header.css';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/logo/logo.svg';

import { IS_LOGGED_IN, LOGOUT_MUTATION } from '../AuthPages/Queries';
import { useQuery, useMutation, useApolloClient } from 'react-apollo';

import PageLoading from '../components/PageLoading/PageLoading';

const Header = () => {
    let pageLoading = false;
    let userLoggedIn = false;
    const { data: cache } = useQuery(IS_LOGGED_IN);
    if (cache.isLoggedIn) {
        userLoggedIn = true;
    }


    const client = useApolloClient();
    const [logoutUser, {loading: logoutLoading, error: logoutError}] = useMutation(LOGOUT_MUTATION, {
        onCompleted: () => {
            client.writeData({data: { isLoggedIn: false, business: null, trial: null} });
            localStorage.removeItem("auth");
            localStorage.removeItem("trial");
            pageLoading = false;
            userLoggedIn = false;
        }
    });
    if (logoutLoading) {
        pageLoading = true;
    }
    if (logoutError) {
        let { graphQLErrors } = logoutError;
        console.log(graphQLErrors[0]);
        pageLoading = false;
    }

    return (
        <div className="header">
            {pageLoading ? <PageLoading ripple={true} /> : null}

            {userLoggedIn ?
                <>
                    <div className="header-logo">
                        <Link to="/"><img src={logo} alt="Taxmingo logo" /></Link>
                    </div>
                    <div className="menu"></div>
                    <div className="sess-menu">
                        {cache.business ? 
                            <div className="sess-user">
                                <Link to="/dashboard/profile" className="lynk a"><span>{cache.business.name}</span> <i className="la la-briefcase"></i></Link>
                                <span className="lynk b" onClick={logoutUser}>Logout</span>
                            </div> : 
                            <button className="menu-link a" onClick={logoutUser}>Logout</button>
                        }
                    </div>
                </> : <>
                    <div className="header-logo">
                        <Link to="/"><img src={logo} alt="Taxmingo logo" /></Link>
                    </div>
                    <div className="menu">
                        <div>
                            <NavLink exact to={{pathname: "/home", hash: "how-it-works"}} className="menu-link a">How it works</NavLink>
                            <NavLink exact to={{pathname: "/home", hash: "services"}} className="menu-link a">Services</NavLink>
                            <NavLink exact to={{pathname: "/home", hash: "pricing"}} className="menu-link a">Pricing</NavLink>
                        </div>
                    </div>
                    <div className="sess-menu">
                        <Link to="/login" className="menu-link a">Login</Link>
                        <Link to="/signup" className="menu-link c">Sign up</Link>
                    </div>
                </>
            }
        </div>
    );
}

export default Header;