import React from 'react';
import './Text.css';

const text = (props) => {
    return (
        <div className={`text-box ${props.w} `}>
            <div className={`text ${props.h}`}>
                {props.text}
            </div>
        </div>
    );
}

export default text;