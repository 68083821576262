import React, { useState } from 'react';
import '../assets/Report.css';
import ReactHtmlParser from 'react-html-parser';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { useQuery } from 'react-apollo';
import { HEALTH_REPORT } from '../../Queries';

const HealthReport = (props) => {
    const [activeSection, setActiveSection] = useState("report");
    let pageLoading = false;
    const id = props.match.params.id;
    
    const {loading: reportLoading, data: reportData} = useQuery(HEALTH_REPORT, {
        variables: { id: id }
    });
    if (reportLoading) {
        pageLoading = true;
    }
    let healthReport = {};
    let assessment = [];
    // console.log({err});
    if (reportData && reportData.view_tax_health_report) {
        healthReport = reportData.view_tax_health_report;
        
        const raw = JSON.parse(reportData.view_tax_health_report.assessment);
        const questions = JSON.parse(raw);
        for (const key in questions) {
            assessment.push({
                question: key, answer: questions[key]
            })
        }
    }
    
    const changeSection = (section) => {
        if (activeSection === section) {}
        setActiveSection(activeSection === section ? "" : section);
    }


    return (
        <div>
            <Breadcrumb name="Tax health report" />

            <div className="report-box">
                <div className="report">
                    <div className="report-intro">
                        {healthReport.type ? <>
                            <div className="desc big">
                                <div className="label">Tax type</div>
                                <div className="value">{healthReport.type}</div>
                            </div>
                            <div className="desc big">
                                <div className="label">Health score</div>
                                <div className="value">{healthReport.score}%</div>
                            </div>
                            <div className="desc">
                                <div className="label">Requested on</div>
                                <div className="value">
                                    {new Date(healthReport.created_at).toLocaleString()}
                                </div>
                            </div>
                            <div className="desc">
                                <div className="label">Analysed on</div>
                                <div className="value">
                                    {healthReport.updated_at !== healthReport.created_at ?
                                        new Date(healthReport.updated_at).toLocaleString() : <div className="not-ready">Not yet ready</div>}
                                </div>
                            </div>
                        </>:null}
                    </div>
                    
                    <div className="report-section">
                        <h1 className={`title ${activeSection === 'report' ? 'active' : null}`} onClick={() => changeSection("report")}>
                            Health report <i className="la la-angle-down"></i>
                        </h1>
                        {activeSection === "report" ?
                            <div className="report-text">
                                {healthReport.report ? ReactHtmlParser(healthReport.report) : <i>Not yet analysed</i>}
                            </div> : null
                        }
                    </div>

                    <div className="report-section">
                        <h1 className={`title ${activeSection === 'assessment' ? 'active' : null}`} onClick={() => changeSection("assessment")}>
                            Your assessment <i className="la la-angle-down"></i>
                        </h1>
                        {activeSection === "assessment" ?
                            <div className="report-text">
                                {assessment.map(item => {
                                    return (
                                        <div className="report-assessment" key={item.question}>
                                            <div className="question">
                                                {item.question}
                                            </div>
                                            <div className="answer">
                                                {item.answer}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div> : null
                        }
                    </div>
                </div>
            </div>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default HealthReport;