import React from 'react';
import './PageLoading.css';

const PageLoading = (props) => {
    return (
        <div className="page-loading">
            <div className="slider"></div>
            {props.ripple ?
                <div className="ripple"><div></div><div></div></div> : null
            }
        </div>
    );
}

export default PageLoading;