import React from 'react';
import './Input.css';

const input = (props) => {
    let inputElement = null;
    let inputError = false;
    if (props.invalid && props.shouldValidate && props.touched) {
        inputError = true;
    }

    switch (props.elementType) {
        case ('input'): inputElement =  <input className={inputError ? 'invalid-field' : null} 
                {...props.elementConfig} value={props.value}
                onChange={props.inputChanged} />
            break;
        case ('file'): inputElement =  <div className={"file-input-box" + (props.value ? " file-input-box--active" : "")}>
                    <span className="file-input-box__label">Choose File</span>
                    <span className="file-input-box__text">{props.value ? props.value : props.typeInfo}</span>
                    <input className={inputError ? 'invalid-field' : null}
                        title={props.value ? props.value : "No file chosen"}
                        {...props.elementConfig} onChange={props.inputChanged} />
                </div>
            break;
        case ('textarea'): inputElement =  <textarea className={inputError ? 'invalid-field' : null} 
                {...props.elementConfig} value={props.value}
                onChange={props.inputChanged} />
            break;
        case ('select'): inputElement = (
                <select className={inputError ? 'invalid-field' : null} onChange={props.inputChanged} value={props.value} >
                    <option></option>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>{option.displayText}</option>
                    ))}
                </select>
            );
            break;
        default: inputElement =  <input className={inputError ? 'invalid-field' : null} 
                {...props.elementConfig} value={props.value}
                onChange={props.inputChanged} />
            break;
    }

    return (
        <div className="input-box">
            <label>
                {props.label}
                {props.required ? <i className="la la-asterisk"></i> : ""}
                {props.info ?
                    <div className="info">
                        <i className="la la-info-circle"></i>
                        <div className="text">
                            {props.info}
                        </div>
                    </div> : null
                }
            </label>
            {inputElement}
            {props.message !== '' ?
                <div className={`message ${inputError ? 'error' : null}`}>
                    {props.message}
                </div> : null
            }
        </div>
    );
}

export default input;