import React from 'react';
import './Chart.css';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { IS_LOGGED_IN } from '../../../AuthPages/Queries';

const Chart = (props) => {
    const {data: cache} = useQuery(IS_LOGGED_IN);
    const trial = cache.trial;
    const subscriptionStatus = cache.business ? cache.business.subscribed : null;

    const data = {};
    if (props.data) {
        props.data.forEach(health => {
            let type;
            if (health.type === "Value Added Tax") {
                type = "vat";
            } else if (health.type === "Personal Income Tax") {
                type = "pit"
            } else if (health.type === "Withholding Tax") {
                type = "wt"
            } else if (health.type === "Company Income Tax") {
                type = "cit"
            }
            data[type] = health;
        });
    }
    let totalScore = <h3>___</h3>;
    let date = null;
    if (props.data && props.data.length > 0) {
        const num = props.data.length;
        let sumScore = 0;
        let allScores = true;
        props.data.forEach(item => {
            if (item.score >= 0 && allScores) {
                sumScore = sumScore + item.score;
            } else {
                allScores = false
            }
        });
        if (allScores) {
            totalScore = <h1>{Math.round(sumScore / num)}%</h1>;
        } else {
            totalScore = <h3>Not yet ready</h3>;
        }

        date = new Date(props.data[props.data.length - 1].created_at).toDateString();
    } else {
        totalScore = <h3>Not available</h3>;
    }

    return (
        <div className="chart-box">
            <div className="chart-container">
                <div className="chart-name">
                    {props.title}
                </div>
                <div className="chart">
                    <div className="chart-values">
                        <div className="w-25 item">
                            {data.vat ? 
                                (data.vat.score >= 0 ?
                                    data.vat.score + "%" :
                                    <>
                                        <span className="hidden-sm">Not ready</span>
                                        <span className="visible-sm">N/R</span>
                                    </>
                                ) :
                                <>
                                    <span className="hidden-sm">Not available</span>
                                    <span className="visible-sm">N/A</span>
                                </>
                            }
                        </div>
                        <div className="w-25 item">
                            {data.pit ? 
                                (data.pit.score >= 0 ?
                                    data.pit.score + "%" :
                                    <>
                                        <span className="hidden-sm">Not ready</span>
                                        <span className="visible-sm">N/R</span>
                                    </>
                                ) :
                                <>
                                    <span className="hidden-sm">Not available</span>
                                    <span className="visible-sm">N/A</span>
                                </>
                            }
                        </div>
                        <div className="w-25 item">
                            {data.wt ? 
                                (data.wt.score >= 0 ? 
                                    data.wt.score + "%" :
                                    <>
                                        <span className="hidden-sm">Not ready</span>
                                        <span className="visible-sm">N/R</span>
                                    </>
                                ) :
                                <>
                                    <span className="hidden-sm">Not available</span>
                                    <span className="visible-sm">N/A</span>
                                </>
                            }
                        </div>
                        <div className="w-25 item">
                            {data.cit ? 
                                (data.cit.score >= 0 ?
                                    data.cit.score + "%" :
                                    <>
                                        <span className="hidden-sm">Not ready</span>
                                        <span className="visible-sm">N/R</span>
                                    </>
                                ) :
                                <>
                                    <span className="hidden-sm">Not available</span>
                                    <span className="visible-sm">N/A</span>
                                </>
                            }
                        </div>
                    </div>
                    {props.data && props.data.length > 0 ? 
                        <div className="chart-bars">
                            <div className="w-25 item">
                                {data.vat ?
                                    <div className="bar">
                                        <div className="meter" style={{height: data.vat.score ? data.vat.score+ "%" : 0 }}></div>
                                    </div> : 
                                    <div className="no-bar">
                                        You are yet to check<span className="hidden-sm"> your <b>Value Added Tax</b> health status</span>.<br/><br/>
                                        <Link to="/dashboard/health/vat" className="check">Check now</Link>
                                    </div>
                                }
                            </div>
                            <div className="w-25 item">
                                {data.pit ?
                                    <div className="bar">
                                        <div className="meter" style={{height: data.pit.score ? data.pit.score + "%" : 0 }}></div>
                                    </div> : 
                                    <div className="no-bar">
                                        You are yet to check<span className="hidden-sm"> your <b>Personal Income Tax</b> health status</span>.<br/><br/>
                                        <Link to="/dashboard/health/pit" className="check">Check now</Link>
                                    </div>
                                }
                            </div>
                            <div className="w-25 item">
                                {data.wt ?
                                    <div className="bar">
                                        <div className="meter" style={{height: data.wt.score ? data.wt.score + "%" : 0}}></div>
                                    </div> : 
                                    <div className="no-bar">
                                        You are yet to check<span className="hidden-sm"> your <b>Withholding Tax</b> health status</span>.<br/><br/>
                                        <Link to="/dashboard/health/wt" className="check">Check now</Link>
                                    </div>
                                }
                            </div>
                            <div className="w-25 item">
                                {data.cit ?
                                    <div className="bar">
                                        <div className="meter" style={{height: data.cit.score ? data.cit.score+ "%" : 0 }}></div>
                                    </div> : 
                                    <div className="no-bar">
                                        You are yet to check<span className="hidden-sm"> your <b>Company Income Tax</b> health status</span>.<br/><br/>
                                        <Link to="/dashboard/health/cit" className="check">Check now</Link>
                                    </div>
                                }
                            </div>
                        </div> : 
                        <div className="no-chart-bars">
                            You are yet to check the health status for any of your taxes.<br/>
                            Click on the <Link to="/dashboard/health" className="check">Check tax health</Link> button to know your tax health status now.
                        </div>
                    }
                    <div className="chart-labels">
                        <div className="w-25 item">
                            <span className="hidden-xs">Value Added Tax</span>
                            <span className="visible-xs">VAT</span>
                        </div>
                        <div className="w-25 item">
                            <span className="hidden-xs">Personal Income Tax</span>
                            <span className="visible-xs">PIT</span>
                        </div>
                        <div className="w-25 item">
                            <span className="hidden-xs">Withholding Tax</span>
                            <span className="visible-xs">WT</span>
                        </div>
                        <div className="w-25 item">
                            <span className="hidden-xs">Company Income Tax</span>
                            <span className="visible-xs">CIT</span>
                        </div>
                    </div>
                </div>
                {trial || subscriptionStatus ? 
                    <div className="chart-total">
                        Overall score {props.data && date ?
                        <>as at <br className="visible-xs"/><span className="date">{date}.</span></> : null }
                        {totalScore}
                        <Link to="/dashboard/health" className="link a">Check tax health</Link>
                    </div>
                    :
                    <div className="chart-total">
                        <h2 className="sub-message">You do not have an active subscription.</h2>
                        <Link to="/dashboard/subscription" className="link a">Subscribe now</Link>
                    </div>
                }
            </div>
        </div>
    );
}

export default Chart;