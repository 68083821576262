import React from 'react';
import './Banner.css';
import { Link } from 'react-router-dom';

import deem from '../../assets/images/clients/deem.png';
import motus from '../../assets/images/clients/motus.png';
import rad from '../../assets/images/clients/rad.png';

const banner = () => {
    return (
        <div className="home-banner">
            <div className="content">
                <h1>
                    Focus on your business,
                    never worry about taxes again.
                </h1>
                <p>
                    We will compute, file and obtain your documents for you monthly while you just focus on building
                    your business. Everything is done online for as low as <b>N16,667</b> monthly.
                </p>
                <div className="actions">
                    <Link to="/signup" className="link a">Get started</Link>
                    <Link to="#pricing" className="link b">Know the cost</Link>
                </div>
            </div>
            <div className="banner-clients">
                <div className="text">
                    You are in good company.
                </div>
                <div className="images">
                    <img src={deem} alt="Deem Forensics logo" />
                    <img src={motus} alt="Motus Africa logo" />
                    <img src={rad} alt="Rad Woman logo" />
                </div>
            </div>
        </div>
    );
}

export default banner;
