import React, { Component } from 'react';
import './Nav.css';
import NavItem from './Nav-Item/Nav-Item';

class Nav extends Component {
    render() {
        return (
            <div className="nav">
                <NavItem icon="home" url="/dashboard/home" label="Home" />
                <NavItem icon="file-medical-alt" url="/dashboard/health" label="Tax health check" />
                <NavItem icon="calculator" url="/dashboard/computation" label="Tax computation" />
                <NavItem icon="chart-bar" url="/dashboard/planning" label="Tax planning" />
                <NavItem icon="comments" url="/dashboard/support" label="Support" />
                <NavItem icon="credit-card" url="/dashboard/subscription" label="Subscription" />
                <NavItem icon="briefcase" url="/dashboard/profile" label="Profile" />

                <div className="scrollbar"></div>
            </div>
        );
    }
}

export default Nav;