import React, { Component } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Text from '../../components/Text/Text';

class Planning extends Component {
    render () {
        const text="We will assist  and guide you to arrange your financial and business affairs to reduce your tax liabilities to the minimum by legitimately taking full benefit of all deductions, reliefs, exemptions and rebates."
        return (
            <div>
                <Breadcrumb name="Tax planning and advisory" />

                <div>
                    <Text text={text}/>
                </div>
            </div>
        );
    }
}

export default Planning;