import React, { useEffect, useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';
import Input from "../../../components/Input/Input";

import { useMutation } from 'react-apollo';
import { UPDATE_USER, USER_INFO } from '../../Queries';
import { graphql} from 'react-apollo';
import { Redirect } from 'react-router';
import { compose } from 'redux';

const EditUser = (props) => {
    let pageLoading = false;
    const [componentState, setComponentState] = useState({
        form: {
            first_name: {
                label: 'First name', elementType: 'input',
                elementConfig: { type: 'text' },
                validation: {required: true}, message: "",
                value: "", valid: true, touched: false
            },
            last_name: {
                label: 'Last name', elementType: 'input',
                elementConfig: { type: 'text' },
                validation: {required: true}, message: "",
                value: "", valid: true, touched: false
            },
            email: {
                label: 'Email', elementType: 'input',
                elementConfig: { type: 'email' },
                validation: {required: true}, message: "",
                value: "", valid: true, touched: false
            },
            confirmEmail: {
                label: 'Retype email', elementType: 'input',
                elementConfig: { type: 'email', onPaste: e => e.preventDefault() },
                validation: { required: true, confirmEmail: true }, message: "",
                value: "", valid: false, touched: false
            }
        },
        formIsValid: false
    });

    useEffect(() => {
        if (props.user.me) {
            const user = props.user.me;
            setComponentState({
                ...componentState,
                form: {
                    ...componentState.form,
                    first_name: {...componentState.form.first_name, value: user.first_name},
                    last_name: {...componentState.form.last_name, value: user.last_name},
                    email: {...componentState.form.email, value: user.email}
                }
            })
        }
    }, [props])
    // const {loading: businessLoading, data: businessData} = useQuery(BUSINESS_INFO);
    // if (businessLoading) {
    //     pageLoading = true;
    // }
    // let business = {};
    // if (businessData && businessData.user_business) {
    //     business = businessData.user_business;
    // }


    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
        ...componentState.form,
        };
        const updatedFormElement = {
        ...updatedForm[inputIdentifier]
        };

        updatedFormElement.value = event.target.value;

        const validityStatus = checkValidity(
            updatedFormElement.value,
            updatedFormElement.validation
        );
        
        updatedFormElement.valid = validityStatus.validity;
        updatedFormElement.message = validityStatus.message;

        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
  
        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
  
        setComponentState({ ...componentState, form: updatedForm, formIsValid: formIsValid });
    };
  
    const checkValidity = (value, rules) => {
        let isValid = true;
        let message = '';
  
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
            message = isValid ? '' : 'This field is required.';
        }
        if (rules.confirmEmail) {
            isValid = value === componentState.form.email.value && isValid;
            message = isValid ? '' : 'Emails must be exactly the same.';
        }
    
        return {validity: isValid, message: message};
    };

    let emailError = false;
    const [update, { loading: updateLoading, data: newBusiness, error: updateError }] = useMutation(UPDATE_USER, {
        refetchQueries: [{query: USER_INFO}]
    });
    if (updateLoading) {
        pageLoading = true;
    }
    if (newBusiness) {
        return <Redirect to="/dashboard/profile" />
    }
    if (updateError) {
      emailError = true;
    }

    const formHandler = (event) => {
        event.preventDefault();
        const form = componentState.form;

        let variables = {
            first_name: form.first_name.value, last_name: form.last_name.value
        }
        if (form.email.value !== props.user.me.email) {
            variables.email = form.email.value;
        }

        // console.log(variables);

        update({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in componentState.form) {
        formELementsArray.push({
            id: key,
            config: componentState.form[key]
        });
    }

    return (
        <div>
            <Breadcrumb name="Edit user" />

            <form className="dashform-box w-40" onSubmit={formHandler}>
                <div className="dashform update-profile">
                    <div className="dashform-intro">
                        Edit your info
                    </div>
                    
                    {formELementsArray.map(formElement => (
                        <Input
                            key={formElement.id}
                            value={formElement.config.value}
                            label={formElement.config.label}
                            invalid={!formElement.config.valid}
                            message={formElement.config.message}
                            shouldValidate={formElement.config.validation}
                            typeInfo = {formElement.config.typeInfo}
                            touched={formElement.config.touched}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            inputChanged={event => inputChangedHandler(event, formElement.id)}
                        />
                    ))}

                    <div className="submit-box">
                        {emailError ?
                            <div className="auth-message auth-error">
                                <b>OOPS!</b> The email has already been taken.<br/>
                            </div> : null
                        }
                        <button type="submit" className="submit right" disabled={!componentState.formIsValid} >
                            Submit
                        </button>
                    </div>

                </div>
            </form>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}
export default compose(
    graphql(USER_INFO, {
        name: "user"
    })
)(EditUser);