import React, { useState } from 'react';
import './modal.css';


const Faqs = (props) => {
    const [activeItem, setActiveItem] = useState(undefined);

    return (
        <div className={'alt-modal-backdrop ' + (props.show ? 'active' : '')} onClick={() => props.close()}>
            <div className={'alt-modal ' + (props.show ? 'active' : '')} onClick={(e) => e.stopPropagation()}>
                <h1 className="alt-modal-heading">
                    Frequently Asked Questions
                    <button className="close" type="button" onClick={() => props.close()}><i className="la la-close"></i></button>
                </h1>
                <div className="alt-modal-content faqs">
                    <div className={"faq-item" + (activeItem === 1 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 1) ? 1 : undefined)}>
                            1. What is Taxmingo about?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Taxmingo is an online tax consulting firm that combines automation and human Professional
                            so solve tax compliance problems for taxpayers in Nigeria. Even though it was initially
                            built to assist Small and Medium-sized Enterprises (SMEs) in Nigeria in eliminating the
                            extravagant cost of tax compliance, ambiguity of tax laws and complexity of procedures
                            that is associated with tax compliance, our tax services also cover large businesses across
                            various industries and sectors of the economy.
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 2 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 2) ? 2 : undefined)}>
                            2. Is Taxmingo Legit?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Yes, Taxmingo is legitimate. We are glad that you asked this question. This is one of those
                            key questions that a lot of people ask our clients support team.<br/>
                            We are very much aware of so many online scams in the financial sphere that our spectacularly
                            efficient and well-priced tax compliance services might seem too good to be true.<br/>
                            Taxmingo was incorporated in Nigeria with the Corporate Affairs Commission (CAC) as Taxmingo &amp; Associates 
                            on February 03, 2020 and launched her services on January 11, 2021. Even though
                            it is an online tax consulting firm, it is physically and strategically located in Lagos, Nigeria.<br/>
                            Our contacts are below:
                            <ul>
                                <li>
                                    <a href="https://www.google.com/maps/dir/53.4867932,-2.3310094/27,+Ladipo+Kuku+Street.+Off+Allen+Avenue/@28.7907208,-16.0794444,4z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x103b92308a778879:0x82fe9aac9dc4e798!2m2!1d3.3515033!2d6.6003006">
                                        27, Ladipo Kuku Street. Off Allen Avenue, Ikeja, Lagos
                                    </a>
                                </li>
                                <li><a href="tel:09055242532">09055242532</a>, <a href="tel:09055242531">09055242531</a></li>
                                <li><a href="mailto:support@taxmingo.com">support@taxmingo.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 3 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 3) ? 3 : undefined)}>
                            3. Is Taxmingo a tax calculator?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            No, Taxmingo is not an AI-driven Tax Calculator. We believe that every business is unique. This is
                            why our processes are driven by humans and automation tools. We combine the power of human
                            professionals and the scale of technology. We love technology, it helps us to eliminate processes,
                            gain speed, and keep our fee low, but it is not who we are though. We are human, real accountants,
                            and tax practitioners. Our team are members of both the Institute of Chartered Accountants of Nigeria
                            (ICAN) and Chartered Institute of Taxation of Nigeria (CITN).
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 4 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 4) ? 4 : undefined)}>
                            4. What are the services rendered by Taxmingo?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Taxmingo renders all tax compliance services which include but not limited to the following;
                            <ol>
                                <li>Corporate Tax Management (Company Income Tax)</li>
                                <li>Personal Income Tax Management - Pay-As-You-Earn (PAYE) &amp; Direct Assessment</li>
                                <li>Education Tax</li>
                                <li>Petroleum Profit Tax</li>
                                <li>Capital Gain Tax</li>
                                <li>Tax compliance support services, which include;</li>
                                    <ul>
                                        <li>Value Added Tax (VAT) compliance</li>
                                        <li>Withholding taxes compliance</li>
                                        <li>Preparation and filings of tax returns</li>
                                        <li>Tax registration with various tax authorities</li>
                                        <li>Obtaining tax clearance certificates</li>
                                        <li>General advice on compliance matters</li>
                                        <li>Audit support and Tax dispute resolution</li>
                                    </ul>
                                <li>Industries regulations compliance</li>
                            </ol>
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 5 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 5) ? 5 : undefined)}>
                            5. Will Taxmingo resolve my past tax irregularities for me when I subscribed?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Yes, Taxmingo will assist you to resolve all your past tax irregularities but that will depend on the
                            PLAN you subscribe to. Details of services to enjoy were stated on each PLAN.
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 6 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 6) ? 6 : undefined)}>
                            6. How does Taxmingo work?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Taxmingo is very easy, effortless and fast. follow the following steps to enjoy the best of our tax
                            compliance services;
                            <ol>
                                <li>log in into www.taxmingo.com.</li>
                                <li>Sign up for free by providing your name, email and create a password</li>
                                <li>A verification link will be sent to your provided email to click to proceed</li>
                                <li>Proceed to register your business/company by uploading the required documents</li>
                                <li>Carry out your Tax Health Check for free to know your tax compliance present status, and </li>
                                <li>Proceed to subscribe to a PLAN of your choice which suite your business </li>
                                <li>You will then be paired with a Consultants who  will immediately contact you </li>
                                <li>Then go to sleep and worry no more about tax</li>
                            </ol>
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 7 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 7) ? 7 : undefined)}>
                            7. Why is Taxmigo pricing so reasonable?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Our pricing is so reasonable because our automation helps us to eliminate processes, gain speed, leave
                            all the heavy and time-consuming admin tasks to the tech that backs our services.<br/>
                            This gives our human professionals adequate time to spend in attending to our client’s enquiries and
                            offering quality and valued tax services.
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 8 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 8) ? 8 : undefined)}>
                            8. How secure is the Taxmingo website platform?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Taxmingo's website is very secure. When you login to Taxmingo website, you will
                            observe that every feature that guarantees a secured website is present. These features include the 
                            following;    
                            <li>Trust Seal which is a closed padlock next to our URL is present on our website.</li>
                            <li>The URL of our website also displays “HTTPS” at the start of the address (instead of “HTTP”). This means the website is secure with an SSL certificate. The SSL certificate is used to secure all data that is passed from the browser to the website’s server.</li>
                            <li>Our physical address and phone contacts are on our website.</li>
                            <li>Identity of our key team members are on our website</li>
                            <li>We have a privacy and confidential policy agreement that we executed with our clients.</li>
                            <li>Our website is secure against malware.</li>
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 9 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 9) ? 9 : undefined)}>
                            9. Who are taxmingo team?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Our team comprises professional Accountants and Tax professionals who are constantly on top
                            of ever-changing tax laws, regulations and policies. They possess the skill, knowledge, and
                            experience to provide professional, efficient, and reasonable tax services to taxpayers of all
                            kinds, at different levels, industries and sectors of the economy. They are members of both
                            the Institute of Chartered Accountants of Nigeria (ICAN) and Chartered Institute of Taxation
                            of Nigeria (CITN). Our team is under obligation to observe the charter, regulations and ethical
                            guidelines of their professional bodies.
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 10 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 10) ? 10 : undefined)}>
                            10. How secure are taxpayers’ data and documents with Taxming?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Despite the fact that Taxmingo’s website is scanned on a regular basis for security holes and
                            known vulnerabilities in order to make our clients visit to our site as safe as possible, our
                            clients’ personal information is contained behind secured networks and is only accessible by a
                            limited number of persons who have special access rights to such systems, and are required to
                            keep the information confidential. In addition, all sensitive/credit information you supply
                            is encrypted via Secure Socket Layer (SSL) and HTTPS (HyperText Transfer Protocol Secure)
                            technology<br/>    
                            We implement a variety of security measures when users subscribe, submit, or access their
                            information to maintain the safety of their personal information.
                        </div>
                    </div>
                    <div className={"faq-item" + (activeItem === 11 ? ' active' : '')}>
                        <button className="faq-question" onClick={() => setActiveItem((!activeItem || activeItem !== 11) ? 11 : undefined)}>
                            11. How secure is payment on the Taxmingo platform?
                            <i className="la la-chevron-down"></i>
                        </button>
                        <div className="faq-answer">
                            Payment on Taxmingo is secured because it is handled by a secured payment agent called Paystack.
                            Paystack is a PCI-certified, auditor certified, PCI Service Provider Level 1 - the highest
                            certification level. All connections to their services are forced to happen over HTTPS using
                            TLS 1.2 (SSL). They use HSTS to ensure browsers interact with <b>Paystack</b> only over HTTPS. Your card
                            details are encrypted while the decryption keys are stored on a separate machine. As such, your
                            card is not stored as plain numbers but securely hidden even from Paystack personnel and systems.
                            The only actions that their systems can take is to request that your card details be sent to your
                            financial institution.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faqs;