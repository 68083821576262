import React, { useState, useEffect } from 'react';
import './Support.css';
import { animateScroll } from 'react-scroll';

import PageLoading from '../../../components/PageLoading/PageLoading';
import Input from "../../../components/Input/Input";

import { useQuery, useMutation } from 'react-apollo';
import { SUPPORT_TICKET, SEND_TICKET_MESSAGE } from '../../Queries';

const SupportTicket = (props) => {
    const ticketId = props.match.params.id;
    let pageLoading = true;
    const {error: ticketError, data: ticketData} = useQuery(SUPPORT_TICKET, {
        variables: { support_id: ticketId }, pollInterval: 1000
    });
    if (ticketError) {
        console.log(ticketError);
    }
    let ticket = {};
    let messages = [];
    if (ticketData && ticketData.view_support) {
        ticket = ticketData.view_support;
        messages = JSON.parse(ticket.message);
        pageLoading = false;
    }

    useEffect(() => {
        animateScroll.scrollToBottom({
            containerId: "message-thread", duration: 300
        })
    })

    const [messageField, setMessageField] = useState({
        label: '', elementType: 'textarea',
        elementConfig: { type: 'text', autoFocus: true },
        validation: {required: true},
        value: '', valid: false, touched: false
    })

    const inputChangedHandler = (event) => {
        const updatedMessageField = {
            ...messageField
        };
        updatedMessageField.value = event.target.value;
        const validity = checkValidity(updatedMessageField.value, updatedMessageField.validation);

        setMessageField({...messageField, value: event.target.value, valid: validity, touched: true});
    }

    const checkValidity = (value, rules) => {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        return isValid;
    }
    
    const [sendMessage, { error: messageError, data: messageData }] = useMutation(SEND_TICKET_MESSAGE, {
        refetchQueries: [{query: SUPPORT_TICKET, variables: {support_id: ticketId}}]
    });
    if (messageError) {
        let { graphQLErrors } = messageError;
        console.log(graphQLErrors[0]);
    }
    if (messageData) {
        console.log(messageData);
    }

    const formHandler = (event) => {
        event.preventDefault();
        const message = messageField.value;
        const variables = {
            support_id: ticketId, message: message
        }
        setMessageField({...messageField, value: '', valid: false, touched: false});
        sendMessage({ variables: variables });
    }

    return (
        <div>
            <div className="ticket-box">
                <div className={"ticket " + (ticket.closed ? "closed" : null)}>
                    <div className="ticket-head">
                        <div className="ticket-name">
                            {ticket.subject}
                        </div>
                        <div className="ticket-info hidden-xs">
                            <span className="item">
                                Status: {ticket.closed ? <b className="green">RESOLVED</b> : <b className="red">OPEN</b>}
                            </span>
                            <span className="item">
                                Created on: <b className="purple">{new Date(ticket.created_at).toLocaleString()}</b>
                            </span>
                        </div> 
                    </div>

                    <div className="ticket-thread" id="message-thread">
                        {messages.map(message => {
                            return (
                                <div key={message.time}>
                                    <div className={"message "+ message.from}>
                                        {message.message}
                                        <span className="time">
                                            {new Date(message.time).toLocaleString()}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {ticketData && !ticket.closed ?
                        <form className="ticket-form" onSubmit={formHandler}>
                            <div className="message-field">
                                <Input
                                    value={messageField.value}
                                    label={messageField.label}
                                    invalid={!messageField.valid}
                                    shouldValidate={messageField.validation}
                                    touched={messageField.touched}
                                    elementType={messageField.elementType}
                                    elementConfig={messageField.elementConfig}
                                    inputChanged={event => inputChangedHandler(event)}
                                    />
                            </div>
                            <button type="submit" disabled={!messageField.valid}>Send</button>
                        </form> : null
                    }
                </div>
            </div>

            {pageLoading ? <PageLoading ripple={true} /> : null }
        </div>
    );
}

export default SupportTicket;