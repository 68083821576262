import React from 'react';
import './Breadcrumb.css';
import { withRouter } from 'react-router';

const Breadcrumb = (props) => {
    return (
        <div className="breadcrumb">
            {props.name !== 'Home' && props.name !== 'Welcome' ?    
                <button className="go-back" onClick={props.history.goBack}>
                    <i className="la la-arrow-left"></i>
                </button> : null
            }
            {props.name}
        </div>
    );
}

export default withRouter(Breadcrumb);