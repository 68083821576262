import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';

import taxh from '../../assets/images/illustrations/services/tax-health.png';
import taxpr from '../../assets/images/illustrations/services/tax-preparation.png';
import taxr from '../../assets/images/illustrations/services/tax-returns.png';
import taxf from '../../assets/images/illustrations/services/tax-fillings.png';
import taxpl from '../../assets/images/illustrations/services/tax-planning.png';

const Services = () => {
    return (
        <div className="services" id="services">
            <h1 className="title">The value you get</h1>
            <div className="services-item">
                <div className="image">
                    <img src={taxh} alt="Tax healt check" />
                </div>
                <div className="text">
                    <h2>Tax health check</h2>
                    <p>
                        Get an automatic and independent snapshot of your business’ tax compliance status, prevent
                        future tax exposures and be sure that tax laws and regulations are continually complied with.
                    </p>
                </div>
            </div>
            <div className="services-item">
                <div className="image">
                    <img src={taxpr} alt="Tax computation and preparation" />
                </div>
                <div className="text">
                    <h2>Tax computation and preparation</h2>
                    <p>
                        Our team of professional chartered accountants and tax practitioners makes it a priority to assist
                        our clients to accurately calculate and prepare their taxes payable in order to avoid unnecessary future liabilities.
                    </p>
                </div>
            </div>
            <div className="services-item">
                <div className="image">
                    <img src={taxr} alt="Filing of tax returns" />
                </div>
                <div className="text">
                    <h2>Filing of tax returns</h2>
                    <p>
                        Focus on running your business while we take care of your monthly and annual tax returns.
                        We know the stress, complexity and challenge associated with filling of tax returns. Let us relieve you of it all.
                    </p>
                </div>
            </div>
            <div className="services-item">
                <div className="image">
                    <img src={taxf} alt="Tax fillings data and documents storage" />
                </div>
                <div className="text">
                    <h2>Tax fillings data and documents storage</h2>
                    <p>
                        It is important to keep proper tax records and we know how difficult this can be, especially for SMEs.
                        We keep digital copies of tax documents and records and make it accessible from anywhere, at anytime.
                    </p>
                </div>
            </div>
            <div className="services-item">
                <div className="image">
                    <img src={taxpl} alt="Tax planning and advisory" />
                </div>
                <div className="text">
                    <h2>Tax planning and advisory</h2>
                    <p>
                        We provide our clients with professional tax advice and plans to help minimize their tax liabilities 
                        while remaining compliant with the law in a complex tax environment.
                    </p>
                </div>
            </div>
            <div className="services-item">
                <div className="summary">
                    We basically take the stress off you while you focus on business.<br/>
                    <Link to="/signup" className="link a">Get started now</Link>
                </div>
            </div>
        </div>
    );
}

export default Services;