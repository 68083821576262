import React, { useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DashInput from '../../components/DashInput/DashInput';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { REQUEST_HEALTH_CHECK } from '../../Queries';
import { useMutation } from 'react-apollo';
import { Link } from 'react-router-dom';

const HealthForm = (props) => {
    const [componentState, setComponentState] = useState({
        form: {
            vat: {
                taxable: {
                    label: 'Are you a VAT taxable business?',
                    description: 'That is, is your company a supplier of taxable goods or services?',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                registered: {
                    label: 'Are you registered with Federal Inland Revenue Service (FIRS)?',
                    description: 'Is your company registered with Federal Inland Revenue Service (FIRS) for the purpose of Value Added Tax?',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                number: {
                    label: 'Do you have a VAT registration number?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                turnover: {
                    label: 'Is your annual turnover above threshold of N25,000,000.00?',
                    description: 'Does your company make more than N25,000,000.00 in a year?',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: false,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                invoice: {
                    label: 'Do you issue Tax Invoice as specified by Federal Inland Revenue Service (FIRS)?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                remit: {
                    label: 'Do you remit the actual VAT charged as at when due?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                file: {
                    label: 'Do you file tax returns as at when due?',
                    description: 'That is, on or before 21 st of every month',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                filing: {
                    label: 'Are you sure you are filing accurate VAT returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                include: {
                    label: 'Do you include 7.5% VAT in your Sales Invoices price?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                records: {
                    label: 'Do you keep proper records of your Sales and Purchases?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                evidences: {
                    label: 'Do you keep proper and up-to-date records of all your VAT payments and returns evidences?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                storage: {
                    label: 'Do you have up-date storage of your evidence of VAT remittances and returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                account: {
                    label: 'Do you self-account for the VAT payable and remit same to FIRS whenever a supplier/sales was made to you and the supplier did not include VAT in the price?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                withhold: {
                    label: 'Do you usually withhold VAT on the goods or services supplied to you from abroad and remit same to FIRS?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                },
                understand: {
                    label: 'Do you understand the procedures and process for filing your VAT return?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: false}, show: false, benefactor: 'turnover',
                    value: '', valid: false, touched: false
                }
            },
            pit: {
                registered: {
                    label: 'Have you registered with the state internal revenue of where you reside?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                identification: {
                    label: 'Do you have a tax payer Identification number?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                records: {
                    label: 'Do you keep proper and accurate records of your transactions?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                accurate: {
                    label: 'Do you keep the accurate records of your income, expenses, cash book and assets?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                file: {
                    label: 'Do you file your Personal Income Tax (Direct Assessment) Returns on or before 31st March of the year of every year?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                sure: {
                    label: 'Are you sure you are paying the accurate and due personal income tax every year?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                employees: {
                    label: 'Do you have employees?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: false,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                paye: {
                    label: 'Have you registered with the state internal revenue of where your business office resides with an issued PAYE ID for your employees?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: false, benefactor: 'employees',
                    value: '', valid: false, touched: false
                },
                deduct: {
                    label: 'Do you deduct the accurate Pay-As-You-Earn (PAYE) from their salaries and remit to state tax authority every time/month you pay them?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: false, benefactor: 'employees',
                    value: '', valid: false, touched: false
                },
                filepaye: {
                    label: 'Do you file your PAYE returns on or before 10 th of every month with the state tax authority?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: false, benefactor: 'employees',
                    value: '', valid: false, touched: false
                },
                returnspaye: {
                    label: 'Do you file your PAYE returns not later than 31st January of every year?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: false, benefactor: 'employees',
                    value: '', valid: false, touched: false
                },
                storage: {
                    label: 'Do you keep proper storage of your tax payments and returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                maintain: {
                    label: 'Do you maintain assets register and schedule?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                cgt: {
                    label: 'Do you file your Capital Gain Tax (CGT) returns along with your tax returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                procedures: {
                    label: 'Do you understand the procedures and process for filing various returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                }
            },
            wt: {
                rent: {
                    label: 'Do you deduct withholding tax on your rent or lease when paid?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                contractors: {
                    label: 'Do you deduct withholding tax on payments made to Contractors and Consultants?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                directors: {
                    label: 'Do you deduct withholding tax on Directors\' fee when paid?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                remitting: {
                    label: 'Are you sure you are deducting and remitting the actual and accurate rate for specific Withholding Tax?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                remit: {
                    label: 'Do you remit Withholding taxes to the appropriate tax authority when deducted along with the specified schedule?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                know: {
                    label: 'Do you know the appropriate tax authority to remit your deducted withholding tax to?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                receipts: {
                    label: 'Do you obtain receipts from tax authority for the Withholding taxes remitted and returns filed?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                tax: {
                    label: 'Do you obtained tax credit notes showing and correspond with the number of taxpayers on your filed Withholding Tax schedule?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                handover: {
                    label: 'Do you handover the credit notes to the taxpayers?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                claim: {
                    label: 'Do you also claim/collect your Withholding tax credit note from wherever, and whenever you suffered deductions of Withholding Tax?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                apply: {
                    label: 'Do you always apply your Withholding Tax credit note to reduce your tax liability or claim refund?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                understand: {
                    label: 'Do you understand the procedures and process for filing your Withholding Tax return?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                }
            },
            cit: {
                a: {
                    label: 'Is your business/company a limited liability company duly registered with Corporate Affair Commission (CAC)?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                b: {
                    label: 'Have you registered with Federal Inland Revenue Service (FIRS) for the purpose of obtaining Tax Identification Number (TIN) and Value Added Tax (VAT)?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                c: {
                    label: 'Do you have a Tax Identification Number (TIN)?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                d: {
                    label: 'Do you have a VAT registration number?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                e: {
                    label: 'Do you keep a proper, accurate and necessary accounting books and records?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                f: {
                    label: 'Do you keep a Cash Book?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                g: {
                    label: 'Do you keep Journals?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                h: {
                    label: 'Do you keep a General Ledger?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                i: {
                    label: 'Do you keep a Statement of Income (Profit & Loss Account) record?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                j: {
                    label: 'Do you keep a Statement of Financial Position (Balance Sheet) record?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                k: {
                    label: 'Do you keep a Statement of change in equity, if any?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                l: {
                    label: 'Do you keep a Statement of cashflow record?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                m: {
                    label: 'Do you keep a Schedule of Fixed Assets?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                n: {
                    label: 'Do you get your Account audited by a professional and licenced accountant every year?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                o: {
                    label: 'Are your accounts being handled by a qualified accountant?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                p: {
                    label: 'Are your tax computations being handled by a qualified and licenced chartered accountant or chartered tax practitioner?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                q: {
                    label: 'Are you paying your accurate Company Income Tax annually?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                r: {
                    label: 'Do you file your Company Income Returns every year as at when due?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                s: {
                    label: 'Do you know when to file your annual returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                t: {
                    label: 'Does your returns every year include your Statement of Income (Profit & Loss Account)?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                u: {
                    label: 'Does your returns every year include your Statement of Financial Position (Balance Sheet)?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                v: {
                    label: 'Does your returns every year include your Statement of change in equity, if any?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                w: {
                    label: 'Does your returns every year include your Statement of Cashflow?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                x: {
                    label: 'Does your returns every year include your Schedule of Fixed Assets?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                y: {
                    label: 'Does your returns every year include your Tax Computation?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                z: {
                    label: 'Does your returns every year include your Capital Allowance Computation?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                A: {
                    label: 'Do you file your Education Tax returns with your Company Income Tax returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                B: {
                    label: 'Do you file your Capital Gain Tax (CGT) returns along with your tax returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                C: {
                    label: 'Do you keep proper and up-to-date record and storage of your evidences of tax payments and filed returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                },
                D: {
                    label: 'Do you understand the procedures and process for filing various returns?',
                    description: '',
                    elementType: 'radio',
                    elementConfig: { type: 'radio' }, gradable: true,
                    validation: {required: true}, show: true,
                    value: '', valid: false, touched: false
                }
            }
        },
        formIsValid: false
    });
    let pageLoading = false;
    let requestSent = false;
    let requestScore = null;

    const taxType = props.match.params.type;
    let pageTitle;
    if (taxType === "vat") {
        pageTitle = 'Value Added Tax';
    } else if (taxType === "pit") {
        pageTitle = 'Personal Income Tax';
    } else if (taxType === "wt") {
        pageTitle = 'Withholding Tax';
    } else if (taxType === "cit") {
        pageTitle = 'Company Income Tax';
    }

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...componentState.form
        };
        const updatedFormGroup = {
            ...updatedForm[taxType]
        }
        const updatedFormElement = {
            ...updatedFormGroup[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[taxType][inputIdentifier] = updatedFormElement;
        
        handleDependants(inputIdentifier, event.target.value);
        
        let formIsValid = true;
        for (let inputIdentifier in updatedForm[taxType]) {
            if (updatedForm[taxType][inputIdentifier].show) {
                formIsValid = updatedForm[taxType][inputIdentifier].valid && formIsValid;
            }
        }

        setComponentState({...componentState, form: updatedForm, formIsValid: formIsValid});
    }

    const handleDependants = (identifier, inputValue) => {
        for (let formIdentifier in componentState.form[taxType]) {
            const element = componentState.form[taxType][formIdentifier];
            if (componentState.form[taxType][formIdentifier].benefactor === identifier && inputValue === 'Yes') {
                element.show = true;
                element.validation.required = true;
            }
            else if (componentState.form[taxType][formIdentifier].benefactor === identifier && inputValue === 'No') {
                element.show = false;
            }
        }
    }

    const checkValidity = (value, rules) => {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        return isValid;
    }

    const [request, { loading: requestLoading, error: requestError, data: requestSuccess }] = useMutation(REQUEST_HEALTH_CHECK);
    if (requestLoading) {
        pageLoading = true;
    }
    if (requestError) {
        console.log(requestError)
    }

    if (requestSuccess && requestSuccess.request_tax_health) {
        requestSent = true;
        requestScore = requestSuccess.request_tax_health.score;
    }
    const formHandler = (event) => {
        event.preventDefault();
        
        let assessment = {};
        let score = 0;
        let gradable = 0;
        for (let key in componentState.form[taxType]) {
            if (componentState.form[taxType][key].show) {
                assessment[componentState.form[taxType][key].label] = componentState.form[taxType][key].value;
                gradable = componentState.form[taxType][key].show && componentState.form[taxType][key].gradable ?
                    gradable + 1 : gradable;
                score = componentState.form[taxType][key].gradable && componentState.form[taxType][key].value === 'Yes' ?
                    score + 1 : score;
            }
        }
        const totalScore = Math.round((score/gradable)*100);
        const variables = {
            type: pageTitle,
            assessment: JSON.stringify(assessment),
            score: totalScore
        };
        request({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in componentState.form[taxType]) {
        formELementsArray.push({
            id: key,
            config: componentState.form[taxType][key]
        });
    }

    return(
        <div>
            <Breadcrumb name={pageTitle + ' health check'} />

            {!requestSent ?
                <form className="dashform-box" onSubmit={formHandler}>
                    <div className="dashform">
                        <div className="dashform-intro">
                            Carefully fill this form to know your <b>{pageTitle}</b> health status.
                        </div>

                        {formELementsArray.map(formElement => {
                            return (
                                <div key={formElement.id}>
                                    {formElement.config.show ? 
                                        <DashInput name={formElement.id} value={formElement.config.value}
                                            label={formElement.config.label} invalid={!formElement.config.valid}
                                            shouldValidate={formElement.config.validation}
                                            touched={formElement.config.touched}
                                            description={formElement.config.description}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            inputChanged={(event) => inputChangedHandler(event, formElement.id)} />
                                    : null}
                                </div>
                            )
                        })}
                        <div className="submit-box dash-input-submit">
                            <button type="submit" className="submit right" disabled={!componentState.formIsValid} >
                                Submit
                            </button>
                        </div>
                    </div>
                </form> 
            : 
                <div className="dashform-box">
                    <div className="dashform">
                        <div className="dashform-intro text-center">
                            Your <b>{pageTitle}</b> health score is:
                        </div>
                        <h1 className="health-score">
                            {requestScore}%
                            <span className="message">
                                {requestScore > 90 ? <span className="green">This is an EXCELLENT result</span> : (
                                    requestScore > 75 && requestScore <= 90 ? <span className="green">GOOD performance</span> : (
                                        requestScore >= 50 && requestScore <= 75 ? <span className="blue">just above AVERAGE</span> : (
                                            requestScore >= 25 && requestScore < 50 ? 
                                                <span>POOR performance</span> : <span>VERY POOR performance</span>
                                        )
                                    )
                                ) }
                            </span>
                        </h1>
                        <div className="dashform-message">
                            You will be notified when a full analysis is ready.<br/><br/><br/>
                            <Link to="/dashboard/health" className="link a">Tax health check</Link>
                        </div>
                    </div>
                </div>
            }

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default HealthForm;