import React from 'react';
import './Nav-Item.css';
import { NavLink } from 'react-router-dom';

const navItem = (props) => {
    return (
        <NavLink to={{pathname: props.url}} className="nav-item">
            <i className={`la la-${props.icon}`}></i>
            {props.label}
        </NavLink>
    );
}

export default navItem;