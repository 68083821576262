import React from 'react';
import './Card.css';

import { Link } from 'react-router-dom';

const card = (props) => {
 return (
    <div className={`card-box ${props.w} ${props.xs} `}>
        <Link to={props.link} className={`card ${props.h} ${props.align} `}>
            { props.header0 ?
                <h4 className="card-header0">{props.header0[0]}</h4> : null
            }
            { props.icon ?
                <div className="card-icon">
                    <i className={`la la-${props.icon} ${props.theme}`}></i>
                </div> : null
            }
            { props.header1 ?
                <h1 className="card-header1">{props.header1}</h1> : null
            }
            { props.header2 ?
                <h1 className="card-header2">{props.header2}</h1> : null
            }      
            { props.header3 ?
                <h1 className={`card-header3 ${props.theme}`}>{props.header3}</h1> : null
            }            
            { props.header0 ?
                <h4 className="card-header0">{props.header0[1]}</h4> : null
            }
            {/* { props.link ?
                <a href={props.link} className={`card-link ${props.theme}`}>View</a>: null
            } */}
        </Link>
    </div>
 );
}

export default card;