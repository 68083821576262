import React from 'react';
import './modal.css';


const Plans = (props) => {
    let content;
    switch (props.show) {
        case 'Solo':
            content = <>
                <p>
                    This plan is suitable for BUSINESS (Business name) with an annual turnover of &#8358;100,000.00 to &#8358;24,999,999.99, and 0 employees.
                </p>
                <p>
                    <b>Subscribers of this plan will enjoy the following tax compliance services;</b>
                </p>
                <ul>
                    <li>
                        Free Tax Health Check.
                    </li>
                    <li>
                        Free tax registration with tax authorities (only for Lagos State residents)
                    </li>
                    <li>
                        Tax planning and compliance advisory/services 
                    </li>
                    <li>
                        Annual Tax computation and filing (including Directors' direct assessments in the case of limited liability companies)
                    </li>
                    <li>
                        Storage and instant access to tax/filings documents
                    </li>
                    <li>
                        Processing and obtaining of Tax Clearance Certificate (TCC)
                    </li>
                    <li>
                        Response to tax inquiries
                    </li>
                    <li>
                        Other Value-Added Accounting and Tax Services
                    </li>
                    <li>
                        Preparation of Account
                    </li>
                </ul>
                <p>
                    This plan only covers tax compliances as it relates to the client’s tax affairs from the date of this subscription. <br />
                    Taxmingo will not be responsible for the regularisation of tax matters arising in respect of periods prior to the date of subscription. <br/>
                    Taxmingo will only assist to regularise tax matters arising in respect of periods prior 
                    to the date of subscription at a negotiated fee outside this plan and the subscriber bears the 
                    attributed expenses and also pay the outstanding tax liabilities if any.
                </p>
                <p>
                    <b>
                        Take-Out: Audited Financial Report, where it is required is not included in this plan.
                    </b>
                </p>
            </>
            break;
        case 'Basic':
            content = <>
                <p>
                    This plan is suitable for START-UPS/BUSINESS (Business name) within the first three years 
                    of operation with an annual turnover of  &#8358;100,000.00 to &#8358;24,999,999.99, and 1 to 15 employees
                </p>
                <p>
                    <b>Subscribers of this plan will enjoy the following tax compliance services;</b>
                </p>
                <ul>
                    <li>
                        Free Tax Health Check.
                    </li>
                    <li>
                        Periodic Taxes computation and filing (All taxes).
                    </li>
                    <li>
                        Storage and instant access to tax/filings documents.
                    </li>
                    <li>
                        Other Value-Added Accounting and Tax Services.
                    </li>
                    <li>
                        Preparation of Monthly Account
                    </li>
                    <li>
                        Preparation of Annual Cash Budget
                    </li>
                </ul>
                <p>
                    This plan only covers tax compliances as it relates to the client's tax affairs from the date of
                    this subscription.<br/>
                    Taxmingo will not be responsible for the regularisation of tax matters arising in respect of
                    periods prior to the date of subscription.<br/>
                    Taxmingo will only assist to regularise tax matters arising in respect of periods prior to the
                    date of subscription at a negotiated fee outside this plan and the subscriber bears the
                    attributed expenses and also pay the outstanding tax liabilities if any.
                </p>
                <p>
                    <b>
                        Take-Out: Audited Financial Report, where it is required is not included in this plan.
                    </b>
                </p>
            </>
            break;
        case 'Medium':
            content = <>
                <p>
                    Suitable for a LIMITED LIABILITY START-UPS COMPANY within the first three years of 
                    operation with an annual turnover of &#8358;100,000.00 to &#8358;24,999,999.99 and 0 to 15 employees.
                </p>
                <p>
                    <b>Subscribers of this plan will enjoy the following tax compliance services;</b>
                </p>
                <ul>
                    <li>
                        Free Tax Health Check.
                    </li>
                    <li>
                        Periodic Taxes computation and filing (All taxes).
                    </li>
                    <li>
                        Storage and instant access to tax/filings documents.
                    </li>
                    <li>
                        Processing of Tax Clearance Certificate (TCC).
                    </li>
                    <li>
                        Other Value-Added Accounting and Tax Services.
                    </li>
                    <li>
                        Preparation of Monthly Account
                    </li>
                    <li>
                        Preparation of Annual Cash Budget
                    </li>
                </ul>
                <p>
                    This plan only covers tax compliances as it relates to the client’s tax affairs from the date of
                    this subscription.<br/>
                    Taxmingo will not be responsible for the regularisation of tax matters arising in respect of
                    periods prior to the date of subscription.<br/>
                    Taxmingo will only assist to regularise tax matters arising in respect of periods prior to the
                    date of subscription at a negotiated fee outside this plan and the subscriber bears the
                    attributed expenses and also pay the outstanding tax liabilities if any.
                </p>
                <p>
                    <b>
                        Take-Out: Audited Financial Report, where it is required is not included in this plan.
                    </b>
                </p>
            </>
            break;
        case 'Enterprise':
            content = <>
                <p>
                    Suitable for ALL KINDS OF BUSINESS above annual turnover of &#8358;25,000,000.00 and above, and/or at least 1 employee.
                </p>
                <p>
                    <b>Subscribers of this plan will enjoy the following tax compliance services;</b>
                </p>
                <ul>
                    <li>
                        Free Tax Health Check.
                    </li>
                    <li>
                        Comprehensive Tax Management, Planning, and compliance advisory/services.
                    </li>
                    <li>
                        Periodic Taxes computation and filing (all taxes).
                    </li>
                    <li>
                        Storage and instant access to tax/filings documents.
                    </li>
                    <li>
                        Processing of Tax Clearance Certificate (TCC).
                    </li>
                    <li>
                        Response to tax inquiries, i.e., tax audit/investigation notice.
                    </li>
                    <li>
                        Preparation of monthly Account
                    </li>
                    <li>
                        Preparation of Annual Cash Budget
                    </li>
                    <li>
                        Other Value-Added Accounting and Tax Services.
                    </li>
                </ul>
                <p>
                    <b>
                        Pricing: We will contact you immediately after registration to give you a quote.
                    </b>
                </p>
                <p>
                    This plan only covers tax compliances as it relates to the client's tax affairs from the date of
                    this subscription.<br/>
                    Taxmingo will not be responsible for the regularisation of tax matters arising in respect of
                    periods prior to the date of subscription.<br/>
                    Taxmingo will only assist to regularise tax matters arising in respect of periods prior to the
                    date of subscription at a negotiated fee outside this plan and the subscriber bears the
                    attributed expenses and also pay the outstanding tax liabilities if any.
                </p>
                <p>
                    <b>
                        NOTE: Audited Financial Report, where it is required is not included in this plan.
                    </b>
                </p>
            </>
            break;
    
        default:
            break;
    }

    return (
        <div className={'alt-modal-backdrop ' + (props.show ? 'active' : '')} onClick={() => props.close()}>
            <div className={'alt-modal ' + (props.show ? 'active' : '')} onClick={(e) => e.stopPropagation()}>
                <h1 className="alt-modal-heading">
                    {props.show} plan
                    <button className="close" type="button" onClick={() => props.close()}><i className="la la-close"></i></button>
                </h1>
                <div className="alt-modal-content privacy">
                    {content}
                </div>
            </div>
        </div>
    );
}

export default Plans;