import React from 'react';
import './modal.css';


const Terms = (props) => {
    return (
        <div className={'alt-modal-backdrop ' + (props.show ? 'active' : '')} onClick={() => props.close()}>
            <div className={'alt-modal ' + (props.show ? 'active' : '')} onClick={(e) => e.stopPropagation()}>
                <h2 className="alt-modal-heading">
                    Terms &amp; Conditions
                    <button className="close" type="button" onClick={() => props.close()}><i className="la la-close"></i></button>
                </h2>
                <div className={"alt-modal-content terms" + (props.agree ? ' b' : '')}>
                    <p>
                        <b>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THIS SERVICE.</b>
                    </p>
                    <p>
                        <b>BY USING THE SERVICE OR CLICKING “AGREE” CLIENT IS AGREEING TO BE BOUND BY THIS AGREEMENT.</b>
                    </p>

                    <div className="terms-item">
                        <p>
                            This agreement is between <b>Taxmingo</b> (hereinafter may be referred to as “we” or “us”), and
                            the <b>Customer/Subscriber</b> agreeing to these terms (hereinafter may be referred to as “Client” or “you”).
                        </p>
                        <ul>
                            <li>
                                <b>SOFTWARE-AS-A-SERVICE:</b> This agreement provides Client access to and usage of an Internet based
                                software service as specified on an order and as further outlined at: www.taxmingo.com (Service).
                            </li>
                            <li>
                                <b>USE OF SERVICE:</b> This agreement provides Client access to and usage of an Internet based software
                                service as specified on an order and as further outlined at: www.taxmingo.com (Service).
                            </li>
                            <li>
                                <b>Client Owned Data:</b> All data and documents uploaded by Client remains the property of Client, as
                                between Taxmingo and Client (Client Data). Client grants Taxmingo the right to use, display and
                                distribute the Client Data for purposes of performing under this agreement.
                            </li>
                            <li>
                                <b>Client Responsibilities:</b> Client (i) must keep its passwords secure and confidential; (ii) is
                                solely responsible for Client Data and all activity in its account in the Service; (iii) must use
                                commercially reasonable efforts to prevent unauthorized access to its account, and notify Taxmingo
                                promptly of any such unauthorized access; and (iv) may use the Service only in accordance with the
                                Service’s Knowledge Base and applicable law.
                            </li>
                        </ul>
                    </div>

                    <div className="terms-item">
                        <h3 className="terms-item-heading">MUTUAL CONFIDENTIALITY</h3>
                        <ul>
                            <li>
                                <b>Definition of Confidential Information:</b> Confidential Information means all non-public
                                information disclosed by a party (Discloser) to the other party (Recipient), whether orally
                                or in writing, that is designated as confidential or that reasonably should be understood to
                                be confidential given the nature of the information and the circumstances of disclosure
                                (Confidential Information).
                            </li>
                        </ul>
                        <p>
                            Taxmingo’ Confidential Information includes without limitation the Service (including without
                            limitation the Service user interface design and layout, and pricing information).
                        </p>
                        <ul>
                            <li>
                                <b>Protection of Confidential Information:</b> The Recipient must use the same degree of care
                                that it uses to protect the confidentiality of its own confidential information (but in no
                                event less than reasonable care) not to disclose or use any Confidential Information of the
                                Discloser for any purpose outside the scope of this agreement. The Recipient must make
                                commercially reasonable efforts to limit access to Confidential Information of Disclosure
                                to those of its employees and contractors who need such access for purposes consistent with
                                this agreement and who have signed confidentiality agreements with Recipient no less restrictive
                                than the confidentiality terms of this agreement.
                            </li>
                            <li>
                                <b>Exclusions:</b> Confidential Information excludes information that: (i) is or becomes
                                generally known to the public without breach of any obligation owed to Discloser, (ii) was known
                                to the Recipient prior to its disclosure by the Discloser without breach of any obligation owed
                                to the Discloser, (iii) is received from a third party without breach of any obligation owed to
                                Discloser, or (iv) was independently developed by the Recipient without use or access to the
                                Confidential Information. The Recipient may disclose Confidential Information to the extent
                                required by law or court order, but will provide Discloser with advance notice to seek a
                                protective order.
                            </li>
                        </ul>
                    </div>

                    <div className="terms-item">
                        <h3 className="terms-item-heading">PROPRIETARY PROPERTY</h3>
                        <ul>
                            <li>
                                <b>Reservation of Rights:</b> The software, workflow processes, user interface, designs, know-how,
                                and other technologies provided by Taxmingo as part of the Service are the proprietary property of
                                Taxmingo and its licensors, and all right, title and interest in and to such items, including all
                                associated intellectual property rights, remain only with Taxmingo. Clients may not remove or
                                modify any proprietary marking or restrictive legends in the Service. Taxmingo reserves all rights
                                unless expressly granted in this agreement.
                            </li>
                            <li>
                                <b>Restrictions:</b> Client may not (i) sell, resell, rent or lease the Service or use it in a service
                                provider capacity; (ii) use the Service to store or transmit infringing, unsolicited marketing emails,
                                libellous, or otherwise objectionable, unlawful or tortious material, or to store or transmit material
                                in violation of third-party rights; (iii) interfere with or disrupt the integrity or performance of
                                the Service; (iv) attempt to gain unauthorized access to the Service or their related systems or
                                networks; (v) reverse engineer the Service; or (vi) access the Service to build a competitive service
                                or product, or copy any feature, function or graphic for competitive purposes.
                            </li>
                            <li>
                                <b>Aggregate Data:</b> During and after the term of this agreement, Taxmingo may use non-personally
                                identifiable Client Data within the Service for purposes of enhancing the Service, aggregated
                                statistical analysis, technical support and other business purposes.
                            </li>
                        </ul>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">TERM OF SERVICE:</h3>
                        <h3 className="terms-item-heading">SCOPE</h3>
                        <p>
                            This term of service sets out the basis on which Taxmingo is to act as tax Consultants/advisers to the Client.
                        </p>
                        <ul>
                            <li>
                                Period of engagement: This agreement will commence on the date of subscription and covers the Client’s
                                tax compliances as relates to the PLAN subscribed to by the Client.
                            </li>
                        </ul>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">TAXMINGO SERVICE TO CLIENT</h3>
                        <ul>
                            <li>
                                Taxmingo service to Client will be in accordance and restricted to the PLAN type subscribed to by the Client.
                            </li>
                            <li>
                                Taxmingo will prepare Client’s returns from the documents, accounts and other information and explanations
                                provided by the Client.
                            </li>
                            <li>
                                Taxmingo will send you the tax return and supporting schedules (in duplicate) for you to approve. Taxmingo
                                will then submit it, with the accounts and computations to the tax authority.
                            </li>
                            <li>
                                Taxmingo will advise Client of the amounts of Tax to be paid and the dates by which Client should make the
                                payments. Where appropriate Taxmingo will initiate repayment claims when tax has been overpaid.
                            </li>
                            <li>
                                Taxmingo will advise Client as to possible claims and elections arising from the tax return and from
                                information supplied by Client. Where instructed by Client, Taxmingo will make such claims and elections
                                in the form and manner required by the Tax authority and Tax laws.
                            </li>
                            <li>
                                Taxmingo will deal with all communications relating to the Client's tax returns addressed to us by the tax
                                authority or forwarded by the Client. However, if the tax authority chooses your returns for enquiry (Audit
                                or Investigation) that may have to be the subject of a separate assignment.
                            </li>
                            <li>
                                Taxmingo will prepare/help you in preparing the tax provisions and disclosures to be included in the
                                Client’s statutory accounts.
                            </li>
                        </ul>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">CLIENT’S RESPONSIBILITIES OF INFORMATION PROVISION</h3>
                        <p>
                            Client is legally responsible for making correct returns by the due date and for payment of tax on time. Failure to
                            meet the deadlines may attract penalties and/or interest.
                        </p>
                        <p>
                            To enable Taxmingo to carry out our work effectively and deliver value, Client agree;
                        </p>
                        <ol className="roman">
                            <li>
                                That all returns are to be made on the basis of full disclosure of all sources of Sales, Purchases, income,
                                expenses, charges, allowances and capital transactions;
                            </li>
                            <li>
                                To provide full information necessary for dealing with the Client’s affairs: Taxmingo will rely on the
                                information and documents being true, correct and complete and will not audit the information or those
                                documents;
                            </li>
                            <li>
                                That Taxmingo can approach such third parties as may be appropriate for information that we consider 
                                necessary to deal with the Client affairs;
                            </li>
                            <li>
                                To provide Taxmingo with information timely for the Client tax returns to be completed and submitted by the due
                                date. To enable us to do this, we need to receive all relevant information on or before the deadline that will
                                be provided by us.
                            </li>
                            <li>
                                To forward to Taxmingo on receipt copies of notices of assessment, letters and other communications received
                                from the tax authority from time to time to enable us to deal with them as may be necessary within the statutory
                                time limits; and
                            </li>
                            <li>
                                To keep us informed about significant transactions or changes in circumstances.
                            </li>
                        </ol>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">OTHER SERVICES AND GENERAL TAX ADVICE</h3>
                        <ul>
                            <li>
                                Taxmingo will be pleased to assist Client generally in tax matters if Client advise us in good time of any
                                proposed transactions and request advice. Taxmingo would, however warn Client that because tax rules change
                                frequently you must ask us to review any advice already given if a transaction is delayed, or if an apparently
                                similar transaction is to be undertaken
                            </li>
                            <li>
                                It is Taxmingo policy to confirm in writing advice upon which Clients may wish to rely.
                            </li>
                            <li>
                                Taxmingo will be pleased also to advise the directors and executives on their personal income tax and capital
                                tax affairs. In such cases we will need to agree separate terms with the individuals concerned.
                            </li>
                        </ul>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">PROFESSIONAL RULES AND PRACTICE GUIDELINES</h3>
                        <p>
                            Taxmingo is under obligation to observe the charter, regulations and ethical guidelines of the professional
                            bodies which our Management, Admin, Consultants and Staff belong to and accept instructions to act for our
                            Clients on the basis that we will act in accordance with those guidelines. These professional bodies
                            include <b>The Institute of Chartered Accountants of Nigeria</b> (ICAN), <b>The Chartered Institute of Taxation of Nigeria</b>
                            (CITN) and <b>Association of National Accountants of Nigeria</b> (ANAN)
                        </p>
                        <h4 className="terms-item-subheading">Retention of records:</h4>
                        <ol className="latin">
                            <li>
                                During the course of our work, we will collect information from you and others acting on your behalf and
                                your business and will return any original documents to you following preparation of the company’s return.
                                We will only retain your information with us during the terms of this agreement.
                            </li>
                        </ol>
                        <p>
                            Whilst certain documents may legally belong to you, we intend to destroy correspondence and other papers that we
                            store which are more than ten (10) years old, other than documents which we consider to be of continuing
                            significance. You may have to inform us if you require retention of a particular document.
                        </p>
                        <h4 className="terms-item-subheading">Regulatory Requirements:</h4>
                        <p>
                            We reserve the right to disclose our files to regulatory bodies in the exercise of their powers.
                        </p>
                        <h4 className="terms-item-subheading">Quality of Service</h4>
                        <p>
                            Taxmingo aims to provide a high quality of service at all times. If you would like to discuss with us how our
                            service could be improved or if you are dissatisfied with the service you are currently receiving, please let
                            us know by contacting <a href="mailto:support@taxmingo.com">support@taxmingo.com</a>
                        </p>
                        <p>
                            We assure you of our commitment to look into any complaint carefully and promptly and to do all we can to
                            explain the position to you.  If an event where you are not satisfied with our responses to your complaints,
                            you may decide to have a physical or virtual meeting with our team.
                        </p>
                        <h4 className="terms-item-subheading">Fees</h4>
                        <p>
                            Our fees shall be in accordance with the PLAN subscribed to by the Client.  However, any other assignment
                            outside the scope of this agreement, for example dealing with Tax authorities, enquiries into tax returns,
                            i.e. tax audit and investigation, will attract additional fees and will be chargeable.
                        </p>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">LIMITATION OF LIABILITY</h3>
                        <ul>
                            <li>
                                The advice which Taxmingo will give to you is for your sole use and does not constitute advice to any
                                third party to whom you may communicate it.
                            </li>
                            <li>
                                We will provide the professional services as relates to the PLAN subscribed to under this agreement with
                                reasonable care and skill. However, we will not be responsible for any losses, penalties, surcharges,
                                interest or additional tax liabilities arising from the supply by you or others of incorrect or incomplete
                                information, or from the failure by you or others to supply any appropriate information or your failure to
                                act on our advice or respond promptly to communications from us or the tax authorities.
                            </li>
                            <li>
                                Taxmingo is not liable for any indirect, special, incidental or consequential damages arising out of or
                                related to this agreement (including, without limitation, costs of delay; loss of data, records or
                                information; and lost profits), even if it knows of the possibility of such damage or loss.
                            </li>
                            <li>
                                Clients are prohibited to make any payment or give monies to any of Taxmingo people outside our platform or
                                to an individual account detail. Therefore, Taxmingo shall not liable for any damage, loss and liability
                                suffered by the Client for any monies paid to, or dealings with Taxmingo’s Management, staff and Consultants
                                outside Taxmingo platform.
                            </li>
                            <li>
                                Taxmingo’s total liability arising out of or related to this agreement (whether in contract, tort or otherwise)
                                does not exceed the amount paid by Client within the 3-month period prior to the event that gave rise to the
                                liability.
                            </li>
                            <li>
                                If any third-party brings a claim against Taxmingo, or requires Taxmingo to respond to a legal process, related
                                to Client’s acts, omissions, data or information within the Software, Client must defend, indemnify and hold
                                Taxmingo harmless from and against all damages, losses, and expenses of any kind, including reasonable legal fees
                                and costs related to such claim or request.
                            </li>
                            <li>
                                E-mail may be used to enable us to communicate with you. As with any other means of delivery this carries with it
                                the risk of inadvertent misdirection or non-delivery. It is the responsibility of the recipient to carry out a
                                virus check on any attachments received.
                            </li>
                        </ul>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">APPLICABLE LAW</h3>
                        <p>
                            This agreement is governed by and construed in accordance with laws of the Federation of Nigeria (amend as appropriate).
                            The Nigeria Courts will have exclusive jurisdiction in relation to any claim, dispute or difference concerning this
                            engagement and any matter arising from it. Nothing in this agreement prevents either party from seeking injunctive relief
                            in a court of competent jurisdiction. The prevailing party in any litigation is entitled to recover its attorneys’ fees
                            and costs from the other party.
                        </p>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">CONTRACTS (RIGHT OF THIRD PARTY)</h3>
                        <p>
                            A person who is not a party to this agreement shall have no right under the contracts to enforce any term of this agreement.
                            This clause does not affect any right or remedy of any person which exists or is available otherwise than pursuant to any
                            related act.
                        </p>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">MODE OF PAYMENT</h3>
                        <p>
                            All payments must be made on our platform. Clients are prohibited to make any payment or give monies to any of Taxmingo
                            people outside our platform.
                        </p>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">TERM OF TERMINATION</h3>
                        <ol className="roman">
                            <li>
                                <b>Term:</b> This agreement continues to be in effect until it is terminated
                            </li>
                            <li>
                                <b>Termination by Client:</b> Client may terminate the agreement by giving 30-days written notice to Taxmingo
                                at <a href="mailto:support@taxmingo.com">support@taxmingo.com</a> and thereafter unsubscribe to the PLAN.
                            </li>
                            <li>
                                <b>Termination by Taxmingo:</b> Taxmingo may terminate the agreement by giving written 30-day notice to Client via
                                the provided email and thereafter unsubscribe the Client from the PLAN.
                            </li>
                            <li>
                                <b>Mutual Termination for Material Breach:</b> If either party is in material breach of this agreement, the other
                                party may terminate this agreement at the end of a written 30-day notice/cure period, if the breach has not been
                                cured.
                            </li>
                            <li>
                                <b>Suspension of violation of the agreement:</b> Taxmingo may temporarily suspend or terminate, or both if Client
                                violates any terms of the service.
                            </li>
                            <li>
                                <b>Maintenance of Client Data:</b> Within 90-days after termination, Client Data will be available for Client upon
                                written request. After such 90-days period, Taxmingo has no obligation to maintain the Client Data and may destroy
                                it.
                            </li>
                            <li>
                                <b>Return Taxmingo Property Upon Termination:</b> Upon termination of this agreement for any reason, Client must
                                pay Taxmingo for any unpaid amounts, and destroy or return all property of Taxmingo. Upon Taxmingo’s request, Client
                                will confirm in writing its compliance with this destruction or return requirement.
                            </li>
                            <li>
                                <b>Suspension for Violations of Law:</b> Taxmingo may temporarily suspend the Service or remove the applicable
                                Client Data, or both, if it in good faith believes that, as part of using the Service, Client has violated a law.
                                Taxmingo will attempt to contact the Client in advance.
                            </li>
                        </ol>
                    </div>
                    
                    <div className="terms-item">
                        <h3 className="terms-item-heading">OTHER TERMS:</h3>
                        <ol className="roman">
                            <li>
                                <b>Entire Agreement and Changes:</b> This agreement and the order constitute the entire agreement between the
                                parties and supersede any prior or contemporaneous negotiations or agreements, whether oral or written, related
                                to this subject matter. Client is not relying on any representation concerning this subject matter, oral or written,
                                not included in this agreement. No representation, promise or inducement not included in this agreement is binding.
                                No modification of this agreement is effective unless both parties sign it, and no waiver is effective unless the
                                party waiving the right signs a waiver in writing.
                            </li>
                            <li>
                                <b>No Assignment:</b> Neither party may assign or transfer this agreement or an order to a third party, except that
                                this agreement with all orders may be assigned, without the consent of the other party, as part of a merger, or sale
                                of substantially all the assets, of a party.
                            </li>
                            <li>
                                <b>Independent Contractors:</b> The parties are independent contractors with respect to each other.
                            </li>
                            <li>
                                <b>Enforceability and Force Majeure:</b> If any term of this agreement is invalid or unenforceable, the other terms
                                remain in effect. Except for the payment of monies, neither party is liable for events beyond its reasonable control,
                                including, without limitation, force majeure events.
                            </li>
                            <li>
                                <b>Money Damages Insufficiency:</b> Any breach by a party of this agreement or violation of the other party’s
                                intellectual property rights could cause irreparable injury or harm to the other party. The other party may seek a
                                court order to stop any breach or avoid any future breach.
                            </li>
                            <li>
                                <b>No Additional Terms:</b> Taxmingo rejects additional or conflicting terms of any form from client’s purchasing document.
                            </li>
                            <li>
                                <b>Order of Precedence:</b> If there is an inconsistency between this agreement and an order, the order prevails.
                            </li>
                            <li>
                                <b>Feedback:</b> By submitting ideas, suggestions or feedback to Taxmingo regarding the Service, Client agrees that such
                                items submitted do not contain confidential or proprietary information; and Client hereby grants Taxmingo an irrevocable,
                                unlimited, royalty-free and fully-paid perpetual license to use such items for any business purpose.
                            </li>
                        </ol>
                    </div>
                    
                    <div className="terms-item">
                        <p>
                            <b>UPDATES:</b> We reserve the right to adjust the terms and conditions in any plan to conform with economic realities. So please review it frequently.  If we make material changes to this policy, we will notify you here and by email.
                        </p>
                    </div>
                    
                    <div className="terms-item">
                        <p>
                            <b>CONTACT:</b> Feel like getting in touch or to make further clarification? Email us directly
                            at <a href="mailto:support@taxmingo.com">support@taxmingo.com</a>
                        </p>
                    </div>
                </div>
                {props.agree ?
                    <div className="alt-modal-footer">
                        <button type="button" onClick={() => {props.agree(); props.close()}}>I AGREE</button>
                    </div>
                : null}
            </div>
        </div>
    );
}

export default Terms;