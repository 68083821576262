import React, { useState, useEffect } from 'react';
import './Subscription.css';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { useQuery } from 'react-apollo';
import { IS_LOGGED_IN } from '../../../AuthPages/Queries';
import { SUBSCRIPTION_PLANS, SUBSCRIPTIONS } from '../../Queries';
import { Link } from 'react-router-dom';

import { graphql} from 'react-apollo';
import { BUSINESS_INFO, USER_INFO } from '../../Queries';
import { compose } from 'redux';
import List from '../../components/List/List';

import { usePaystackPayment } from 'react-paystack';

const Subscription = (props) => {
    const { data: cache } = useQuery(IS_LOGGED_IN);
    const subscriptionStatus = cache.business ? cache.business.subscribed : null;
    const [paystackConfig, setPaystackConfig] = useState({
        reference: (new Date()).getTime(),
        plan: null,
        publicKey: process.env.REACT_APP_PAYSTACK_KEY
    });

    useEffect(() => {
        if (props.user.me && props.user.me.email) {
            setPaystackConfig({
                reference: (new Date()).getTime(),
                plan: null,
                email: props.user.me.email,
                publicKey: process.env.REACT_APP_PAYSTACK_KEY
            })
        }
    }, [props])

    if (props.user.me && props.user.me.email) {

    }
    
    let pageLoading = false;
    
    const { loading: plansLoading, data: plans } = useQuery(SUBSCRIPTION_PLANS, {
        fetchPolicy: "network-only"
    });
    if (plansLoading) {
        pageLoading = true;
    }
    let subscription_plans = [];
    if (plans) {
        subscription_plans = plans.plans;
    }

    
   
    const InitPayment = (planCode) => {
        setPaystackConfig({
            ...paystackConfig,
            plan: planCode
        })
    }

    const onPaystackSuccess = (reference) => {
        window.location.reload();
        // setTimeout(() => {
        //     window.location.reload(false);
        //     // setPaystack({payment: null, code: null, form: 'card'});
        //     // reloadBusiness();
        // }, 3000);
    };
    
    // you can call this function anything
    const onPaystackClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        
    }

    const initializePayment = usePaystackPayment(paystackConfig);
    if (paystackConfig.plan) {
        initializePayment(onPaystackSuccess, onPaystackClose);
        setPaystackConfig({
            ...paystackConfig,
            plan: null
        })
    }

    const [currentPage, setCurrentPage] = useState(1);
    const { error: subsError, data: subs } = useQuery(SUBSCRIPTIONS, {
        variables: {first: 15, page: currentPage},
        fetchPolicy: "network-only"
    });
    if (subsError) {
        // console.log(subsError);
    }
    let subscriptionList = [];
    let currentSub = {};
    let listPagination = {};
    if (subs && subs.subscriptions) {
        subscriptionList = subs.subscriptions.data;
        currentSub = subscriptionList[0] ? subscriptionList[0] : {};
        listPagination = subs.subscriptions.paginatorInfo;
    }
    

    const handlePagination = (page) => {
        setCurrentPage(page);
    }

    return (
        <div>
            <Breadcrumb name="Subscription" />

            <div className="subscription-box">
                <div className="subscription">
                    {!subscriptionStatus || subscriptionStatus === 'cancelled' ? <>
                        <h1 className="title red">You are currently not subscribed to any plan.</h1>
                        {cache.trial ? 
                            <h2 className="trial">
                                You are within your trial period and you can only perform a <Link to="/dashboard/health">Tax Health Check</Link>.
                            </h2>
                        : null}
                        <div className="benefits">
                            <h3>Subscribe now and get continous access to...</h3>
                            <div className="item">
                                <i className="la la-check-circle"></i>
                                Monthly tax computation and filing services.
                            </div>
                            <div className="item">
                                <i className="la la-check-circle"></i>
                                Tax health check for all tax types.
                            </div>
                            <div className="item">
                                <i className="la la-check-circle"></i>
                                Tax health analysis and recommendation for all tax type, from professionals.
                            </div>
                            <div className="item">
                                <i className="la la-check-circle"></i>
                                Tax computation  and filling for all tax types, done by professionals.
                            </div>
                            <div className="item">
                                <i className="la la-check-circle"></i>
                                Tax planning and advisory
                            </div>
                            <div className="item">
                                <i className="la la-check-circle"></i>
                                Professional support on tax matters from a professional.
                            </div>
                        </div>
                        {cache.business && plans ?
                            <div className="plans">
                                <h3>Available plans for business tier {cache.business.tier}</h3>
                                {subscription_plans.map(plan => {
                                    return(
                                        <div className="plan" key={plan.plan_code}>
                                            <h3 className="price"><b className="naira">&#8358;</b>{plan.amount}</h3>
                                            <h3 className="period">{plan.interval}</h3>
                                            <button onClick={() => InitPayment(plan.plan_code)}>
                                                SUBSCRIBE
                                            </button>
                                        </div>
                                    )
                                })}
                            </div> : null
                        }
                    </>:(subscriptionStatus === 'pending' || subscriptionStatus === 'success' ? <>
                            <h1 className="title green">You transaction is yet to be confirmed.</h1>
                            <div className="benefits wide back">
                                <h3>Your subscription period starts when your transaction is confirmed.</h3>
                            </div>
                        </>:<>
                            <h1 className="title">You currently have an active subscription.</h1>
                            <div className="benefits wide back">
                                <div className="desc">
                                    <div className="label">Plan</div>
                                    {currentSub.plan}
                                </div>
                                <div className="desc">
                                    <div className="label">Subscribed on</div>
                                    {new Date(currentSub.start).toDateString()}
                                </div>
                                <div className="desc">
                                    <div className="label">Next renewal date</div>
                                    {new Date(currentSub.end).toDateString()}
                                </div>
                            </div>
                        </>)
                    }
                </div>
            </div>

            <List type="subscription" title="Previous subscriptions" list={subscriptionList}
                pagination={listPagination} pageClicked={handlePagination} />

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    )
}

export default compose(
    graphql(BUSINESS_INFO, {
        name: "business"
    }),
    graphql(USER_INFO, {
        name: "user"
    })
)(Subscription);