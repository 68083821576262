import React from 'react';
import './How.css';
import { Link } from 'react-router-dom';

import register from '../../assets/images/illustrations/steps/register.png';
import consultant from '../../assets/images/illustrations/steps/consultant.png';
import focus from '../../assets/images/illustrations/steps/focus.png';

const how = () => {
    return (
        <div className="how" id="how-it-works">
            <h1 className="title">3 simple steps to having your taxes sorted</h1>
            <div className="item">
                <img src={register} alt="Register" />
                <h3>Sign up to Taxmingo</h3>
                <p>
                    Create an account and set up your business information, upload your documents, take our free tax health check.
                </p>
            </div>
            <div className="item">
                <img src={consultant} alt="Get paired" />
                <h3>Get paired with an accountant</h3>
                <p>
                    You will be paired with a qualified accountant who will walk you through your tax health and help manage your company’s tax affairs.
                </p>
            </div>
            <div className="item">
                <img src={focus} alt="Focus on business"/>
                <h3>Focus on your business</h3>
                <p>
                    Then you can spend more time and resources growing your business while we help you keep the taxman away.
                </p>
            </div>
            <div className="just-2">
                Looks like it's just 2 steps <i className="la la-lg la-smile-wink"></i>&nbsp; &nbsp;
                <Link to="/signup" className="link a">
                    Take the first step
                </Link>
            </div>
        </div>
    );
}

export default how;