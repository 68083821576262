import React, { useEffect } from 'react';
import '../AuthPages.css';

import { useMutation, useApolloClient } from 'react-apollo'
import { VERIFY_EMAIL } from '../Queries';
import PageLoading from '../../components/PageLoading/PageLoading';
import { Link } from 'react-router-dom';

const Verifying = (props) => {
    const client = useApolloClient();
    const [verify, {loading: verifying, error: verificationError, data: verified}] = useMutation(VERIFY_EMAIL, {
        onCompleted: userData => {
            if (!userData.verify_email.error) {
                localStorage.setItem("auth", userData.verify_email.authorization);
                client.writeData({data: { isLoggedIn: true} });
            }
        }
    });
    if (verificationError) {
        console.log({verificationError});
    }

    useEffect(() => {
        verify({variables: {code: props.match.params.token}});
    }, [verify, props.match.params.token]);

    return (
        <div>
            <h1>Verifying</h1>
            {verified && !verified?.verify_email.error ?
                <div className="auth-message auth-success">
                    <b>EMAIL VERIFIED!</b><br/> Your email has been successfully verified.<br/><br/>
                    You will be redirected to your dashboard
                </div> : null
            }
            {verificationError || !verified?.verify_email.error ?
                <div className="auth-message auth-error">
                    <b>OOPS!</b> Your verification code is invalid. Kindly check your email and follow the link without editing.<br/><br/>
                    <Link to="/login" className="lnk">Login</Link> if your email has been verified or <Link to="/signup" className="lnk">Sign up</Link> if you don't have an account.
                </div> : null
            }
            {verifying ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default Verifying;