import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../components/Input/Input';
import { useMutation } from 'react-apollo'
import { RESET_PASSWORD } from '../Queries';
import PageLoading from '../../components/PageLoading/PageLoading';

const ResetPassword = (props) => {
    const [resetPassword, setResetPassword] = useState({
        form: {
            password: {
                label: 'New password', elementType: 'input',
                elementConfig: { type: 'password', autoFocus: true },
                validation: {required: true, minLength: 8},
                value: '', valid: false, touched: false
            },
            confirm: {
                label: 'Confirm password', elementType: 'input',
                elementConfig: { type: 'password' },
                validation: {required: true, confirmPassword: true},
                value: '', valid: false, touched: false
            }
        },
        formIsValid: false
    })

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...resetPassword.form
        };
        const updatedElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedElement.value = event.target.value;
        updatedElement.valid = checkValidity(updatedElement.value, updatedElement.validation);
        updatedElement.touched = true;
        updatedForm[inputIdentifier] = updatedElement;
        
        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid
        }

        setResetPassword({...resetPassword, form: updatedForm, formIsValid: formIsValid});
    }

    const checkValidity = (value, rules) => {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.minLength) {
            isValid = value.trim().length >= rules.minLength && isValid;
        }
        if (rules.confirmPassword) {
            isValid = value.trim() === resetPassword.form.password.value && isValid;
        }

        return isValid;
    }

    let pageLoading = false;
    let passwordChanged = false;
    let passwordNotChanged = false;
    const [requestReset, { loading: requestLoading, error: requestError, data: requestData }] = useMutation(RESET_PASSWORD);
    if (requestLoading) {
        pageLoading = true;
    }
    if (requestError) {
        console.log(requestError);
        pageLoading = false;
    }
    if (requestData) {
        if (requestData.user_reset_password[0] === "true") {
            passwordChanged = true;
        } else {
            passwordNotChanged = true;
        }
    }

    const formHandler = (event) => {
        event.preventDefault();

        const variables = {
            email: props.location.search.split("=")[1].replace("%40", "@"),
            token: props.match.params.token,
            password: resetPassword.form.password.value,
            password_confirmation: resetPassword.form.confirm.value
        }

        requestReset({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in resetPassword.form) {
        formELementsArray.push({
            id: key,
            config: resetPassword.form[key],
        });
    }
    
    return (
        <form onSubmit={formHandler}>
            <h1>New password</h1>
            {passwordChanged ?
                <div className="auth-message auth-success">
                    <b>PASSWORD CHANGED!</b><br/> You now have a new password.<br/>
                    <Link to="/login" className="lnk">Login</Link> to your account with your new password.
                </div> : null
            }
            {passwordNotChanged ?
                <div className="auth-message auth-error">
                    <b>OOPS!</b> Your reset password link is expired.<br/>
                    Kindly <Link to="/forgot-password" className="lnk">request</Link> a password change link again.
                </div> : null
            }
            {formELementsArray.map(formElement => (
                <Input key={formElement.id} value={formElement.config.value}
                    label={formElement.config.label} invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    inputChanged={(event) => inputChangedHandler(event, formElement.id)} />
            ))}
            <div className="submit-box">
                <button type="submit" className="submit" disabled={!resetPassword.formIsValid}>Reset</button>
                <div className="text"><Link to="/login">Login</Link> with previous password.</div>
            </div>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </form>
    );
}

export default ResetPassword;