import React, { useEffect }  from 'react';
import './App.css';

import Header from './Header/Header';
import Routes from './Routes';

import tawkTo from 'tawkto-react';

const tawkToPropertyId = '5fd6a4e6df060f156a8caf5e'

const App = () => {

  useEffect(() => {
      tawkTo(tawkToPropertyId)
  }, [])

  return (
    <div className="App">
      <Header />
      <Routes />
      {/* <Route path="/404" component={PageNotFound} />
      <Redirect to="/404" /> */}
    </div>
  );
}

export default App;
