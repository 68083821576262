import React from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Card from '../../components/Card/Card';
import Chart from '../../components/Chart/Chart';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { LATEST_HEALTH_REPORT } from '../../Queries';
import { useQuery } from 'react-apollo';

const Home = () => {
    let pageLoading = false;

    const { loading: healthLoading, data: healthStatus} = useQuery(LATEST_HEALTH_REPORT, {
        fetchPolicy: "network-only"
    });
    if (healthLoading) {
        pageLoading =true;
    }

    return (
        <div>
            <Breadcrumb name="Home" />

            <div>
                <Chart title="Tax health status" data={healthStatus ? healthStatus.last_tax_health : null}/>
            </div>
            <div>
                <Card w="w-1of3" h="h-150" align="text-center" theme="cyan" icon="calculator"
                    header1="Tax computation" link="/dashboard/computation" />
                <Card w="w-1of3" h="h-150" align="text-center" theme="green" icon="chart-bar"
                    header1="Tax planning and management" link="/dashboard/planning" />
                <Card w="w-1of3" h="h-150" align="text-center" theme="yellow" icon="comments"
                    header1="Consultant support" link="/dashboard/support" />
            </div>
            
            {pageLoading ? <PageLoading ripple={true} /> : null}            
        </div>
    );
}

export default Home;