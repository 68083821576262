import React, { useEffect } from 'react';
import './Home.css';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { animateScroll } from 'react-scroll';

import Banner from './Banner/Banner';
import Services from './Services/Services';
import How from './How/How';
import Pricing from './Pricing/Pricing';
// import Testimonies from './Testimonies/Testimonies';
import Footer from './Footer/Footer';

import { IS_LOGGED_IN } from '../AuthPages/Queries';
import { useQuery } from 'react-apollo';

const Home = (props) => {
    const { data: loggedIn } = useQuery(IS_LOGGED_IN);

    const hash = props.location.hash.substring(1);
    let hashElement = null;
    
    useEffect(() => {
        if (hash) {
            hashElement = document.getElementById(hash).offsetTop;
        }
        animateScroll.scrollTo(hashElement, {
            duration: 500, delay: 0, smooth: true
        })
    })

    if (loggedIn.isLoggedIn) {
        return <Redirect to="/dashboard/home" />
    }

    return (
        <div className="home-page">
            <Banner />
            <How />
            <Services />
            <Pricing />
            {/* <Testimonies /> */}
            <Footer />
        </div>
    );
}

export default withRouter(Home);