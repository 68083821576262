import React, { useState } from 'react';
import { Redirect } from "react-router-dom";

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageLoading from '../../../components/PageLoading/PageLoading';
import Input from "../../../components/Input/Input";

import { REGISTER_BUSINESS, BUSINESS_INFO } from '../../Queries';
import { useMutation } from 'react-apollo';


const RegisterBusiness = () => {
    const [componentState, setComponentState] = useState({
        businessForm: {
            info: {
                name: {
                    label: 'Business name', elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {required: true}, show: true,
                    value: '', message: '', valid: false, touched: false
                },
                designation: {
                    label: 'Your designation', elementType: 'input',
                    elementConfig: { type: 'text' },
                    info: 'What is your role or position in the Business/Company?',
                    validation: {required: true}, show: true,
                    value: '', message: '', valid: false, touched: false
                },
                type: {
                    label: 'Business type', elementType: 'select',
                    elementConfig: { options: [
                        {value: 'Enterprise', displayText: 'Enterprise'},
                        {value: 'Ventures', displayText: 'Ventures'},
                        {value: 'Limited Liability Company', displayText: 'Limited Liability Company'}
                    ]},
                    validation: {required: true}, show: true,
                    value: '', message: '', valid: false, touched: false
                },
                employees: {
                    label: 'Number of employees', elementType: 'input',
                    elementConfig: { type: 'number', min: 1, step: 1 },
                    validation: {required: true}, show: true,
                    value: '', message: '', valid: false, touched: false
                },
                turnover: {
                    label: 'Yearly turnover (in Naira)', elementType: 'input',
                    elementConfig: { type: 'text', placeholder: '0.00' },
                    validation: {required: true, money: true}, show: true,
                    value: '', actualValue: '', message: '', valid: false, touched: false
                },
                rc: {
                    label: 'RC number', elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {}, show: false,
                    value: '', message: '', valid: true, touched: false
                },
                bn: {
                    label: 'BN number', elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {}, show: false,
                    value: '', message: '', valid: true, touched: false
                },
                tin: {
                    label: 'Tax Identification Number', elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {}, show: true,
                    value: '', message: '', valid: true, touched: false
                },
                vat: {
                    label: 'VAT registration number', elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {}, show: true,
                    value: '', message: '', valid: true, touched: false
                },
                phone: {
                    label: 'Phone (sperate with comma)', elementType: 'input',
                    elementConfig: { type: 'text' },
                    validation: {required: true}, show: true,
                    value: '', message: '', valid: false, touched: false
                },
                address: {
                    label: 'Address', elementType: 'textarea',
                    elementConfig: { type: 'text' },
                    validation: {required: true}, show: true,
                    value: '', message: '', valid: false, touched: false
                }
            },
            docs: {
                idcard: {
                    label: 'Government issued ID', elementType: 'file',
                    elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png" },
                    typeInfo: "Select an image file",
                    validation: { image: true }, show: true,
                    value: '', message: '', file: {}, valid: true, touched: false
                },
                inc: {
                    label: 'Certificate of Incorporation', elementType: 'file',
                    elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: { doc: true }, show: true,
                    value: '', message: '', file: {}, valid: true, touched: false
                },
                co2: {
                    label: 'Form C02 & C07', elementType: 'file',
                    elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: { doc: true }, show: true,
                    value: '', file: {}, valid: true, touched: false
                },
                tin_cert: {
                    label: 'TIN certificate', elementType: 'file',
                    elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: { doc: true }, show: true,
                    value: '', file: {}, valid: true, touched: false
                },
                vat_cert: {
                    label: 'VAT certificate', elementType: 'file',
                    elementConfig: { type: 'file', accept: ".jpg, .jpeg, .png, .pdf, .doc, .docx" },
                    typeInfo: "Select pdf, doc or image file",
                    validation: { doc: true }, show: true,
                    value: '', file: {}, valid: true, touched: false
                }
            }
        },
        formState: 'info',
        formIsValid: false,
        loading: false
    });
    let pageLoading = false;

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedbusinessForm = {
        ...componentState.businessForm,
        };
        const updatedFormGroup = {
            ...updatedbusinessForm[componentState.formState]
        };
        const updatedFormElement = {
        ...updatedFormGroup[inputIdentifier]
        };

        if (updatedFormElement.validation.money) {
            updatedFormElement.actualValue = event.target.value.replace(/,/gi,'');
            updatedFormElement.value = editMoney(event.target.value);
        } else if (updatedFormElement.elementConfig.type === "file" && event.target.files[0]) {
            updatedFormElement.file = event.target.files[0];
            updatedFormElement.value = event.target.files[0].name;
        } else {
            updatedFormElement.value = event.target.value;
        }
        const validityStatus = checkValidity(
            updatedFormElement.actualValue ? updatedFormElement.actualValue : updatedFormElement.value,
            updatedFormElement.validation,
            updatedFormElement.elementConfig.type === "file" ? event.target.files[0] : null
        );
        updatedFormElement.valid = validityStatus.validity;
        updatedFormElement.message = validityStatus.message;
        updatedFormElement.touched = true;
        updatedbusinessForm[componentState.formState][inputIdentifier] = updatedFormElement;
        
        // Check inputIdentifier to handle RC and BN number
        if (inputIdentifier === 'type') {
            const updatedRC = {
                ...updatedFormGroup.rc
            };
            const updatedBN = {
                ...updatedFormGroup.bn
            };
            if (event.target.value === 'Enterprise' || event.target.value === 'Ventures') {
                updatedRC.show = true;
                updatedbusinessForm.info.rc = updatedRC;
                updatedBN.show = false;
                updatedbusinessForm.info.bn = updatedBN;
            } else if (event.target.value === 'Limited Liability Company') {
                updatedRC.show = false;
                updatedbusinessForm.info.rc = updatedRC;
                updatedBN.show = true;
                updatedbusinessForm.info.bn = updatedBN;
            }
        }
  
        let formIsValid = true;
        for (let inputIdentifier in updatedbusinessForm[componentState.formState]) {
            if (updatedbusinessForm[componentState.formState][inputIdentifier].show) {
                formIsValid = updatedbusinessForm[componentState.formState][inputIdentifier].valid && formIsValid;
            }
        }
  
        setComponentState({ ...componentState, businessForm: updatedbusinessForm, formIsValid: formIsValid });
    };

    const editMoney = (input) => {
        let val = input.indexOf(".") > -1 ? input.split(".")[0] : input;
        let value = val.replace(/,/gi,'');
        let newValue = value;
        const iteration = Math.floor(value.length / 3);
        if (value.length > 3) {
            for (let index = 1; index <= iteration; index++) {
                newValue = newValue.slice(0, value.length - (3 * index)) + "," + newValue.slice(value.length - (3 * index));
            }
            newValue = newValue.charAt(0) === ',' ? newValue.replace(',','') : newValue;
        } else {
            newValue = val;
        }
        return input.indexOf(".") > -1 ? newValue + '.' + input.split(".")[1] : newValue;
    }
  
    const checkValidity = (value, rules, file) => {
        let isValid = true;
        let message = '';
  
        if (rules.required) {
            const valid = value.trim() !== '';
            isValid = valid && isValid;
            message = valid ? '' : 'This field is required';
        }
        if (rules.money) {
            const figure = value.replace(/,/gi,''); 
            const valid = !isNaN(figure) && !isNaN(parseFloat(figure));
            isValid = valid && isValid;
            message = valid ? '' : 'Input a valid amount of money';
        }
        if (rules.minLength) {
            const valid = value.length >= rules.minLength;
            isValid = valid && isValid;
            message = valid ? '' : 'Password should be at least ' + rules.minLength + ' characters';
        }
        if (rules.image) {
            const fileType = value.split(".")[value.split(".").length - 1];
            const acceptedTypes = ["jpg", "jpeg", "png"];
            const valid = acceptedTypes.includes(fileType.toLowerCase());
            isValid = valid && isValid;
            message = valid ? '' : 'File must be .jpeg  or .png';
        }
        if (rules.doc) {
            const fileType = value.split(".")[value.split(".").length - 1];
            const acceptedTypes = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "csv", "tsv", "xls", "xlsx"];
            const valid = acceptedTypes.includes(fileType.toLowerCase());
            isValid = valid && isValid;
            message = valid ? '' : 'File must be .pdf, .doc, .docx, excel, csv, tsv, .jpeg or .png';
        }
        if (file) {
            const valid = file.size < 6000000;
            isValid = valid && isValid;
            message = valid ? '' : 'Size of attached file must be less than 6MB';
        }
        
        return {validity: isValid, message: message};
    };

    const changeFormState = (newState, motion) => {
        if (motion === 'next') {
            let formIsValid = false;
            for (let inputIdentifier in componentState.businessForm[componentState.formState]) {
                if (componentState.businessForm[componentState.formState][inputIdentifier].value) {
                    formIsValid = true;
                }
            }
            setComponentState({ ...componentState, formState: newState, formIsValid: formIsValid });
        } else {
            setComponentState({ ...componentState, formState: newState, formIsValid: true });
        }
    }

    const [register, { loading: registerLoading, data: newBusiness, error: regError }] = useMutation(REGISTER_BUSINESS, {
        refetchQueries: [{query: BUSINESS_INFO}]
    });
    if (registerLoading) {
        pageLoading = true;
    }
    if (newBusiness) {
        return <Redirect to="/dashboard/home" />
    }
    if (regError) {
        // console.log({regError});
    }

    const formHandler = (event) => {
        event.preventDefault();
        const infoForm = componentState.businessForm.info;
        const docForm = componentState.businessForm.docs;

        let doc_names = [], docs = [];
        if (event.target.button.value === "submit") {
            for (let key in docForm) {
                if (docForm[key].value && docForm[key].label !== "Government issued ID") {
                    doc_names.push(docForm[key].label);
                    docs.push(docForm[key].file);
                }
            }
        }
        let variables = {
            name: infoForm.name.value, designation: infoForm.designation.value, type: infoForm.type.value,
            employees: infoForm.employees.value, turnover: infoForm.turnover.actualValue,
            address: infoForm.address.value, phone: infoForm.phone.value
        }

        variables = infoForm.rc.value === '' ? {...variables} : {...variables, rc_no: infoForm.rc.value};
        variables = infoForm.bn.value === '' ? {...variables} : {...variables, bn_no: infoForm.bn.value};
        variables = infoForm.tin.value === '' ? {...variables} : {...variables, tin: infoForm.tin.value};
        variables = infoForm.vat.value === '' ? {...variables} : {...variables, vat_reg_no: infoForm.vat.value};
        variables = docs.length < 1 ? {...variables} : {...variables, doc_types: doc_names, cac_docs: docs };
        variables = docForm.idcard.value === '' ? {...variables} : {...variables, identity: docForm.idcard.file};
        
        register({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in componentState.businessForm[componentState.formState]) {
        formELementsArray.push({
            id: key,
            config: componentState.businessForm[componentState.formState][key]
        });
    }

    return (
        <div>
            <Breadcrumb name="Welcome" />

            <form className="dashform-box w-40" onSubmit={formHandler}>
                <div className="dashform">
                    <div className="dashform-intro b">
                        <b>Register your business</b> by <b>Carefully</b> filling this form.
                        <div className="form-nav">
                            <span className={componentState.formState === "info" ? "active" : null}>Business info</span>
                            <span className={componentState.formState === "docs" ? "active" : null}>Documents</span>
                        </div>
                    </div>
                    
                    {formELementsArray.map(formElement => {
                        return (
                            formElement.config.show ?
                                <Input
                                    key={formElement.id}
                                    value={formElement.config.value}
                                    label={formElement.config.label}
                                    invalid={!formElement.config.valid}
                                    message={formElement.config.message}
                                    shouldValidate={formElement.config.validation}
                                    typeInfo = {formElement.config.typeInfo}
                                    required={formElement.config.validation.required}
                                    touched={formElement.config.touched}
                                    elementType={formElement.config.elementType}
                                    info={formElement.config.info}
                                    elementConfig={formElement.config.elementConfig}
                                    inputChanged={event => inputChangedHandler(event, formElement.id)}
                                />
                            : null
                        )
                    })}

                    {componentState.formState === "info" ? <>
                        <div className="submit-box">
                            <button className="right form-nav" disabled={!componentState.formIsValid} onClick={() => changeFormState('docs', 'next')}>Next</button>
                        </div> </> : null
                    }
                    {componentState.formState === "docs" ? <>
                        <div className="submit-box">
                            <div>
                                <button className="form-nav" onClick={() => changeFormState('info', 'prev')}>Previous</button>
                                <button type="submit" name="button" value="submit" className="submit right" disabled={!componentState.formIsValid} >
                                    Submit
                                </button>
                            </div>
                            <div className="center-button">
                                <button type="submit" name="button" className="form-nav">Upload documents later</button>
                            </div>
                        </div> </> : null
                    }
                </div>
            </form>
            
            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default RegisterBusiness;