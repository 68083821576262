import React from 'react';
import './modal.css';


const Privacy = (props) => {
    return (
        <div className={'alt-modal-backdrop ' + (props.show ? 'active' : '')} onClick={() => props.close()}>
            <div className={'alt-modal ' + (props.show ? 'active' : '')} onClick={(e) => e.stopPropagation()}>
                <h1 className="alt-modal-heading">
                    Privacy policy
                    <button className="close" type="button" onClick={() => props.close()}><i className="la la-close"></i></button>
                </h1>
                <div className="alt-modal-content privacy">
                    TAXMINGO is dedicated to protecting the confidentiality and privacy of information entrusted to it.<br/>
                    As part of this fundamental obligation, TAXMINGO is committed to the appropriate protection and use of
                    personal information (sometimes referred to as “personal data”, "personally identifiable information"
                    or "PII") that has been collected online.<br/>
                    Generally, our intent is to collect only the personal information that is provided voluntarily by
                    online visitors so that we can offer information and/or services to those individuals.
                    Please review this privacy statement ("Privacy Statement") to learn more about how
                    we collect, use, share and protect the personal information that we have obtained.

                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">1. What information do we collect?</h3>
                        <p>
                            We obtain personal information about you if you choose to provide it — for example, to contact mailboxes
                            or to register for certain services. If you choose to register or login to TAXMINGO website using a
                            third party single sign-in service that authenticates your identity and connects your social media login
                            information (e.g., LinkedIn, Google, or Twitter) with TAXMINGO, we will collect any information or content
                            needed for the registration or log-in that you have permitted the social media provider to share with us,
                            such as your name and email address. Other information we collect may depend on the privacy settings you
                            have set with your social media provider, so please review the privacy statement or policy of the
                            applicable service.
                        </p>
                        <p>
                            By registering and/or submitting personal information to TAXMINGO, you are also acknowledging that TAXMINGO
                            may use this information in accordance with this Privacy Statement. Your personal information is not used
                            for other purposes, unless we obtain your permission, or unless otherwise required or permitted by law or
                            professional standards. For example, if you register to TAXMINGO website and provide information about your
                            preferences, we will use this information to personalize your user experience. Where you register or login
                            using a third-party single user sign-in we may also recognize you as the same user across any different devices
                            you use and personalize your user experience on TAXMINGO website. If you send us a resume or curriculum vitae
                            (CV) to apply online for a position with TAXMINGO, we will use the information that you provide to match you
                            with available TAXMINGO job opportunities.<br/>
                            In some cases where you have registered for certain services we may store your email address temporarily until
                            we receive confirmation of the information you provided via an email (i.e. where we send an email to the email
                            address provided as part of your registration to confirm a subscription request).
                        </p>
                    </div>

                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">2. The legal grounds we have to use your personal information</h3>
                        <p>
                            TAXMINGO generally collects only the personal information necessary to fulfil your request. Where additional,
                            optional information is sought, you will be notified of this at the point of collection or later.
                        </p>
                        <p>
                            The law in Nigeria allows us to process personal information, so long as we have a ground under the law to do
                            so. It also requires us to tell you what those grounds are. As a result, when we process your personal
                            information, we will rely on one of the following processing conditions:
                        </p>
                        <ol>
                            <li>
                                <b>Performance of a contract:</b> this is when the processing of your personal information is necessary
                                in order to perform our obligations under a contract;
                            </li>
                            <li>
                                <b>Legal obligation:</b> this is when we are required to process your personal information in order to
                                comply with a legal obligation, such as keeping records for tax purposes or providing information to a
                                public body or law enforcement agency;
                            </li>
                            <li>
                                <b>Legitimate interests:</b> we may process information about you where it is in our legitimate interest
                                in running a lawful business to do so in order to further that business, so long as it doesn’t outweigh
                                your interests; or
                            </li>
                            <li>
                                <b>Consent:</b> we may occasionally ask you for specific permission to process some of your personal
                                information, and we will only process your personal information in this way if you agree to us doing so.
                                You may withdraw your consent at any time by contacting <a href="mailto:support@taxmingo.com">support@taxmingo.com</a>
                            </li>
                        </ol>
                        <p>
                            TAXMINGO only collects "sensitive" personal information when the relevant individuals voluntarily provide us
                            with this information or where such information is required or permitted to be collected by law or professional
                            standards. Sensitive information includes personal information regarding a person's race, ethnicity, political
                            beliefs, trade union membership, religious or similar beliefs, physical or mental health, sexual life or
                            criminal record. Please it is important that you use your discretion when providing sensitive information
                            to TAXMINGO, and under any circumstances, do not provide sensitive information to TAXMINGO, unless you thereby 
                            consent to TAXMINGO's use of that information for its legitimate business purposes and consent to the transfer
                            and storage of such information to and in TAXMINGO databases. If you have any questions about whether the
                            provision of sensitive information to TAXMINGO is, or may be, necessary or appropriate for particular purposes,
                            please contact <a href="mailto:support@taxmingo.com">support@taxmingo.com</a>
                        </p>
                    </div>

                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">3. Automatic collection of personal information</h3>
                        <p>
                            In some instances, TAXMINGO and its service providers uses cookies, web beacons and other technologies to
                            automatically collect certain types of information when you visit us online, as well as through emails that
                            we may exchange. The collection of this information allows us to customize your online experience, improve
                            the performance, usability and effectiveness of TAXMINGO's online presence, and to measure the effectiveness
                            of our marketing activities.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">4. IP addresses</h3>
                        <p>
                            An IP address is a number assigned to your computer whenever you access the internet. It allows computers
                            and servers to recognise and communicate with one another. IP addresses from which visitors appear to
                            originate may be recorded for IT security and system diagnostic purposes. This information may also be used
                            in aggregate form to conduct web site trend and performance analysis.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">5. Cookies</h3>
                        <p>
                            Cookies may be placed on your computer or internet-enabled device whenever you visit our website. This
                            allows our website to remember your computer or device and serves a number of purposes.
                        </p>
                        <p>
                            A notification banner may appear requiring your consent to collect cookies. If you do not provide consent,
                            your computer or internet-enabled device will not be tracked for marketing-related activities. A secondary
                            type of cookie referred to as "user-input" cookies may still be required for necessary functionality. Such
                            cookies will not be blocked through the use of this notification banner. Your selection will be saved in a
                            cookie and is valid for a period of 90 days. If you wish to revoke your selection, you may do so by clearing
                            your browser's cookies.
                        </p>
                        <p>
                            Although most browsers automatically accept cookies, you can choose whether or not to accept cookies via
                            your browser's settings (often found in your browser's Tools or Preferences menu). You may also delete cookies
                            from your device at any time. However, please be aware that if you do not accept cookies, you may not be able
                            to fully experience some of our website’s features. Other third-party tools and widgets may be used on our web
                            page to provide additional functionality. Use of these tools or widgets may place a cookie on your device to
                            make our service easier to use, and ensure your interaction is displayed on our webpage properly.
                        </p>
                        <p>
                            Cookies by themselves do not tell us your email address or otherwise identify you personally. In our analytical
                            reports, we may obtain other identifiers including IP addresses, but this is for the purpose of identifying the
                            number of unique visitors to our website and geographic origin of visitor trends, and not to identify individual
                            visitors.
                        </p>
                        <p>
                            It is important to understand that by navigating on our website or entering your login details to access areas
                            reserved for registered users, you agree that we can place these cookies on your computer or internet enabled
                            device.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">6. Web beacons</h3>
                        <p>
                            A web beacon is a small image file on a web page that can be used to collect certain information from your
                            computer, such as an IP address, the time the content was viewed, a browser type, and the existence of cookies
                            previously set by the same server. TAXMINGO only uses web beacons in accordance with applicable laws. TAXMINGO
                            or its service providers may use web beacons to track the effectiveness of third party web sites that provide
                            us with marketing or recruiting services, or to gather aggregate visitor statistics and manage cookies. You have
                            the option to render some web beacons unusable by rejecting their associated cookies. The web beacon may still
                            record an anonymous visit from your IP address but cookie information will not be recorded.
                        </p>
                        <p>
                            In some of our newsletters and other communications, we may monitor recipient actions such as email open rates
                            through embedded links within the messages. We collect this information to gauge user interest and to enhance
                            future user experiences.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">7. Location-based tools</h3>
                        <p>
                            TAXMINGO may collect and use the geographical location of your computer or mobile device. This location data
                            is collected for the purpose of providing you with information regarding services which we believe may be of
                            interest to you based on your geographic location, and to improve our location-based products and services.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">8. Social media widgets and applications</h3>
                        <p>
                            TAXMINGO website may include functionality to enable sharing via third-party social media applications, such
                            as the Facebook Like button and Twitter widget. These social media applications may collect and use information
                            regarding your use of TAXMINGO website. Any personal information that you provide via such social media
                            applications may be collected and used by other members of that social media application and such interactions
                            are governed by the privacy policies of the companies that provide the application. We do not have control over,
                            or responsibility for, those companies or their use of your information.
                        </p>
                        <p>
                            In addition, TAXMINGO website may host blogs, forums, crowd-sourcing and other applications or services
                            (collectively "social media features"). The purpose of social media features is to facilitate the sharing
                            of knowledge and content. Any personal information that you provide on any TAXMINGO social media feature may
                            be shared with other users of that social media feature (unless otherwise stated at the point of collection),
                            over whom we may have limited or no control.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">9. Children</h3>
                        <p>
                            TAXMINGO understands the importance of protecting children's privacy, especially in an online environment.
                            In particular, our website is not intentionally designed for or directed at children under the age of 16. It
                            is our policy never to knowingly collect or maintain information about anyone under the age of 16, except as
                            part of an engagement to provide professional services.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">10. Transfer of information to third parties</h3>
                        <p>
                            We do not share personal information with unaffiliated third-parties, except as necessary for our legitimate
                            professional and business needs, to carry out your requests, and/or as required or permitted by law or
                            professional standards. This would include:
                        </p>
                        <ol>
                            <li>
                                <b>Our service providers:</b> TAXMINGO work with reputable partners, service providers or agencies so
                                they can process your personal information on our behalf. TAXMINGO will only transfer personal
                                information to them when they meet our strict standards on the processing of data and security. We
                                only share personal information that allows them to provide their services.
                            </li>
                            <li>
                                <b>If we are reorganized or sold to another organization:</b> TAXMINGO may also disclose personal
                                information in connection with the sale, assignment, or other transfer of the business of the site to
                                which the data relates;
                            </li>
                            <li>
                                <b>Courts, tribunals, law enforcement or regulatory bodies:</b> TAXMINGO may disclose personal
                                information in order to respond to requests of courts, tribunals, government or law enforcement
                                agencies or where it is necessary or prudent to comply with applicable laws, court or tribunal
                                orders or rules, or government regulations.
                            </li>
                            <li>
                                <b>Audits:</b> disclosures of personal information may also be needed for data privacy or security
                                audits and/or to investigate or respond to a complaint or security threat.
                            </li>
                        </ol>
                        <p>
                            TAXMINGO will not transfer the personal information you provide to any third-parties for their own direct
                            marketing use.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">11. Choices</h3>
                        <p>
                            In general, you are not required to submit any personal information to TAXMINGO, but we may require you
                            to provide certain personal information in order for you to receive additional information about our
                            services and events. TAXMINGO may also ask for your permission for certain uses of your personal
                            information, and you can agree to or decline those uses. If you opt-in for particular services or
                            communications, such as an e-newsletter, you will be able to unsubscribe at any time by following the
                            instructions included in each communication. If you decide to unsubscribe from a service or communication,
                            we will try to remove your information promptly, although we may require additional information before we
                            can process your request.
                        </p>
                        <p>
                            As described in "Cookies" above, if you wish to prevent cookies from tracking you as you navigate our sites,
                            you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. Note, however,
                            that some portions of our sites may not work properly if you elect to refuse cookies.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">12. Your rights</h3>
                        <p>
                            If you have submitted personal information to TAXMINGO, you have the following rights:
                        </p>
                        <ol>
                            <li>
                                <b>Access and correction:</b> you have the right to access to that data. This is 
                                sometimes called a ‘Subject Access Request’. If we agree that we are obliged to
                                provide personal information to you, we will provide it to you free of charge.
                                Before providing personal information to you, we may ask for proof of identity
                                and sufficient information about your interactions with us that we can locate
                                your personal information. If the information we hold about you is incorrect,
                                you may ask us to correct any inaccuracies in the personal information.
                            </li>
                            <li>
                                <b>Object to processing:</b> you have the right to object to us processing your
                                personal information if we are not entitled to use it any more, to have your
                                information deleted if we are keeping it too long or have its processing restricted
                                in certain circumstances.
                            </li>
                        </ol>
                        <p>
                            You can make a request or exercise these rights by contacting TAXMINGO 
                            at <a href="mailto:support@taxmingo.com">support@taxmingo.com</a> and we will make all reasonable and
                            practical efforts to comply with your request, so long as it is consistent with applicable
                            law and professional standards.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">13. Data security and integrity</h3>
                        <p>
                            TAXMINGO has reasonable security policies and procedures in place to protect personal information
                            from unauthorized loss, misuse, alteration, or destruction. Despite TAXMINGO's best efforts,
                            however, security cannot be absolutely guaranteed against all threats. To the best of our ability,
                            access to your personal information is limited to those who have a need to know. Those individuals
                            who have access to the data are required to maintain the confidentiality of such information.
                        </p>
                        <p>
                            We also make reasonable efforts to retain personal information only for so long as the information
                            is necessary to comply with an individual's request or until that person asks that the information
                            be deleted and, in any case, no longer than 10 years.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">14. Links to other sites</h3>
                        <p>
                            Please be aware that TAXMINGO website may later contain links to other sites, including sites
                            maintained by various tax authorities and our service providers. We encourage users to review the
                            privacy policy of each Website visited before disclosing any personal information.
                        </p>
                        <p>
                            By subscribing to any TAXMINGO service plan, you agree to the use of your personal information in
                            accordance with the privacy statement of the TAXMINGO website.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">15. Changes to this statement</h3>
                        <p>
                            TAXMINGO may modify this Privacy Statement from time to time to reflect our current privacy practices.
                            When we make changes to this statement, that will immediately reflect on our website. We therefore
                            encourage you to periodically review this Privacy Statement to be informed about how TAXMINGO is
                            protecting your information.
                        </p>
                    </div>
                    
                    <div className="privacy-item">
                        <h3 className="privacy-item-heading">16. Policy questions and enforcement</h3>
                        <p>
                            TAXMINGO is committed to protecting the online privacy of your personal information. If you have questions
                            or comments about our administration of your personal information, please contact us
                            at <a href="mailto:support@taxmingo.com">support@taxmingo.com</a>. You may also use this address to communicate
                            any concerns you may have regarding compliance with our Privacy Statement.
                        </p>
                        <p>
                            If you are not satisfied with the response you receive, you may escalate your concern to the Global Privacy
                            Officer by sending an email to <a href="mailto:admin@taxmingo.com">admin@taxmingo.com</a>. We will acknowledge
                            your email within [2] days and seek to resolve your concern immediately except on exceptional cases that may
                            require some times. We may accept your concern (and in that case implement one of the measures set out in the
                            'Your Rights' section above), or we may reject your concern on legitimate grounds.
                        </p>
                        <p>
                            In any event, you always have the right to lodge a complaint with the Nigerian regulator in charge of
                            protecting personal information.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Privacy;