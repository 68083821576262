import React, { useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Card from '../../components/Card/Card';
import List from '../../components/List/List';
import PageLoading from '../../../components/PageLoading/PageLoading';

import { useQuery, useLazyQuery } from 'react-apollo';
import { HEALTH_REPORT_LIST } from '../../Queries';

const Health = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [filtered, setFiltered] = useState({
        column: 'TYPE', operator: 'NOT_IN', value: []
    });
    
    let pageLoading = false;
    const {loading: healthLoading, data: healthListData} = useQuery(HEALTH_REPORT_LIST, {
        variables: {
            column: filtered.column, operator: filtered.operator,
            value: filtered.value, first: 15, page: currentPage
        },
        fetchPolicy: "network-only"
    });
    if (healthLoading) {
        pageLoading = true;
    }
    let healthCheckList = [];
    let listPagination = {};
    if (healthListData && healthListData.filter_list_tax_health) {
        healthCheckList = healthListData.filter_list_tax_health.data;
        listPagination = healthListData.filter_list_tax_health.paginatorInfo;
    }

    const filter = [
        {
            title: 'TYPE',
            items: ['Value Added Tax', 'Personal Income Tax', 'Withholding Tax', 'Company Income Tax']
        }
    ]
    const [filterData, {loading: filterLoading}] = useLazyQuery(HEALTH_REPORT_LIST, {
        fetchPolicy: "network-only"
    });
    if (filterLoading) {
        pageLoading = true;
    }    
    const handleFilter = (event, type) => {
        pageLoading = true;
        let newFiltered = {...filtered};
        let filteredValue = newFiltered.value;
        const index = filteredValue.indexOf(event.target.value);
        if (index > -1) {
            filteredValue.splice(index, 1);
        } else {
            filteredValue.push(event.target.value);
        }
        let operator;
        if (filteredValue.length > 0) { 
            operator = 'IN'
        }
        else { 
            operator = 'NOT_IN' 
        }
        setFiltered({
            column: type, operator: operator, value: filteredValue
        });
        filterData({
            variables: {
                column: type, operator: operator,
                value: filteredValue, first: 15, page: 1
            },
            onCompleted: data => {
                healthCheckList = data.filter_list_tax_health.data;
                listPagination = data.filter_list_tax_health.paginatorInfo;
            }
        })
    }
    const clearFilter = () => {
        setFiltered({
            column: 'TYPE', operator: 'NOT_IN', value: []
        });
    }

    const handlePagination = (page) => {
        setCurrentPage(page);
    }


    return (
        <div>
            <Breadcrumb name="Tax health check" />

            <div>
                <Card w="w-25" h="h-200" align="text-center" theme="purple" icon="heartbeat" xs="w-50-s"
                    header0={["Check", "health status"]} header3="Value Added Tax" link="/dashboard/health/vat" />
                <Card w="w-25" h="h-200" align="text-center" theme="purple" icon="heartbeat" xs="w-50-s"
                    header0={["Check", "health status"]} header3="Personal Income Tax" link="/dashboard/health/pit" />
                <Card w="w-25" h="h-200" align="text-center" theme="purple" icon="heartbeat" xs="w-50-s"
                    header0={["Check", "health status"]} header3="Withholding Tax" link="/dashboard/health/wt" />
                <Card w="w-25" h="h-200" align="text-center" theme="purple" icon="heartbeat" xs="w-50-s"
                    header0={["Check", "health status"]} header3="Company Income Tax" link="/dashboard/health/cit" />
            </div>
            <div>
                <List type="health" title="Previous health check results" list={healthCheckList}
                    filter={filter} filteredItems={filtered.value} filterClicked={handleFilter} clear={clearFilter}
                    pagination={listPagination} pageClicked={handlePagination} />
            </div>

            {pageLoading ? <PageLoading ripple={true} /> : null}
        </div>
    );
}

export default Health;