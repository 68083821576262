import React, { useState } from 'react';
import './Footer.css';

import logo from '../../assets/images/logo/logo.svg';
import Faqs from '../../components/modals/Faqs';
import Privacy from '../../components/modals/Privacy';
import Terms from '../../components/modals/Terms';

const Footer = () => {
    const [activeModal, setActiveModal] = useState(undefined);

    return (
        <div className="footer">            
            <div className="brand">
                <img src={logo} alt="Taxmingo logo" className="logo"/>
                <p className="tagline">...making tax compliance easy, efficient and affordable.</p>
                <i className="la la-envelope"></i> <a href="mailto:support@taxmingo.com">support@taxmingo.com</a><br/>
                <i className="la la-phone"></i> <a href="tel:09055242531">09055242531</a>, <a href="tel:09055242532">09055242532</a><br/>
                <i className="la la-map-marker"></i>
                <a target="_blank" rel="noopener noreferrer"
                    href="https://www.google.com/maps/dir//27+Ladipo+Kuku+St,+Allen,+Lagos/@6.600296,3.3493049,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x103b9230898ac149:0xfa8100fcafbe31d5!2m2!1d3.3514936!2d6.600296">
                    27, Ladipo Kuku Street, Off Allen Avenue, Ikeja.
                </a>
            </div>

            <div className="service-list">
                <div className="item">
                    <i className="la la-check-circle"></i> Tax health check
                </div>
                <div className="item">
                    <i className="la la-check-circle"></i> Tax computation and preparation
                </div>
                <div className="item">
                    <i className="la la-check-circle"></i> Filling of tax returns
                </div>
                <div className="item">
                    <i className="la la-check-circle"></i> Tax fillings data and documents storage
                </div>
                <div className="item">
                    <i className="la la-check-circle"></i> Tax planning and management
                </div>
            </div>

            <div className="quick-links">
                <button type="button" className="link" onClick={() => setActiveModal('faqs')}>FAQs</button><br/>
                <button type="button" className="link" onClick={() => setActiveModal('privacy')}>Privacy policy</button><br/>
                <button type="button" className="link" onClick={() => setActiveModal('terms')}>Terms &amp; Condition</button>
            </div>

            <Faqs show={activeModal === 'faqs' ? true : false} close={() => setActiveModal(undefined)} />
            <Privacy show={activeModal === 'privacy' ? true : false} close={() => setActiveModal(undefined)} />
            <Terms show={activeModal === 'terms' ? true : false} close={() => setActiveModal(undefined)} />
        </div>
    );
}

export default Footer;