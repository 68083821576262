import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";

import Input from '../../components/Input/Input';
import PageLoading from '../../components/PageLoading/PageLoading';

import { IS_LOGGED_IN, LOGIN_MUTATION } from '../Queries';
import { useMutation, useApolloClient, useQuery } from 'react-apollo';

const Login = () => {
    const {data: cache} = useQuery(IS_LOGGED_IN);
    const [componentState, setComponentState] = useState({
        loginForm: {
            email: {
                label: 'Email', elementType: 'input',
                elementConfig: { type: 'email', autoFocus: true },
                validation: {required: true, email: true},
                value: '', valid: false, touched: false
            },
            password: {
                label: 'Password', elementType: 'password',
                elementConfig: { type: 'password' },
                validation: {required: true},
                value: '', valid: false, touched: false
            }
        },
        formIsValid: false
    });
    let pageLoading = false;
    let authDetailsError = false;
    let networkError = false;

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedLoginForm = {
            ...componentState.loginForm
        };
        const updatedFormElement = {
            ...updatedLoginForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedLoginForm[inputIdentifier] = updatedFormElement;
        
        let formIsValid = true;
        for (let inputIdentifier in updatedLoginForm) {
            formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid
        }

        setComponentState({...componentState, loginForm: updatedLoginForm, formIsValid: formIsValid});
    }

    const checkValidity = (value, rules) => {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.email) {
            const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isValid = isEmail.test(value.trim()) && isValid;
        }

        return isValid;
    }

    const client = useApolloClient();
    const [loginUser, { loading: loginLoading, error: loginError, data: userData }] = useMutation(LOGIN_MUTATION, {
        onCompleted: userData => {
            pageLoading = false;
            if (!userData.user_login.error) {
                ReactGA.set({
                    userId: userData.id
                })
                localStorage.setItem("auth", userData.user_login.authorization);
                localStorage.setItem("trial", userData.user_login.trial);
                client.writeData({
                    data: {
                        isLoggedIn: true,
                        trial: userData.user_login.trial,
                        tokenExpired: false
                    }
                });
            }
        }
    });
    if (loginLoading) {
        pageLoading = true;
    }
    if (loginError) {
        networkError = true;
    }
    if (userData) {
        if (userData.user_login.error) {
            authDetailsError = true;
        }
    }

    const formHandler = (event) => {
        event.preventDefault();

        const variables = {
            email: componentState.loginForm.email.value,
            password: componentState.loginForm.password.value
        }

        loginUser({ variables: variables });
    }

    const formELementsArray = [];
    for (let key in componentState.loginForm) {
        formELementsArray.push({
            id: key,
            config: componentState.loginForm[key]
        });
    }

    return (
        <form onSubmit={formHandler}>
            <h1>Login</h1>
            {authDetailsError ?
                <div className="auth-message auth-error">
                    <b>OOPS!</b> Something isn't right. Double check your details and try again.<br/>
                    <Link to="/signup" className="lnk">Sign up</Link> if you do not have an account.<br/>
                    <Link to="/forgot-password" className="lnk">Reset your password</Link> if you are sure you have an account.<br/>
                </div> : null
            }
            {networkError ?
                <div className="auth-message auth-error">
                    <b>OOPS!</b> Something isn't right.<br/>Please check your internet connection and try again.
                </div> : null
            }
            {cache.tokenExpired ?
                <div className="auth-message auth-error">
                    <b>OOPS!</b> Your session has expired.<br/>Please login again to continue.
                </div> : null
            }
            
            {formELementsArray.map(formElement => (
                <Input key={formElement.id} value={formElement.config.value}
                    label={formElement.config.label} invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    inputChanged={(event) => inputChangedHandler(event, formElement.id)} />
                    
            ))}
            
            <div className="submit-box">
                <button type="submit" className="submit" disabled={!componentState.formIsValid}>Login</button>
                <Link to="/forgot-password" className="forgot">Forgot password?</Link>
                <div className="text"><Link to="/signup">Sign up</Link> if you do not have an account.</div>
            </div>
            
            {pageLoading ? <PageLoading ripple={true} /> : null}
        </form>
    );
}

export default Login;